<template>
  <div class="map">
    <div id="googleMaps" class="map__container"></div>
    <!--Destination info--->
    <div v-if="type==='destinations'" class="card">
      <!--Message-->
      <span class="card__message">Hora aproximada de llegada</span>
      <!--End-->
      <!--Hour-->
      <span
          class="card__hour">
        <template v-if="this.travelTime.travelTime">
          {{
            moment(new Date().setMinutes(new Date().getMinutes() + (this.travelTime.travelTime?.value / 60))).tz('America/Lima').format("hh:mm A")
          }}
        </template>
        <template v-else>00:00</template>
      </span>
      <!--End-->
    </div>
    <!--End-->
    <!--List info-->
    <div v-else-if="type==='lists'" class="card">
      <!--Message-->
      <span class="card__message">Ubicación compartida desde hace</span>
      <!--End-->
      <!--Hour-->
      <span
          class="card__hour">
          {{ formattedElapsedTime }}
      </span>
      <!--End-->
    </div>
    <!--End-->
  </div>
</template>

<script>
import firebase from "firebase"
import moment from "moment"
import 'moment-timezone'

require('firebase/firestore')
export default {
  name: "Lives",
  data () {
    return {
      map: {},
      position: {},
      currentMarker: null,
      type: null,
      tl: [
        {
          "lon": -77.0183597079616,
          "lat": -12.1435102191899,
          "address": "AV. FRANCISCO BOLOGNESI - CA. VIGIL - AV. MIRAFLORES"
        },
        {
          "lon": -77.0200508255076,
          "lat": -12.1503236948415,
          "address": "AV. FRANCISCO BOLOGNESI - CA. 28 DE JULIO - CA. CORPANCHO"
        },
        {
          "lon": -77.0200262248425,
          "lat": -12.1479744917328,
          "address": "AV. FRANCISCO BOLOGNESI - CA. PAZOS - CA. PARDO"
        },
        {
          "lon": -77.0194704154024,
          "lat": -12.1463479971576,
          "address": "AV. FRANCISCO BOLOGNESI - CA. SALAVERRY - CA. CAJAMARCA"
        },
        {
          "lon": -77.0191275708204,
          "lat": -12.1454162206468,
          "address": "AV. FRANCISCO BOLOGNESI - CA. SEGURA - CA. SANTA ROSA"
        },
        {
          "lon": -77.0198218215271,
          "lat": -12.1522573193006,
          "address": "AV. FRANCISCO BOLOGNESI - JR. GIO BATTA ISOLA"
        },
        {
          "lon": -77.0196813703971,
          "lat": -12.1534441908452,
          "address": "AV. FRANCISCO BOLOGNESI - JIRON. DEPORTES"
        },
        {
          "lon": -77.0178638237081,
          "lat": -12.1390368046376,
          "address": "AV. REPUBLICA DE PANAMA - PROLONG. EL SOL"
        },
        {
          "lon": -77.0178490578104,
          "lat": -12.1358513298849,
          "address": "AV. REPUBLICA DE PANAMA - CA. MIRANDA"
        },
        {
          "lon": -77.0178540365062,
          "lat": -12.141879276686,
          "address": "AV. REPUBLICA DE PANAMA - AV. NICOLAS DE PIEROLA"
        },
        {
          "lon": -77.0178037230312,
          "lat": -12.1361838710899,
          "address": "AV. REPUBLICA DE PANAMA - ACCESO SUR A ESTACION PLAZA DE FLORES"
        },
        {
          "lon": -77.0430551918538,
          "lat": -12.0428574826717,
          "address": "AV. ALFONSO UGARTE - COSTADO NORTE GLORIETA PLAZA CASTILLA"
        },
        {
          "lon": -77.0429823717533,
          "lat": -12.0439444666748,
          "address": "AV. ALFONSO UGARTE - COSTADO SUR GLORIETA PLAZA CASTILLA"
        },
        {
          "lon": -77.0422775510657,
          "lat": -12.0519190909386,
          "address": "AV. ALFONSO UGARTE - ACCESO SUR ESTACIÓN QUILCA"
        },
        {
          "lon": -77.0420206120023,
          "lat": -12.0544858950752,
          "address": "AV. ALFONSO UGARTE - AV. VENEZUELA"
        },
        {
          "lon": -77.0423605408487,
          "lat": -12.0508717036262,
          "address": "AV. ALFONSO UGARTE - JR. QUILCA"
        },
        {
          "lon": -77.0417252098573,
          "lat": -12.0581801971544,
          "address": "AV. ALFONSO UGARTE - AV. ESPAÑA"
        },
        {
          "lon": -77.0419049362012,
          "lat": -12.0562361920894,
          "address": "AV. ALFONSO UGARTE - AV. BOLIVIA"
        },
        {
          "lon": -77.0353931971964,
          "lat": -12.0476341039741,
          "address": "AV. EMANCIPACION - JR. CAYLLOMA"
        },
        {
          "lon": -77.0364142963756,
          "lat": -12.0469880870528,
          "address": "AV. EMANCIPACION -  JR. RUFINO TORRICO"
        },
        {
          "lon": -77.0383878787939,
          "lat": -12.0457639025173,
          "address": "AV. EMANCIPACION - JR. CHANCAY"
        },
        {
          "lon": -77.0374659065016,
          "lat": -12.0463217330937,
          "address": "AV. EMANCIPACION - AV. TACNA"
        },
        {
          "lon": -77.0403020414854,
          "lat": -12.0445902050507,
          "address": "AV. EMANCIPACION - JR. ANGARAES"
        },
        {
          "lon": -77.0393399448988,
          "lat": -12.045200201614,
          "address": "AV. EMANCIPACION - JR. CAÑETE"
        },
        {
          "lon": -77.0343557825549,
          "lat": -12.0482944267409,
          "address": "AV. EMANCIPACION - JR. CAMANA"
        },
        {
          "lon": -77.0333488813353,
          "lat": -12.0489109143919,
          "address": "AV. EMANCIPACION - JR. DE LA UNION"
        },
        {
          "lon": -77.0380701781744,
          "lat": -12.0578686111436,
          "address": "AV. ESPAÑA - AV. GARCILASO DE LA VEGA"
        },
        {
          "lon": -77.0392494241535,
          "lat": -12.0579994151203,
          "address": "AV. ESPAÑA -  JR. WASHINGTON"
        },
        {
          "lon": -77.0323277118575,
          "lat": -12.0495225959024,
          "address": "JR. CARABAYA - JR. CUSCO"
        },
        {
          "lon": -77.0319841415222,
          "lat": -12.0512025479425,
          "address": "JR. LAMPA - JR. PUNO"
        },
        {
          "lon": -77.0343067255995,
          "lat": -12.0545275546931,
          "address": "JR. LAMPA - JR. PACHITEA"
        },
        {
          "lon": -77.0312968720008,
          "lat": -12.0501627995602,
          "address": "JR. LAMPA - JR. CUSCO"
        },
        {
          "lon": -77.0336746524714,
          "lat": -12.0535789031208,
          "address": "JR. LAMPA - JR. L. CORNEJO"
        },
        {
          "lon": -77.0350632883123,
          "lat": -12.0556295008713,
          "address": "JR. LAMPA - AV. ROOSEVELT"
        },
        {
          "lon": -77.0330499658543,
          "lat": -12.0527129564554,
          "address": "JR. LAMPA - AV. NICOLAS DE PIEROLA"
        },
        {
          "lon": -77.035788451935,
          "lat": -12.0597952408587,
          "address": "PASEO DE LOS HEROES NAVALES - PLAZA GRAU"
        },
        {
          "lon": -77.0077806043926,
          "lat": -12.1840525057038,
          "address": "AV. DEFENSORES DEL MORRO - AV. LAS GAVIOTAS"
        },
        {
          "lon": -77.0074847918658,
          "lat": -12.1850182214816,
          "address": "AV. DEFENSORES DEL MORRO - AV. GUARDIA CIVIL"
        },
        {
          "lon": -77.0078051943229,
          "lat": -12.1876614946188,
          "address": "AV. DEFENSORES DEL MORRO - AV. SANTA ANITA"
        },
        {
          "lon": -77.002668172154,
          "lat": -12.1939690267134,
          "address": "AV. DEFENSORES DEL MORRO - AV. JOSE A. LAVALLE"
        },
        {
          "lon": -77.006290161563,
          "lat": -12.190229349515,
          "address": "AV. DEFENSORES DEL MORRO - ALAMEDA SUR"
        },
        {
          "lon": -77.0017650530122,
          "lat": -12.1948372508825,
          "address": "AV. DEFENSORES DEL MORRO - AV. CONFRATERNIDAD"
        },
        {
          "lon": -77.0195275021162,
          "lat": -12.1659360765518,
          "address": "AV. ESCUELA MILITAR - CA. ARIAS (BELGRANO)"
        },
        {
          "lon": -77.0190580250371,
          "lat": -12.1567575411383,
          "address": "AV. ESCUELA MILITAR - CA. BUSTAMANTE"
        },
        {
          "lon": -77.0190145618127,
          "lat": -12.1601165094719,
          "address": "AV. ESCUELA MILITAR - SALIDA ESCUELA MILITAR"
        },
        {
          "lon": -77.0192812940525,
          "lat": -12.1621488309353,
          "address": "AV. ESCUELA MILITAR - AV. ALEJANDRO IGLESIAS"
        },
        {
          "lon": -77.0191199955893,
          "lat": -12.1680149222688,
          "address": "AV. ESCUELA MILITAR - AV. FERNANDO TERAN"
        },
        {
          "lon": -77.0145425513318,
          "lat": -12.1736688066032,
          "address": "AV. PASEO DE LA REPUBLICA - JR. MADALENGOITIA"
        },
        {
          "lon": -77.0115037099048,
          "lat": -12.1769646403,
          "address": "AV. PASEO DE LA REPUBLICA - JR. LOS PUMAS"
        },
        {
          "lon": -77.0098141725836,
          "lat": -12.179034779338,
          "address": "AV. PASEO DE LA REPUBLICA - AV. MATELINI"
        },
        {
          "lon": -77.0194096723825,
          "lat": -12.1678625021042,
          "address": "AV. PROLG. PASEO DE LA REPUBLICA - ACCESO NORTE ESTACION TERAN"
        },
        {
          "lon": -77.0438433979395,
          "lat": -12.0332397912948,
          "address": "AV. CAQUETA - CA. GENERAL PEZET"
        },
        {
          "lon": -77.0441776030503,
          "lat": -12.0283572083854,
          "address": "AV. TUPAC AMARU - AV. CAQUETA - AV. FRANCISCO PIZARRO"
        },
        {
          "lon": -77.0569975584211,
          "lat": -11.9889715450801,
          "address": "AV. TUPAC AMARU - AV. CARLOS IZAGUIRRE"
        },
        {
          "lon": -77.0589256427345,
          "lat": -11.9801286606214,
          "address": "AV. TUPAC AMARU - AV. CHINCHAYSUYO"
        },
        {
          "lon": -77.0578692886034,
          "lat": -11.9853572428959,
          "address": "AV. TUPAC AMARU - AV. LAS ALMENDRAS"
        },
        {
          "lon": -77.0584546284768,
          "lat": -11.9831812482639,
          "address": "AV. TUPAC AMARU - AV. LOS ALISOS - CA. YAHUAR HUACA"
        },
        {
          "lon": -77.0548129008261,
          "lat": -12.0014432958739,
          "address": "AV. TUPAC AMARU - AV. LOS JAZMINES"
        },
        {
          "lon": -77.053793307391,
          "lat": -12.0073863529064,
          "address": "AV. TUPAC AMARU - AV. TOMAS VALLE"
        },
        {
          "lon": -77.0529745488633,
          "lat": -12.0110456432748,
          "address": "AV. TUPAC AMARU - AV. BARTOLOMÉ DE LAS CASAS"
        },
        {
          "lon": -77.0545002575261,
          "lat": -12.003797270526,
          "address": "AV. TUPAC AMARU - CA. LOS MELONES - CA. SANCHEZ CERRO"
        },
        {
          "lon": -77.0541235758922,
          "lat": -12.0055903322523,
          "address": "AV. TUPAC AMARU - CA. LOS PACAES - CA. LOS TIGRES"
        },
        {
          "lon": -77.0571471161007,
          "lat": -11.9877651334755,
          "address": "AV. TUPAC AMARU - JR. ARAVICUS"
        },
        {
          "lon": -77.0552644626304,
          "lat": -11.9976756359334,
          "address": "AV. TUPAC AMARU - JR. LOS PINOS"
        },
        {
          "lon": -77.0440649794652,
          "lat": -12.031408473088,
          "address": "AV. CAQUETA - CA. M. QUIMPER"
        },
        {
          "lon": -77.0437200043927,
          "lat": -12.0353925804224,
          "address": "AV. CAQUETA - ACCESO NORTE ESTACIÓN CAQUETA"
        },
        {
          "lon": -77.0435385388739,
          "lat": -12.0375371791021,
          "address": "AV. CAQUETA - ACCESO SUR ESTACIÓN CAQUETA"
        },
        {
          "lon": -77.0441670051349,
          "lat": -12.030492972978,
          "address": "AV. CAQUETA - AV. LOS PROCERES"
        },
        {
          "lon": -77.0499890807444,
          "lat": -12.0233132376154,
          "address": "AV. TUPAC AMARU - AV. EDUARDO DE HABICH"
        },
        {
          "lon": -77.0513803191361,
          "lat": -12.0180872440171,
          "address": "AV. TUPAC AMARU - AV. HONORIO DELGADO"
        },
        {
          "lon": -77.0520832361888,
          "lat": -12.0151130371375,
          "address": "AV. TUPAC AMARU - PASAJE EL ANGEL"
        },
        {
          "lon": -77.0413213698193,
          "lat": -12.072127353036,
          "address": "AV. SALAVERRY - JR. TALARA"
        },
        {
          "lon": -77.0418710155604,
          "lat": -12.0738549990119,
          "address": "AV. SALAVERRY - AV. 6 DE AGOSTO"
        },
        {
          "lon": -77.0420726548132,
          "lat": -12.0751682775675,
          "address": "AV. SALAVERRY - AV. CUBA"
        },
        {
          "lon": -77.0422876425108,
          "lat": -12.0765300031483,
          "address": "AV. SALAVERRY - AV. JOSE MARIATEGUI"
        },
        {
          "lon": -77.0487435121267,
          "lat": -12.0853249724493,
          "address": "AV. SALAVERRY - AV. CESAR CANEVARO"
        },
        {
          "lon": -77.0506875719255,
          "lat": -12.0880683215428,
          "address": "AV. SALAVERRY - AV. CADIZ* DEFINIR COLOR EN CUADRO"
        },
        {
          "lon": -77.025333572546,
          "lat": -12.0698142500211,
          "address": "AV. ISABEL LA CATÓLICA - PRLG. ANDAHUAYLAS"
        },
        {
          "lon": -77.0219359436787,
          "lat": -12.0693613205995,
          "address": "AV. ISABEL LA CATÓLICA - JR. ABTAO"
        },
        {
          "lon": -77.0179726299972,
          "lat": -12.0688311870764,
          "address": "AV. ISABEL LA CATÓLICA - PRLG. LUCANAS"
        },
        {
          "lon": -77.0268734742602,
          "lat": -12.0700169524373,
          "address": "AV. ISABEL LA CATÓLICA - AV. LUNA PIZARRO"
        },
        {
          "lon": -77.0090311956599,
          "lat": -12.0701971726214,
          "address": "AV. MÉXICO - AV. SAN PABLO"
        },
        {
          "lon": -77.0061867256351,
          "lat": -12.0680944989622,
          "address": "AV. MÉXICO - AV. SAN LUIS"
        },
        {
          "lon": -77.0037478742633,
          "lat": -12.0648945787187,
          "address": "AV. MÉXICO - JR. VALDEZ"
        },
        {
          "lon": -77.0129764913561,
          "lat": -12.0610312631621,
          "address": "Av. 28 de Julio - Av. Aviacion"
        },
        {
          "lon": -77.027850660722,
          "lat": -12.0629683545361,
          "address": "Av. 28 de Julio - Av. Luna Pizarro"
        },
        {
          "lon": -77.0276667939751,
          "lat": -12.0643326187723,
          "address": "AV. BAUSATE Y MEZA - AV. LUNA PIZARRO"
        },
        {
          "lon": -77.026060288465,
          "lat": -12.0641426395413,
          "address": "AV. BAUSATE Y MEZA - AV. PROLG. ANDAHUAYLAS"
        },
        {
          "lon": -77.0254186512982,
          "lat": -12.0640609414362,
          "address": "AV. BAUSATE Y MEZA - JR. SAN LORENZO"
        },
        {
          "lon": -77.0234271830506,
          "lat": -12.0637892580333,
          "address": "AV. BAUSATE Y MEZA - JR. MENDOZA MERINO"
        },
        {
          "lon": -77.0210673793833,
          "lat": -12.063502858047,
          "address": "AV. BAUSATE Y MEZA - JR. PROLG. CANGALLO"
        },
        {
          "lon": -77.0187388991248,
          "lat": -12.0631861123422,
          "address": "AV. BAUSATE Y MEZA - JR. PROLOG. LUCANAS"
        },
        {
          "lon": -77.0177272759275,
          "lat": -12.0630738564483,
          "address": "AV. BAUSATE Y MEZA - AV. PROLOG. PARINACOCHAS"
        },
        {
          "lon": -77.0153562292452,
          "lat": -12.0627200485564,
          "address": "AV. BAUSATE Y MEZA - AV. HUANUCO"
        },
        {
          "lon": -77.0237435926699,
          "lat": -12.0611072835905,
          "address": "JR. MENDOZA MERINO - AV. GARCIA NARANJO"
        },
        {
          "lon": -77.0175578323769,
          "lat": -12.0644328719531,
          "address": "JR. PARINACOCVHAS - AV. VON HUMBOLDT"
        },
        {
          "lon": -77.0324211364268,
          "lat": -12.0621941068541,
          "address": "AV. JOSE GALVEZ - AV. GARCIA NARANJO"
        },
        {
          "lon": -77.0280352380203,
          "lat": -12.0616342765256,
          "address": "AV. LUNA PIZARRO - AV. GARCIA NARANJO"
        },
        {
          "lon": -77.0230503005029,
          "lat": -12.0610108430617,
          "address": "JR. ABTAO - AV. GARCIA NARANJO"
        },
        {
          "lon": -77.0214123226637,
          "lat": -12.0608146613138,
          "address": "JR. CANGALLO - AV. GARCIA NARANJO"
        },
        {
          "lon": -77.019701801313,
          "lat": -12.0633226739461,
          "address": "JR. LA MAR - AV. BAUSATE Y MEZA"
        },
        {
          "lon": -77.015191407373,
          "lat": -12.0641295872209,
          "address": "JR. HUANUCO - AV. VON HUMBOLDT"
        },
        {
          "lon": -77.0160783079336,
          "lat": -12.0601110815615,
          "address": "JR. HUANUCO - AV. GARCIA NARANJO"
        },
        {
          "lon": -77.0166177118882,
          "lat": -12.058878359129,
          "address": "JR. HUANUCO - JR. ANTONIO RAYMONDI"
        },
        {
          "lon": -77.0174083153619,
          "lat": -12.065692443088,
          "address": "JR. PARINACOCHAS - AV. HIPOLITO UNANUE"
        },
        {
          "lon": -77.0223011550332,
          "lat": -12.066358439062,
          "address": "JR. ABTAO - AV. HIPOLITO UNANUE"
        },
        {
          "lon": -77.0193558060026,
          "lat": -12.065959893314,
          "address": "JR. LA MAR - AV. HIPOLITO UNANUE"
        },
        {
          "lon": -77.01840364307,
          "lat": -12.0658502119807,
          "address": "JR. LUCANAS - AV. HIPOLITO UNANUE"
        },
        {
          "lon": -77.0150319980929,
          "lat": -12.065406887368,
          "address": "JR. HUANUCO - AV. HIPOLITO UNANUE"
        },
        {
          "lon": -77.0146260170376,
          "lat": -12.0684081416613,
          "address": "AV. ISABEL LA CATÓLICA - JR. HUÁNUCO"
        },
        {
          "lon": -77.0409361206035,
          "lat": -11.9161731741469,
          "address": "AV. TUPAC AMARU - AV. REVOLUCION"
        },
        {
          "lon": -77.0423055486593,
          "lat": -11.9230403427173,
          "address": "AV. TUPAC AMARU - AV. SANGARARA"
        },
        {
          "lon": -77.0430275181328,
          "lat": -11.9269870692448,
          "address": "AV. TUPAC AMARU - CA. MIGUEL GRAU"
        },
        {
          "lon": -77.0670926871663,
          "lat": -12.0914468060798,
          "address": "AV. BRASIL - JR. FELIX DIBOS"
        },
        {
          "lon": -77.0457291520283,
          "lat": -12.0652818858632,
          "address": "AV. BRASIL - AV. 28 DE JULIO"
        },
        {
          "lon": -77.0435445570109,
          "lat": -12.0651217203841,
          "address": "AV. 28 DE JULIO - CALLE. RIVERO DE USTARIS"
        },
        {
          "lon": -76.9681533484016,
          "lat": -12.0563471597354,
          "address": "AV. NICOLAS AYLLON - AV.  LOS FRUTALES"
        },
        {
          "lon": -76.9708517530375,
          "lat": -12.0574794314943,
          "address": "AV. NICOLAS AYLLON - AV. SANTA ROSA"
        },
        {
          "lon": -76.9762915312708,
          "lat": -12.0598324201618,
          "address": "AV. NICOLAS AYLLON - TOMAS ALBA EDDISON"
        },
        {
          "lon": -77.0179339928451,
          "lat": -12.1337785029594,
          "address": "AV. REPUBLICA DE PANAMA - Av. Mariscal Ramon Castilla"
        },
        {
          "lon": -77.0475378527631,
          "lat": -12.0653747086738,
          "address": "JR. RESTAURACIÓN -  CA. FERNANDINI"
        },
        {
          "lon": -77.0452951066075,
          "lat": -12.0603926070356,
          "address": "JR. REBECA OQUENDO -  JR. HUARAZ"
        },
        {
          "lon": -77.0506649892036,
          "lat": -12.0627461109655,
          "address": "JR. RESTAURACION  -  JR. JORGE CHAVEZ"
        },
        {
          "lon": -77.0487104283526,
          "lat": -12.064401006194,
          "address": "JR. RESTAURACIÓN - CA. HUARAZ"
        },
        {
          "lon": -77.0512049686482,
          "lat": -12.0673604430266,
          "address": "JR. HUARAZ - JR. CASTRO VIRREYNA"
        },
        {
          "lon": -77.0504004064043,
          "lat": -12.0664099433767,
          "address": "JR. HUARAZ - GRAL. ORBEGOSO"
        },
        {
          "lon": -77.0483710011436,
          "lat": -12.0663926638993,
          "address": "JR. FERNADINI - CA. CENTENARIO"
        },
        {
          "lon": -77.0450940814529,
          "lat": -12.0536688182388,
          "address": "JR. CARHUAZ - JR. HUARAZ"
        },
        {
          "lon": -77.0586147525575,
          "lat": -12.0596959113508,
          "address": "AV. TINGO MARIA - JR. GRAL. ORBEGOSO"
        },
        {
          "lon": -77.0598154513797,
          "lat": -12.0635478781453,
          "address": "AV. TINGO MARIA - AV. A. BERTELLO"
        },
        {
          "lon": -77.052152243855,
          "lat": -12.0585822892209,
          "address": "AV. INDEPENDENCIA - AV. AGUARICO"
        },
        {
          "lon": -77.0492171980796,
          "lat": -12.0610353165615,
          "address": "AV. INDEPENDENCIA -  JR. JORGE CHAVEZ"
        },
        {
          "lon": -77.0513645547518,
          "lat": -12.0656061167091,
          "address": "AV. GRAL. VARELA - JR. GRAL. ORBEGOSO"
        },
        {
          "lon": -77.0505441097419,
          "lat": -12.0646221636573,
          "address": "AV. GRAL. VARELA - JR. CENTENARIO"
        },
        {
          "lon": -77.0521949522453,
          "lat": -12.0665778970968,
          "address": "AV. GRAL. VARELA - JR. CASTROVIRREYNA"
        },
        {
          "lon": -77.0481972027742,
          "lat": -12.068147534161,
          "address": "AV. BRASIL - JR. GRAL. ORBEGOSO"
        },
        {
          "lon": -77.0489908944957,
          "lat": -12.0691324549791,
          "address": "AV. BRASIL - JR. CASTROVIRREYNA"
        },
        {
          "lon": -77.0474169669401,
          "lat": -12.0672210953988,
          "address": "AV. BRASIL - CA. CENTENARIO"
        },
        {
          "lon": -77.026901676211,
          "lat": -11.8905029089447,
          "address": "AV. TUPAC AMARU - AV. AMADOR MEDINA REINA"
        },
        {
          "lon": -77.0232597804961,
          "lat": -11.8850721329532,
          "address": "AV. TUPAC AMARU - AV. CENTRAL"
        },
        {
          "lon": -77.0303435474054,
          "lat": -11.8989019946894,
          "address": "AV. TUPAC AMARU - AV. ISABEL CHIMPU OCLLO"
        },
        {
          "lon": -77.0155738303303,
          "lat": -11.8735148887667,
          "address": "AV. TUPAC AMARU - AV. MANUEL PRADO"
        },
        {
          "lon": -77.0235808474794,
          "lat": -11.8875522420393,
          "address": "AV. TUPAC AMARU - AV. PARQUE ZONAL - AV. JOSE PARDO"
        },
        {
          "lon": -77.034246296433,
          "lat": -11.9083866796455,
          "address": "AV. TUPAC AMARU - AV. SAN FELIPE"
        },
        {
          "lon": -77.029039808898,
          "lat": -11.8954484018326,
          "address": "AV. TUPAC AMARU - JR. INDUSTRIAL - JR. LA FLOR"
        },
        {
          "lon": -77.0214502079245,
          "lat": -11.8808251691409,
          "address": "AV. TUPAC AMARU - AV. MIRAFLORES"
        },
        {
          "lon": -77.0360759318591,
          "lat": -11.8949887910306,
          "address": "AV. UNIVERSITARIA - AV. CHIMPU OCLLO - MICAELA BASTIDAS"
        },
        {
          "lon": -77.021213022262,
          "lat": -11.8701026636411,
          "address": "AV. UNIVERSITARIA - AV. MANUEL PRADO"
        },
        {
          "lon": -77.0442128434047,
          "lat": -12.0650958409891,
          "address": "AV. 28 DE JULIO - CA. CORONEL TAFUR"
        },
        {
          "lon": -77.0306692174707,
          "lat": -12.0737734368234,
          "address": "AV. ALEJANDRO TIRADO - AV. JOSÉ GÁLVEZ"
        },
        {
          "lon": -77.0325310880021,
          "lat": -12.0740371252209,
          "address": "AV. ALEJANDRO TIRADO - AV. NICOLÁS DE ARANIBAR"
        },
        {
          "lon": -77.0724310104443,
          "lat": -12.0461663800497,
          "address": "AV. ARGENTINA - Jr. Leonardo Arrieta"
        },
        {
          "lon": -77.061175175015,
          "lat": -12.04514108036,
          "address": "AV. ARGENTINA - Jr. Monsefu"
        },
        {
          "lon": -77.0509453670339,
          "lat": -12.0441739528148,
          "address": "AV. ARGENTINA - JR. RAMON CARCAMO"
        },
        {
          "lon": -77.0496293853729,
          "lat": -12.0443058223332,
          "address": "AV. ARGENTINA - Jr. Garcia Villon"
        },
        {
          "lon": -77.0470404232569,
          "lat": -12.0440439079951,
          "address": "AV. ARGENTINA - Jr. Pacasmayo"
        },
        {
          "lon": -77.0444364811992,
          "lat": -12.0437944981617,
          "address": "AV. ARGENTINA - Jr. Huarochiri"
        },
        {
          "lon": -77.0652445189567,
          "lat": -12.045491540149,
          "address": "AV. ARGENTINA - AV. NICOLAS DUEÑAS"
        },
        {
          "lon": -77.0486742020994,
          "lat": -12.0742658357128,
          "address": "AV. HORACIO URTEAGA - AV. REPUBLICA DOMINICANA"
        },
        {
          "lon": -77.067519617076,
          "lat": -12.0677156918607,
          "address": "AV. MARIANO CORNEJO - AV. ALBORADA"
        },
        {
          "lon": -77.0736711669326,
          "lat": -12.0678780760053,
          "address": "AV. MARIANO CORNEJO - GERTUDRIS"
        },
        {
          "lon": -77.0720123580516,
          "lat": -12.0493658473658,
          "address": "AV. OSCAR R. BENAVIDES - AV. ARRIETA"
        },
        {
          "lon": -77.067250301851,
          "lat": -12.0488338280409,
          "address": "AV. OSCAR R. BENAVIDES -Av. Luis Braile"
        },
        {
          "lon": -77.0714124638349,
          "lat": -12.0493093097663,
          "address": "AV. OSCAR R. BENAVIDES - Av. Victor Sarria Arzubiaga"
        },
        {
          "lon": -77.0649015215432,
          "lat": -12.04856836093,
          "address": "AV. OSCAR R. BENAVIDES - AV. NICOLAS DUEÑAS"
        },
        {
          "lon": -77.0756037154878,
          "lat": -12.0594223770415,
          "address": "AV. VENEZUELA - AV. AURELIO GARCÍA GARCÍA"
        },
        {
          "lon": -77.0684321265413,
          "lat": -12.057833456229,
          "address": "AV. VENEZUELA - AV. THORNDIKE"
        },
        {
          "lon": -77.0672308797169,
          "lat": -12.0576888129835,
          "address": "AV. VENEZUELA - AV. LA ALBORADA"
        },
        {
          "lon": -77.0638949234627,
          "lat": -12.0569956923514,
          "address": "AV. VENEZUELA - AV. TELLERIA"
        },
        {
          "lon": -77.0303672411386,
          "lat": -12.0759132952905,
          "address": "CA. ENRIQUE VILLAR - AV. JOSÉ GÁLVEZ"
        },
        {
          "lon": -77.0311744167895,
          "lat": -12.0760232600627,
          "address": "CA. ENRIQUE VILLAR - CA. TORRES PAZ"
        },
        {
          "lon": -77.0300593173279,
          "lat": -12.0778469772707,
          "address": "CA. MANUEL DEL PINO - AV. JOSÉ GÁLVEZ"
        },
        {
          "lon": -77.0305247538515,
          "lat": -12.074818403845,
          "address": "CA. MARIANO CARRANZA - AV. JOSÉ GÁLVEZ"
        },
        {
          "lon": -77.0300364445641,
          "lat": -12.0747726647301,
          "address": "CA. MARIANO CARRANZA - AV. PASEO DE LA REPUBLICA"
        },
        {
          "lon": -77.0310176775406,
          "lat": -12.0770781381043,
          "address": "CA. TEODORO CÁRDENAS - CA. TORRES PAZ"
        },
        {
          "lon": -77.0320039024203,
          "lat": -12.0772034289153,
          "address": "CA. TEODORO CARDENAS - CARLOS ARRIETA"
        },
        {
          "lon": -77.0301925290899,
          "lat": -12.0769893080745,
          "address": "CA. TEODORO CÁRDENAS - AV. JOSÉ GÁLVEZ"
        },
        {
          "lon": -77.0494653803072,
          "lat": -12.07200564741,
          "address": "JR. GRAL GARZON - AV. JOSÉ MARÍA PLAZA"
        },
        {
          "lon": -77.0438454021498,
          "lat": -11.9298231796808,
          "address": "AV. TUPAC AMARU - AV. JAMAICA"
        },
        {
          "lon": -77.0468251306566,
          "lat": -11.9336856337168,
          "address": "AV. TUPAC AMARU - AV. MICAELA BASTIDAS"
        },
        {
          "lon": -77.0514750650422,
          "lat": -11.9525362079705,
          "address": "AV. TUPAC AMARU - AV. 22 DE AGOSTO"
        },
        {
          "lon": -77.0559588437451,
          "lat": -11.9661612403515,
          "address": "AV. TUPAC AMARU - AV. DEL MAESTRO"
        },
        {
          "lon": -77.0548035794735,
          "lat": -11.9625324404545,
          "address": "AV. TUPAC AMARU - AV. EL PARRAL"
        },
        {
          "lon": -77.0414656638234,
          "lat": -11.9172524411044,
          "address": "AV. TUPAC AMARU - AV. LOS INCAS"
        },
        {
          "lon": -77.0423679145667,
          "lat": -11.9248164111409,
          "address": "AV. TUPAC AMARU - AV. MIGUEL GRAU"
        },
        {
          "lon": -77.0493000655831,
          "lat": -11.9488130030347,
          "address": "AV. TUPAC AMARU - AV. SANTA ROSA"
        },
        {
          "lon": -77.0537041467585,
          "lat": -11.9599166153116,
          "address": "AV. TUPAC AMARU - AV. SAN MARTIN"
        },
        {
          "lon": -77.0503796503435,
          "lat": -11.9409306200929,
          "address": "AV. TUPAC AMARU - AV. VICTOR ANDRES BELAUNDE"
        },
        {
          "lon": -77.0574179350439,
          "lat": -11.9717268058531,
          "address": "AV. TUPAC AMARU - CA. 23 - CA. LA MERCED"
        },
        {
          "lon": -77.0488544062765,
          "lat": -11.9368250973457,
          "address": "AV. TUPAC AMARU - CA. CIRO ALEGRIA"
        },
        {
          "lon": -77.03997563718,
          "lat": -11.9139747339654,
          "address": "AV. TUPAC AMARU - CA. SAN JULIAN"
        },
        {
          "lon": -77.0666602553102,
          "lat": -12.0281088673841,
          "address": "AV. JOSE GRANDA - AV. TUPAC AMARU"
        },
        {
          "lon": -77.0687222463879,
          "lat": -12.0103123819171,
          "address": "AV. TOMAS VALLE - AL. ALFA"
        },
        {
          "lon": -77.0704308673988,
          "lat": -12.0107550517064,
          "address": "AV. TOMAS VALLE - AV. GERMAN AGUIRRE (BETA)"
        },
        {
          "lon": -77.049494770299,
          "lat": -12.0736239319704,
          "address": "Republica de Panama - Av. Arnaldo Marquez"
        },
        {
          "lon": -77.048718837586,
          "lat": -12.0774936580758,
          "address": "AV. MARIATEGUI - AV. MELLO FRANCO - CA. HUIRACOCHA"
        },
        {
          "lon": -77.0451947380229,
          "lat": -12.0769550453151,
          "address": "AV. MARIATEGUI - JR. PACHACUTEC"
        },
        {
          "lon": -77.0547144825609,
          "lat": -12.0783794834671,
          "address": "AV. GRAL GARZON - AV. JAVIER MARIATEGUI"
        },
        {
          "lon": -77.0510889426752,
          "lat": -12.0739973398749,
          "address": "JR. GRAL GARZON -  JR. MRCAL. LUZURIAGA"
        },
        {
          "lon": -77.0478499624869,
          "lat": -12.0700357608773,
          "address": "JR. GRAL GARZON -  JR. GRAL. CANTERAC"
        },
        {
          "lon": -77.0446061093245,
          "lat": -12.0661032678905,
          "address": "JR. GRAL GARZON -  JR. LOS MOGABUROS"
        },
        {
          "lon": -77.0519219864028,
          "lat": -12.0750133183069,
          "address": "JR. GRAL GARZON - JR. AFRANIO DE MELLO FRANCO"
        },
        {
          "lon": -77.0470450663994,
          "lat": -12.0690849807735,
          "address": "JR. GRAL GARZON - AV. TALARA"
        },
        {
          "lon": -77.0572623098128,
          "lat": -12.0814682796741,
          "address": "JR. GRAL GARZON - AV. SAN FELIPE"
        },
        {
          "lon": -77.0502885151191,
          "lat": -12.0730204308366,
          "address": "JR. GRAL GARZON - AV. REPÚBLICA DOMINICANA"
        },
        {
          "lon": -77.0477369983344,
          "lat": -12.0793848308378,
          "address": "JR. HUSARES DE JUNIN - AV. OLAVEGOYA"
        },
        {
          "lon": -77.0504847573525,
          "lat": -12.0777457455054,
          "address": "JR. HUSARES DE JUNIN - AV. FRANCISCO JAVIER MARIÁTEGUI"
        },
        {
          "lon": -77.0527198390293,
          "lat": -12.0759493420651,
          "address": "JR. HUSARES DE JUNIN - JR. GRAL. GARZÓN"
        },
        {
          "lon": -77.0155507753117,
          "lat": -12.0613347259253,
          "address": "Av. 28 de Julio - JR. HUANUCO"
        },
        {
          "lon": -77.0235747822322,
          "lat": -12.0624077976557,
          "address": "Av. 28 de Julio - Jr. Mendoza Merino"
        },
        {
          "lon": -77.0262569066023,
          "lat": -12.062742232475,
          "address": "Av. 28 de Julio - Prlg. Andahuaylas"
        },
        {
          "lon": -77.0212477476846,
          "lat": -12.0621100936141,
          "address": "Av. 28 de Julio - Prlg. Cangallo"
        },
        {
          "lon": -77.018921078074,
          "lat": -12.0617969004843,
          "address": "Av. 28 de Julio - Prlg. Lucanas"
        },
        {
          "lon": -77.0179571052689,
          "lat": -12.0616553224084,
          "address": "Av. 28 de Julio - Prlg. Parinacochas"
        },
        {
          "lon": -77.06603258133,
          "lat": -12.0051877056745,
          "address": "AV. ANGELICA GAMARRA - CA. TUPAC YUPANQUI"
        },
        {
          "lon": -77.0695924744802,
          "lat": -12.0056581390664,
          "address": "AV. ANGELICA GAMARRA - AV. ALFA"
        },
        {
          "lon": -77.0716404305719,
          "lat": -12.0060065740192,
          "address": "AV. ANGELICA GAMARRA - AV. BETA"
        },
        {
          "lon": -77.0875366766454,
          "lat": -12.009541395757,
          "address": "AV. ANGELICA GAMARRA - AV. LOS PROCERES"
        },
        {
          "lon": -77.061924511907,
          "lat": -12.0043480434295,
          "address": "PANAMERICANA NORTE - AV. ANGELICA GAMARRA - AV. FRANCISCO BOLOGNESI"
        },
        {
          "lon": -77.0762663159908,
          "lat": -11.9950245559362,
          "address": "AV. ANTUNEZ DE MAYOLO - CA. ESCORPIO"
        },
        {
          "lon": -77.0720796542517,
          "lat": -11.9941248490058,
          "address": "AV. ANTUNEZ DE MAYOLO - CA. ORION"
        },
        {
          "lon": -77.1102446635845,
          "lat": -11.9942304567132,
          "address": "AV. CANTA CALLAO - AV. NARANJAL - AV.PACASMAYO"
        },
        {
          "lon": -77.0636328172647,
          "lat": -11.9897286431996,
          "address": "AV. CARLOS IZAGUIRRE - AV. ALFREDO MENDIOLA"
        },
        {
          "lon": -77.0687513878564,
          "lat": -11.990825468193,
          "address": "AV. CARLOS IZAGUIRRE - AV. JOSE SANTOS CHOCANO"
        },
        {
          "lon": -77.0716401410116,
          "lat": -11.9912004406478,
          "address": "AV. CARLOS IZAGUIRRE - AV. LAS PALMERAS"
        },
        {
          "lon": -77.0751003145503,
          "lat": -11.9918143177017,
          "address": "AV. CARLOS IZAGUIRRE - JR. CONOCOCHA"
        },
        {
          "lon": -77.0879689851709,
          "lat": -11.9911756527592,
          "address": "AV. CARLOS IZAGUIRRE - AV. HAYA DE LA TORRE (SAN FRANCISCO)"
        },
        {
          "lon": -77.0930049032233,
          "lat": -11.9907475903811,
          "address": "AV. CARLOS IZAGUIRRE - AV. 12 DE OCTUBRE"
        },
        {
          "lon": -77.0967818669654,
          "lat": -11.9901544521286,
          "address": "AV. CARLOS IZAGUIRRE - AV. SANTA ROSA"
        },
        {
          "lon": -77.0850146726301,
          "lat": -11.9805954269881,
          "address": "AV. HUANDOY - CA. LOS OLIVOS"
        },
        {
          "lon": -77.064086522251,
          "lat": -12.0295229461931,
          "address": "AV. JOSE GRANDA - AV. ALAMOS"
        },
        {
          "lon": -77.0831175513902,
          "lat": -12.0227582655289,
          "address": "AV. JOSE GRANDA - AV. CANTA"
        },
        {
          "lon": -77.086113866403,
          "lat": -12.0213488921048,
          "address": "AV. JOSE GRANDA - AV. LOS PROCERES"
        },
        {
          "lon": -77.0586494509329,
          "lat": -12.028694710367,
          "address": "AV. JOSE GRANDA - PANAMERICANA NORTE"
        },
        {
          "lon": -77.060454163849,
          "lat": -12.0299684468837,
          "address": "AV. JOSE GRANDA - CA. NICOLAS DE PIEROLA"
        },
        {
          "lon": -77.0719585743705,
          "lat": -11.9891992368169,
          "address": "AV. LAS PALMERAS - CA. LAS HIEDRAS"
        },
        {
          "lon": -77.0723236177195,
          "lat": -11.9868305040275,
          "address": "AV. LAS PALMERAS - CA. AMARANTOS"
        },
        {
          "lon": -77.0723046378091,
          "lat": -11.9819226738026,
          "address": "AV. LAS PALMERAS - ALAMEDA CENTRAL"
        },
        {
          "lon": -77.0725276327949,
          "lat": -11.972101164178,
          "address": "AV. LAS PALMERAS - AV. MARAÑON"
        },
        {
          "lon": -77.0611608481703,
          "lat": -11.9835411266503,
          "address": "AV. LOS ALISOS - CA. LAS MARACUYAS"
        },
        {
          "lon": -77.07224333357,
          "lat": -11.9837692478863,
          "address": "AV. LOS ALISOS - AV. LAS PALMERAS"
        },
        {
          "lon": -77.0858321282105,
          "lat": -11.9825229898445,
          "address": "AV. LOS ALISOS - AV. HUANDOY"
        },
        {
          "lon": -77.0831986783148,
          "lat": -11.9765543912829,
          "address": "AV. NARANJAL - AV. HUANDOY"
        },
        {
          "lon": -77.0626955989872,
          "lat": -11.9768893801899,
          "address": "AV. NARANJAL - JR. LOS YUNQUES"
        },
        {
          "lon": -77.0722974153387,
          "lat": -11.9785058198907,
          "address": "AV. NARANJAL - AV. LAS PALMERAS"
        },
        {
          "lon": -77.0872167535718,
          "lat": -12.0121853488193,
          "address": "AV. TOMAS VALLE - AV. LOS PROCERES"
        },
        {
          "lon": -77.0826275966817,
          "lat": -12.0130045235668,
          "address": "AV. TOMAS VALLE - CA. PEDRO UNANUE - CA. DIEGO FERRE"
        },
        {
          "lon": -77.0617027817186,
          "lat": -12.0080387152404,
          "address": "AV. TOMAS VALLE - PANAMERICANA NORTE"
        },
        {
          "lon": -77.0708938922631,
          "lat": -12.0957053548611,
          "address": "AV. BRASIL - JR. SALAVERRY"
        },
        {
          "lon": -77.0686513690692,
          "lat": -12.0931412163414,
          "address": "AV. BRASIL - JR. BOLOGNESI"
        },
        {
          "lon": -77.069369392374,
          "lat": -12.0940095572419,
          "address": "AV. BRASIL -  JR. MIGUEL GRAU"
        },
        {
          "lon": -77.0714179060252,
          "lat": -12.0966301547449,
          "address": "AV. BRASIL - CA. MIRAFLORES"
        },
        {
          "lon": -77.0718276757896,
          "lat": -12.0971373237756,
          "address": "AV. BRASIL - AV. PÉREZ ARANIBAR (EJERCITO)"
        },
        {
          "lon": -77.069423964785,
          "lat": -12.0979818201542,
          "address": "AV. EJÉRCITO - JR. LARCO HERRERA"
        },
        {
          "lon": -77.0620831859442,
          "lat": -12.1005945252755,
          "address": "AV. EJÉRCITO - CA. BACA FLOR (BAJADA DE MARBELLA)"
        },
        {
          "lon": -77.0608651159585,
          "lat": -12.1009662431177,
          "address": "AV. EJÉRCITO - AV. JUAN DE ALIAGA"
        },
        {
          "lon": -77.0707421942373,
          "lat": -12.0923833502561,
          "address": "AV. SUCRE -  JR. BOLOGNESI"
        },
        {
          "lon": -77.0710882729094,
          "lat": -12.0934072384899,
          "address": "AV. SUCRE -  JR. GRAU"
        },
        {
          "lon": -77.0692721351773,
          "lat": -12.0884709268499,
          "address": "AV. SUCRE - JR. JUNÍN"
        },
        {
          "lon": -77.0721857813798,
          "lat": -12.0963749434962,
          "address": "AV. SUCRE - JR. COMANDANTE ESPINAR"
        },
        {
          "lon": -77.072395227906,
          "lat": -12.0969446298411,
          "address": "AV. SUCRE - JR. DIEGO FERRE"
        },
        {
          "lon": -77.0718342975325,
          "lat": -12.0954130246665,
          "address": "AV. SUCRE - JR. SALAVERRY"
        },
        {
          "lon": -77.0728779615569,
          "lat": -12.0949996400752,
          "address": "JR. TACNA - JR. SALAVERRY"
        },
        {
          "lon": -77.0732346096871,
          "lat": -12.0959846007071,
          "address": "JR. TACNA -  JR. COMANDANTE ESPINAR"
        },
        {
          "lon": -77.0398549467503,
          "lat": -12.1140867736538,
          "address": "AV. ANGAMOS - AV. SANTA CRUZ"
        },
        {
          "lon": -77.0471089004733,
          "lat": -12.1146777445525,
          "address": "AV. EJERCITO - CA. IGNACIO MERINO"
        },
        {
          "lon": -77.0499668835318,
          "lat": -12.1117098665213,
          "address": "AV. DEL EJERCITO - CA. MARIANO MELGAR"
        },
        {
          "lon": -77.0513467859293,
          "lat": -12.1102614617435,
          "address": "AV. EJÉRCITO - CA. JORGE POLAR"
        },
        {
          "lon": -77.0449922412304,
          "lat": -12.1167810550598,
          "address": "AV. EJÉRCITO - CA. MANUEL TOVAR"
        },
        {
          "lon": -77.0181742487554,
          "lat": -12.1232636046878,
          "address": "AV. REPÚBLICA DE PANAMÁ - AV. RICARDO PALMA"
        },
        {
          "lon": -77.0181038126973,
          "lat": -12.1262800160996,
          "address": "AV. REPÚBLICA DE PANAMÁ - AV. ALFREDO BENAVIDES"
        },
        {
          "lon": -77.0182153614908,
          "lat": -12.1205824615249,
          "address": "AV. REPÚBLICA DE PANAMÁ - AV. ROCA Y BOLOÑA"
        },
        {
          "lon": -77.0179908177429,
          "lat": -12.1304202539236,
          "address": "AV. REPÚBLICA DE PANAMÁ - AV. 28 DE JULIO"
        },
        {
          "lon": -77.0557896687965,
          "lat": -12.0979827786798,
          "address": "AV. SALAVERRY - AV. ALBERTO DEL CAMPO"
        },
        {
          "lon": -77.0419670538942,
          "lat": -12.1170928497981,
          "address": "AV. SANTA CRUZ - AV. MARISCAL LA MAR"
        },
        {
          "lon": -77.0056963194695,
          "lat": -12.1221632601842,
          "address": "AV. TOMAS MARSANO - AV. MANUEL VILLARÁN"
        },
        {
          "lon": -77.0086992301709,
          "lat": -12.1177036159454,
          "address": "AV. TOMAS MARSANO - AV. ROCA Y BOLOÑA"
        },
        {
          "lon": -77.0641291481832,
          "lat": -12.0721434545793,
          "address": "AV. BOLIVAR - AV. GRAL. CÓRDOVA"
        },
        {
          "lon": -77.0757315599495,
          "lat": -12.0708907734098,
          "address": "AV. BOLIVAR - JR. ABRAHAM LINCOLN"
        },
        {
          "lon": -77.05570645409,
          "lat": -12.0736490099259,
          "address": "AV. BOLIVAR - AV. DEL RIO"
        },
        {
          "lon": -77.0589770817242,
          "lat": -12.0718611138117,
          "address": "AV. BOLIVAR - AV. PASEO DE LOS ANDES"
        },
        {
          "lon": -77.0599707876544,
          "lat": -12.082580736781,
          "address": "AV. BRASIL - JR. DIEGO DE ALMAGRO"
        },
        {
          "lon": -77.0513662475316,
          "lat": -12.0722225102037,
          "address": "AV. BRASIL - JR. GRAL. BORGOÑO"
        },
        {
          "lon": -77.0615677617535,
          "lat": -12.0845140628262,
          "address": "AV. BRASIL - CA. CABO GUTARRA (DE LA POLICIA)"
        },
        {
          "lon": -77.0530305255839,
          "lat": -12.0741858320647,
          "address": "AV. BRASIL - AV. MELLO FRANCO"
        },
        {
          "lon": -77.0583187940929,
          "lat": -12.0806197145353,
          "address": "AV. BRASIL - AV. GRAL. VIVANCO (AV. SAN FELIPE)"
        },
        {
          "lon": -77.0565544582282,
          "lat": -12.0786148173578,
          "address": "AV. BRASIL - AV. F. JAVIER MARIÁTEGUI"
        },
        {
          "lon": -77.0505055963344,
          "lat": -12.0711997100108,
          "address": "AV. BRASIL - AV. COLOMBIA"
        },
        {
          "lon": -77.0537911887319,
          "lat": -12.0751320366741,
          "address": "AV. BRASIL - AV. BOLÍVAR"
        },
        {
          "lon": -77.0553768539133,
          "lat": -12.0770432940466,
          "address": "AV. BRASIL - AV. CAYETANO H."
        },
        {
          "lon": -77.0694529323627,
          "lat": -12.0776568922472,
          "address": "AV. CIPRIANO DULANTO - JR. DANIEL HERNANDEZ"
        },
        {
          "lon": -77.0741347708601,
          "lat": -12.0762383515605,
          "address": "AV. CIPRIANO DULANTO (EX LA MAR) - AV. JUAN VALER SANDOVAL"
        },
        {
          "lon": -77.0776262227214,
          "lat": -12.0753393303073,
          "address": "AV. CIPRIANO DULANTO (EX LA MAR) - CDRA 10 (INGRESO AELU)*"
        },
        {
          "lon": -77.07635026856,
          "lat": -12.0755320944663,
          "address": "AV. LA MAR - JR. ABRAHAM LINCOLN"
        },
        {
          "lon": -77.0625564132007,
          "lat": -12.0737470054875,
          "address": "AV. SUCRE - AV. GRAL. CÓRDOVA"
        },
        {
          "lon": -77.0645297604402,
          "lat": -12.0772556177814,
          "address": "AV. SUCRE -  AV. GRAL. MANUEL VIVANCO"
        },
        {
          "lon": -77.0616046014605,
          "lat": -12.0716091562131,
          "address": "AV. SUCRE - AV. BOLÍVAR"
        },
        {
          "lon": -77.0654345729992,
          "lat": -12.0788168262289,
          "address": "AV. SUCRE - AV. CIPRIANO DULANTO"
        },
        {
          "lon": -77.0635916694268,
          "lat": -12.0755967326326,
          "address": "AV. SUCRE - AV. JOSÉ LEGUÍA MELÉNDEZ"
        },
        {
          "lon": -77.042176470197,
          "lat": -12.0918477588688,
          "address": "AV. 2  DE MAYO - CA. LOS ALAMOS*"
        },
        {
          "lon": -77.0475616031698,
          "lat": -12.0925931006607,
          "address": "AV. 2  DE MAYO - AV. PRESCOTT*"
        },
        {
          "lon": -77.0187723358256,
          "lat": -12.1019586044477,
          "address": "AV. ARAMBURU - AV. REPUBLICA DE PANAMA"
        },
        {
          "lon": -77.053835411341,
          "lat": -12.1077665746729,
          "address": "AV. EJÉRCITO - AV. CRNEL PEDRO PORTILLO"
        },
        {
          "lon": -77.0551914412855,
          "lat": -12.106386830289,
          "address": "AV. EJÉRCITO - CA. GODOFREDO GARCÍA - CA. VALLERIESTRA"
        },
        {
          "lon": -77.0544483388522,
          "lat": -12.1071482109968,
          "address": "AV. EJÉRCITO - AV. OCTAVIO ESPINOZA"
        },
        {
          "lon": -77.0186088634747,
          "lat": -12.1056777527788,
          "address": "AV. REPÚBLICA DE PANAMÁ - AV. DOMINGO ORUE"
        },
        {
          "lon": -77.0185636883488,
          "lat": -12.1078570479843,
          "address": "AV. REPÚBLICA DE PANAMÁ - CA. PORTOCARRERO"
        },
        {
          "lon": -77.0205362747716,
          "lat": -12.0974137268179,
          "address": "AV. REPÚBLICA DE PANAMÁ - AV. CANAVAL Y MOREYRA"
        },
        {
          "lon": -77.0574655379656,
          "lat": -12.10131877617,
          "address": "AV. SALAVERRY - AV. JUAN ANTONIO PEZET"
        },
        {
          "lon": -77.0583220272921,
          "lat": -12.1029160503442,
          "address": "AV. SALAVERRY - AV. EJÉRCITO"
        },
        {
          "lon": -76.9803966423636,
          "lat": -12.1503682319885,
          "address": "Av. Los Heroes - Metro de Atocongo"
        },
        {
          "lon": -76.9764783645449,
          "lat": -12.1523435765224,
          "address": "Av. Los Heroes - Ca. Buckinghan"
        },
        {
          "lon": -76.9718359710324,
          "lat": -12.1541645678611,
          "address": "Av. Los Heroes - Av. San Juan"
        },
        {
          "lon": -76.9586905143136,
          "lat": -12.1596169599492,
          "address": "AV. LOS HEROES - AV. MIGUEL IGLESIAS"
        },
        {
          "lon": -76.9946571775173,
          "lat": -12.0705322515952,
          "address": "AV. CIRCUNVALACIÓN - JR. MANUEL BEINGOLEA"
        },
        {
          "lon": -76.9927283729298,
          "lat": -12.072591960084,
          "address": "AV. CIRCUNVALACIÓN - AV. MARISCAL NIETO"
        },
        {
          "lon": -77.0910211459979,
          "lat": -12.0011175428845,
          "address": "AV. ANTUNEZ DE MAYOLO - AV. 12 DE OCTUBRE"
        },
        {
          "lon": -77.1050993001484,
          "lat": -11.9890765565774,
          "address": "AV. CANTA CALLAO - AV. CARLOS IZAGUIRRE"
        },
        {
          "lon": -77.0994426570289,
          "lat": -11.9838534248973,
          "address": "AV. CANTA CALLAO - AV. LOS OLIVOS"
        },
        {
          "lon": -77.0968443523339,
          "lat": -11.9815562997002,
          "address": "AV. CANTA CALLAO - AV. LOS ALISOS"
        },
        {
          "lon": -77.0997797930762,
          "lat": -11.9836470176104,
          "address": "AV. CANTA CALLAO - CA. LOS OLIVOS"
        },
        {
          "lon": -77.1149399215816,
          "lat": -11.9992362487184,
          "address": "AV. CANTA CALLAO - CA. 3"
        },
        {
          "lon": -77.0879283897211,
          "lat": -11.9742826027719,
          "address": "AV. CANTA CALLAO - AV. NARANJAL"
        },
        {
          "lon": -77.1070554467891,
          "lat": -11.9907223159201,
          "address": "AV. CANTA CALLAO - AV. LOS DOMINICOS"
        },
        {
          "lon": -77.0915492018583,
          "lat": -12.0703919106417,
          "address": "AV. PRECURSORES - AV. RAFAEL ESCARDO"
        },
        {
          "lon": -77.087090540444,
          "lat": -12.0737606119117,
          "address": "AV. LA MAR - AV. PARQUE DE LAS LEYENDAS"
        },
        {
          "lon": -77.0837757891055,
          "lat": -12.0745842165997,
          "address": "AV. LA MAR - AV. JOSÉ DE LA RIVA AGÜERO"
        },
        {
          "lon": -77.0850272159137,
          "lat": -12.0893885076123,
          "address": "AV. LA PAZ - CA. LEONCIO PRADO"
        },
        {
          "lon": -77.1078509798773,
          "lat": -12.0786808345429,
          "address": "AV. LA PAZ - JR. SANTA ROSA (CA. 27 DE JULIO)"
        },
        {
          "lon": -77.1053220457907,
          "lat": -12.0800942323311,
          "address": "AV. LA PAZ - JR. ECHENIQUE"
        },
        {
          "lon": -77.103413280608,
          "lat": -12.0810900407391,
          "address": "AV. LA PAZ - JR. AVIACIÓN"
        },
        {
          "lon": -77.0913809316185,
          "lat": -12.086455079882,
          "address": "AV. LA PAZ - JR. JOSÉ INCLAN"
        },
        {
          "lon": -77.0896022740916,
          "lat": -12.0872654289313,
          "address": "AV. LA PAZ - JR. LADISLAO ESPINAR"
        },
        {
          "lon": -77.1023050262835,
          "lat": -12.0815659249801,
          "address": "AV. LA PAZ - CA. 20"
        },
        {
          "lon": -77.0964850967541,
          "lat": -12.0841612681098,
          "address": "AV. LA PAZ - JR. MARISCAL GAMARRA"
        },
        {
          "lon": -77.0945828870946,
          "lat": -12.0850049516513,
          "address": "AV. LA PAZ - PSJ. JORGE CHÁVEZ"
        },
        {
          "lon": -77.0830487813856,
          "lat": -12.0872787212764,
          "address": "AV. LIMA - JR. INDEPENDENCIA"
        },
        {
          "lon": -77.077381139983,
          "lat": -12.0842175531782,
          "address": "JR. FRANCISCO BOLOGNESI - JR. AYACUCHO"
        },
        {
          "lon": -77.0782221184104,
          "lat": -12.0858252869954,
          "address": "JR. FRANCISCO BOLOGNESI - JR. CUSCO"
        },
        {
          "lon": -77.079841167595,
          "lat": -12.0889305031518,
          "address": "JR. FRANCISCO BOLOGNESI - AV. LIMA"
        },
        {
          "lon": -76.998922667964,
          "lat": -12.1318047972619,
          "address": "Av. Tomas Marsano - Av. Alfredo Franco"
        },
        {
          "lon": -76.9969686147573,
          "lat": -12.134651743379,
          "address": "Av. Tomas Marsano - Av. Ayacucho"
        },
        {
          "lon": -76.9860801422397,
          "lat": -12.1477962379428,
          "address": "Av. Tomas Marsano - Av. Caminos del Inca - Av. Los Proceres"
        },
        {
          "lon": -76.9909929114711,
          "lat": -12.1425692537105,
          "address": "Av. Tomas Marsano - estacion JORGE CHAVEZ"
        },
        {
          "lon": -76.9937739831772,
          "lat": -12.1392528213274,
          "address": "Av. Tomas Marsano - Av. Surco"
        },
        {
          "lon": -76.9884272577175,
          "lat": -12.1456984648995,
          "address": "Av. Tomas Marsano - Jr. El Sol - Ca. Talara"
        },
        {
          "lon": -77.0010766910258,
          "lat": -12.1288553049776,
          "address": "Av. Tomas Marsano - Ovalo Higuereta"
        },
        {
          "lon": -77.0042741466485,
          "lat": -12.1246463072317,
          "address": "Av. Tomas Marsano - Ca. Enrique Caballero Orteaga"
        },
        {
          "lon": -77.0183191983765,
          "lat": -12.116228296071,
          "address": "AV. REPUBLICA DE PANAMA - JIRON GONZALES PRADA"
        },
        {
          "lon": -77.016686399057,
          "lat": -12.1065606009342,
          "address": "Av. Tomas Marsano - Av. Guardia Civil"
        },
        {
          "lon": -77.0146937615745,
          "lat": -12.1089942541331,
          "address": "Av. Tomas Marsano - Ca. Alejandro Peralta - Psj. Flaubert"
        },
        {
          "lon": -77.0133563594825,
          "lat": -12.1107740167272,
          "address": "Av. Tomas Marsano - Ca. Allende - Ca. San Fernando"
        },
        {
          "lon": -77.0178351937795,
          "lat": -12.1053918354529,
          "address": "Av. Tomas Marsano - Ca. Domingo Orue"
        },
        {
          "lon": -77.0099223087909,
          "lat": -12.1156809713822,
          "address": "Av. Tomas Marsano - Ca. Los Angeles"
        },
        {
          "lon": -77.0033657104951,
          "lat": -12.1259635104625,
          "address": "Av. Tomas Marsano - Diaz C. / Av. El Sauce - Ca. Erick G. Diaz Cabre"
        },
        {
          "lon": -77.0185881143342,
          "lat": -12.1045981295025,
          "address": "AV. TOMAS MARSANO - AV. REPUBLICA DE PANAMA"
        },
        {
          "lon": -76.9350012925272,
          "lat": -12.1951943381729,
          "address": "AV. LOS HEROES - AV. EL SOL"
        },
        {
          "lon": -76.9434833428044,
          "lat": -12.1803837361786,
          "address": "AV. LOS HEROES - AV. 26 DE NOVIEMBRE"
        },
        {
          "lon": -76.9422409581325,
          "lat": -12.1826370207894,
          "address": "AV. LOS HEROES - AV. PUMACAHUA"
        },
        {
          "lon": -76.9362233296469,
          "lat": -12.1929060838062,
          "address": "AV. LOS HEROES - AV. 27 DE DICIEMBRE"
        },
        {
          "lon": -76.9303555324598,
          "lat": -12.2027632071918,
          "address": "AV. LOS HEROES - AV. VELASCO ALVARADO"
        },
        {
          "lon": -76.9288491251575,
          "lat": -12.2048808745625,
          "address": "AV. LOS HEROES - CA. 5 (TOTTUS)"
        },
        {
          "lon": -76.9568188521762,
          "lat": -12.1602289937414,
          "address": "AV. LOS HEROES - JR. HUAMACHUCO"
        },
        {
          "lon": -76.9487581200763,
          "lat": -12.0731353660751,
          "address": "AV. LA FONTANA - AV. LOS INGENIEROS"
        },
        {
          "lon": -77.0154502951735,
          "lat": -12.0860722261461,
          "address": "AV. NICOLAS ARRIOLA - AV. SAN EUGENIO - AV. PRINCIPAL"
        },
        {
          "lon": -77.0141111548669,
          "lat": -12.0833626214925,
          "address": "AV. CANADA - AV. NICOLAS ARRIOLA"
        },
        {
          "lon": -77.011946859391,
          "lat": -12.0680950441358,
          "address": "AV. AVIACION - AV. ISABEL LA CATOLICA"
        },
        {
          "lon": -77.0167521188192,
          "lat": -12.0758779730996,
          "address": "AV. PARINACOCHAS - AV. LAS AMERICAS"
        },
        {
          "lon": -77.0262286035262,
          "lat": -12.0824173014106,
          "address": "AV. CANADA - AV. PASEO LA REPUBLICA"
        },
        {
          "lon": -77.0228789002806,
          "lat": -12.062326651429,
          "address": "AV. 28 DE JULIO - AV. ABTAO"
        },
        {
          "lon": -77.0192143249667,
          "lat": -12.0812951446784,
          "address": "AV. CANADA - JR. PARINACOCHAS"
        },
        {
          "lon": -77.0171621773033,
          "lat": -12.0819856342814,
          "address": "AV. CANADA - AV. SANTA CATALINA"
        },
        {
          "lon": -77.023197501876,
          "lat": -12.0817143085488,
          "address": "AV. CANADA - AV. PALERMO"
        },
        {
          "lon": -77.0215817817233,
          "lat": -12.0813802465294,
          "address": "AV. CANADA - AV. CAMPODONICO"
        },
        {
          "lon": -77.0035810926547,
          "lat": -12.0707846733761,
          "address": "AV. SAN LUIS - AV. PABLO PATRÓN"
        },
        {
          "lon": -77.0053166341861,
          "lat": -12.081354686017,
          "address": "AV. AVIACION - AV. DEL AIRE"
        },
        {
          "lon": -77.0184353054449,
          "lat": -12.0847503573875,
          "address": "AV. SANTA CATALINA - AV. SAN EUGENIO"
        },
        {
          "lon": -77.0444326075766,
          "lat": -12.0593281749236,
          "address": "AV. ARICA - JIRON HUARAZ"
        },
        {
          "lon": -77.0458392470589,
          "lat": -12.0589082875827,
          "address": "AV. ARICA - JR. VARELA"
        },
        {
          "lon": -77.0471513203941,
          "lat": -12.0585173642623,
          "address": "AV. ARICA - JIRON JORGE CHAVEZ"
        },
        {
          "lon": -77.0516845471538,
          "lat": -12.057113827899,
          "address": "AV. ARICA - AV. BOLIVIA"
        },
        {
          "lon": -77.0522428812335,
          "lat": -12.0571002467807,
          "address": "AV. ARICA - JR. NAPO"
        },
        {
          "lon": -77.0574628086695,
          "lat": -12.055650979114,
          "address": "AV. ARICA - AV. VENEZUELA"
        },
        {
          "lon": -77.052541395238,
          "lat": -12.055353901954,
          "address": "AV. VENEZUELA - JR. NAPO"
        },
        {
          "lon": -77.0511129018819,
          "lat": -12.0552395198482,
          "address": "AV. VENEZUELA - JR. AGUARICO"
        },
        {
          "lon": -77.0476495808924,
          "lat": -12.0549780200369,
          "address": "AV. VENEZUELA - JR. JORGE CHAVEZ"
        },
        {
          "lon": -77.0450968873687,
          "lat": -12.0547667055346,
          "address": "AV. VENEZUELA - JR. HUARAZ"
        },
        {
          "lon": -77.0477908512925,
          "lat": -12.0567164157042,
          "address": "AV. BOLIVIA - JR. JORGE CHAVEZ"
        },
        {
          "lon": -77.0451419051451,
          "lat": -12.0565600222321,
          "address": "AV. BOLIVIA - JR. HUARAZ"
        },
        {
          "lon": -77.0553389153708,
          "lat": -12.0525893868504,
          "address": "AV. TINGO MARIA - JR. AIJA"
        },
        {
          "lon": -76.9533823898375,
          "lat": -12.0500643851076,
          "address": "CARRETERA CENTRAL CON AV. ATE"
        },
        {
          "lon": -76.9266807146418,
          "lat": -12.032034489804,
          "address": "AV. JAVIER PRADO - CARRETERA CENTRAL"
        },
        {
          "lon": -76.9303645077621,
          "lat": -12.043242506563,
          "address": "AV. JAVIER PRADO - AV. VISTA ALEGRE"
        },
        {
          "lon": -76.9244234447711,
          "lat": -12.0383825937369,
          "address": "AV. JAVIER PRADO - CALLE BERLIN"
        },
        {
          "lon": -76.9360725823389,
          "lat": -12.0469175615832,
          "address": "AV. JAVIER PRADO - CALLE BUCAREST"
        },
        {
          "lon": -76.9261083360191,
          "lat": -12.0422086557731,
          "address": "AV.JAVIER PRADO - CALLE HOLANDA"
        },
        {
          "lon": -76.9826759176243,
          "lat": -12.0748033646976,
          "address": "AV. LOS QUECHUAS - CA. TERPSICORE"
        },
        {
          "lon": -76.9275690682709,
          "lat": -12.0301009496678,
          "address": "AV. METROPOLITANA - AV. PROLOG. JAVIER PRADO"
        },
        {
          "lon": -76.9314675694667,
          "lat": -12.0378921169298,
          "address": "CARRETERA CENTRAL - AV. VISTA ALEGRE"
        },
        {
          "lon": -76.9871667007227,
          "lat": -12.0641295427098,
          "address": "CARRETERA CENTRAL - AV. LAS TORRES"
        },
        {
          "lon": -76.9239362559896,
          "lat": -12.0288827463659,
          "address": "CARRETERA CENTRAL - AV. MARCO PUENTE LLANOS"
        },
        {
          "lon": -76.935046437727,
          "lat": -12.0420392977579,
          "address": "CARRETERA CENTRAL - CA. LONDRES (PURUCHUCO) - CALLE 12"
        },
        {
          "lon": -76.9305021756709,
          "lat": -12.0362602957506,
          "address": "CARRETERA CENTRAL - CA. TITAN / DALIAS"
        },
        {
          "lon": -76.937797372749,
          "lat": -12.0438260017036,
          "address": "CARRETERA CENTRAL - SEPARADORA INDUSTRIAL"
        },
        {
          "lon": -76.9553330082737,
          "lat": -12.0542375361368,
          "address": "AV. URUBAMBA - JR. 9 DE SETIEMBRE"
        },
        {
          "lon": -76.9648484932017,
          "lat": -12.064831257801,
          "address": "AV. SEPARADORA INDUSTRIAL - AV. LOS FRUTALES"
        },
        {
          "lon": -77.019531481559,
          "lat": -12.1546834576953,
          "address": "ANTIGUA PANAMERICANA SUR - JR. INDEPENDENCIA"
        },
        {
          "lon": -77.0195418054297,
          "lat": -12.1304864757394,
          "address": "AV. 28 DE JULIO - AV. RAMON RIBEYRO"
        },
        {
          "lon": -76.936340281239,
          "lat": -12.0433445725,
          "address": "CARRETERA CENTRAL - AV. MONACO"
        },
        {
          "lon": -77.020425019795,
          "lat": -12.1504704178399,
          "address": "AV. GRAU - JR. 28 DE JULIO"
        },
        {
          "lon": -77.0155248777437,
          "lat": -12.1437963577234,
          "address": "AV. JORGE CHAVEZ - JR. LIMA"
        },
        {
          "lon": -77.0161390039351,
          "lat": -12.1432257701886,
          "address": "AV. LUNA PIZARRO - AV. BALTA"
        },
        {
          "lon": -77.0193327914716,
          "lat": -12.1538304615578,
          "address": "AV. MANUEL DE LA FUENTE CHAVEZ - AV. LAS PALMAS"
        },
        {
          "lon": -77.0224303836106,
          "lat": -12.1378522726562,
          "address": "AV. MIGUEL GRAU - JR. ARRIETA"
        },
        {
          "lon": -77.0217317773553,
          "lat": -12.1458466240344,
          "address": "AV. MIGUEL GRAU - JR. COLINA - JR. MERINO"
        },
        {
          "lon": -77.022292392324,
          "lat": -12.1395312855857,
          "address": "AV. MIGUEL GRAU - AV. SOL"
        },
        {
          "lon": -77.0221420864388,
          "lat": -12.1411652301221,
          "address": "AV. MIGUEL GRAU - AV. NICOLAS DE PIEROLA"
        },
        {
          "lon": -77.0221027815747,
          "lat": -12.1511365657005,
          "address": "AV. PEDRO DE OSMA - JR. 28 DE JULIO"
        },
        {
          "lon": -77.022682006804,
          "lat": -12.1527339539747,
          "address": "AV. PEDRO DE OSMA - JR. P. SOLARI"
        },
        {
          "lon": -77.0232998309566,
          "lat": -12.1412771250834,
          "address": "AV. SAN MARTIN - JR. CENTENARIO"
        },
        {
          "lon": -77.0228478514966,
          "lat": -12.1461489381774,
          "address": "AV. SAN MARTIN - JR. COLINA"
        },
        {
          "lon": -77.0216562524824,
          "lat": -12.1492420074898,
          "address": "AV. SAN MARTIN - PQ. MUNICIPAL"
        },
        {
          "lon": -77.0187693006912,
          "lat": -12.1492849359804,
          "address": "JR. LIMA - JR. PROLONGACION PAZOS"
        },
        {
          "lon": -77.0535308661702,
          "lat": -12.0654872222446,
          "address": "JR. AGUARICO (JR. LORETO) - JR. CASTROVIRREYNA"
        },
        {
          "lon": -77.0532122104856,
          "lat": -12.056797898754,
          "address": "AV. ARICA - JR. PASTAZA"
        },
        {
          "lon": -77.0576633625469,
          "lat": -12.0558198214072,
          "address": "AV. VENEZUELA - AV. TINGO MARIA"
        },
        {
          "lon": -77.0389729018659,
          "lat": -11.8925003196124,
          "address": "AV. CHIMPU OCLLO - AV. CONDORCANQUI - AV. A"
        },
        {
          "lon": -77.066670538196,
          "lat": -12.0621528901156,
          "address": "AV. ALEJANDRO BERTELLO - AV. ALBORADA"
        },
        {
          "lon": -77.0426033372844,
          "lat": -12.0482731889638,
          "address": "AV. ALFONSO UGARTE - JR. SANCHEZ PINILLOS - JR. ZEPITA"
        },
        {
          "lon": -77.0394350987945,
          "lat": -12.0644261608723,
          "address": "AV. 28 DE JULIO - AV. GUZMAN BLANCO"
        },
        {
          "lon": -77.0616545326068,
          "lat": -12.0541352749812,
          "address": "AV. ARICA - AV. NACIONES UNIDAS"
        },
        {
          "lon": -77.0404720454659,
          "lat": -12.0561106873506,
          "address": "AV. BOLIVIA - JR. CHOTA"
        },
        {
          "lon": -77.0486564585185,
          "lat": -12.0407096464075,
          "address": "AV. ENRIQUE MEIGGS - JR. ACOMAYO"
        },
        {
          "lon": -77.0461447697479,
          "lat": -12.0404973111765,
          "address": "AV. ENRIQUE MEIGGS - JR. ASCOPE"
        },
        {
          "lon": -77.0473718366833,
          "lat": -12.0406240012292,
          "address": "AV. ENRIQUE MEIGGS - JR. PACASMAYO"
        },
        {
          "lon": -77.0513069932615,
          "lat": -12.0404907201764,
          "address": "AV. ENRIQUE MEIGGS - JR. RAMON CARCAMO"
        },
        {
          "lon": -77.0774053906567,
          "lat": -12.0386053325921,
          "address": "AV. ENRIQUE MEIGGS - AV. UNIVERSITARIA"
        },
        {
          "lon": -77.0131055770083,
          "lat": -12.0544817372204,
          "address": "AV. GRAU - AV. SEBASTIAN LORENTE"
        },
        {
          "lon": -77.0408268296822,
          "lat": -12.0615809652096,
          "address": "AV. GUZMAN BLANCO - JR. TARMA"
        },
        {
          "lon": -77.0401157380347,
          "lat": -12.0628860671521,
          "address": "AV. GUZMAN BLANCO - JR. CHINCHA"
        },
        {
          "lon": -77.0511825272651,
          "lat": -12.0373838577859,
          "address": "AV. MORALES DUAREZ - JR. RAMON CARCAMO"
        },
        {
          "lon": -77.0499972472956,
          "lat": -12.0375765291661,
          "address": "AV. MORALES DUAREZ - JR. MANCORA"
        },
        {
          "lon": -77.0614755943533,
          "lat": -12.0565278843531,
          "address": "AV. NACIONES UNIDAS - AV. VENEZUELA"
        },
        {
          "lon": -77.0781913961364,
          "lat": -12.0500775180027,
          "address": "AV. OSCAR R. BENAVIDES - AV. UNIVERSITARIA"
        },
        {
          "lon": -77.0693110613388,
          "lat": -12.054590156687,
          "address": "AV. REYNALDO SAAVEDRA - AV. ROBERTO THORNDIKE"
        },
        {
          "lon": -77.0128141791106,
          "lat": -12.0494719226276,
          "address": "AV. SEBASTIAN LORENTE - JR. DESAGUADERO"
        },
        {
          "lon": -77.0537435044585,
          "lat": -12.0490850862972,
          "address": "AV. TINGO MARIA - JR. ZORRITOS"
        },
        {
          "lon": -77.0547653048277,
          "lat": -12.0513677408291,
          "address": "AV. TINGO MARIA - JR. PARIACOTO"
        },
        {
          "lon": -77.0546155178208,
          "lat": -12.0508580049528,
          "address": "AV. TINGO MARIA - ENTRADA AL M.T.C."
        },
        {
          "lon": -77.0810681626002,
          "lat": -12.0559982223686,
          "address": "AV. UNIVERSITARIA - AV. AMEZAGA"
        },
        {
          "lon": -77.0767349315962,
          "lat": -12.046545777169,
          "address": "AV. UNIVERSITARIA - AV. ARGENTINA"
        },
        {
          "lon": -77.077116139047,
          "lat": -12.0417516679736,
          "address": "AV. UNIVERSITARIA - AV. MATERIALES"
        },
        {
          "lon": -77.0777028836131,
          "lat": -12.0359180229717,
          "address": "AV. UNIVERSITARIA - AV. MORALES DUAREZ"
        },
        {
          "lon": -77.0791758825451,
          "lat": -12.0602433519012,
          "address": "AV. UNIVERSITARIA - AV. VENEZUELA"
        },
        {
          "lon": -77.0793626117924,
          "lat": -12.0536565843424,
          "address": "AV. UNIVERSITARIA - CA. M. ARREDONDO"
        },
        {
          "lon": -77.0406403868069,
          "lat": -12.0543932905365,
          "address": "AV. URUGUAY - JR. CHOTA"
        },
        {
          "lon": -77.0252961548154,
          "lat": -12.0499766738904,
          "address": "JR HUALLAGA - PROLOG. ANDAHUAYLAS"
        },
        {
          "lon": -77.0202280139918,
          "lat": -12.0479093661133,
          "address": "JR JAUJA - JR. ANCASH"
        },
        {
          "lon": -77.055780120281,
          "lat": -12.0488504794392,
          "address": "JR ZORRITOS - PSJE. ECUADOR - ENTRADA AL M.T.C"
        },
        {
          "lon": -77.0209855430337,
          "lat": -12.0448064126525,
          "address": "JR. AMAZONAS - JR. HUANTA"
        },
        {
          "lon": -77.0214283567393,
          "lat": -12.044756379724,
          "address": "JR. AMAZONAS - JR. PARURO"
        },
        {
          "lon": -77.0195703341744,
          "lat": -12.0452598106845,
          "address": "JR. AMAZONAS - JR. PARURO"
        },
        {
          "lon": -77.0123562136872,
          "lat": -12.0451050666797,
          "address": "JR. ANCASH - AV. SEBASTIAN LORENTE"
        },
        {
          "lon": -77.017475188448,
          "lat": -12.0469826927527,
          "address": "JR. ANCASH - JR. MAYNAS"
        },
        {
          "lon": -77.0263246637235,
          "lat": -12.0493289415941,
          "address": "JR. HUALLAGA - JR. AYACUCHO"
        },
        {
          "lon": -77.0217574996234,
          "lat": -12.0476760455796,
          "address": "JR. HUANTA - JR. ANCASH"
        },
        {
          "lon": -77.0227649699489,
          "lat": -12.0514740864305,
          "address": "JR. HUANTA - JR. ANCASH"
        },
        {
          "lon": -77.0198112714374,
          "lat": -12.04774928955,
          "address": "JR. HUANUCO - JR. ANCASH"
        },
        {
          "lon": -77.0179471677705,
          "lat": -12.05595388253,
          "address": "JR. HUANUCO - JR. PUNO"
        },
        {
          "lon": -77.0193915416501,
          "lat": -12.0511302924051,
          "address": "JR. HUANUCO - JR. JUNIN"
        },
        {
          "lon": -77.0467016961035,
          "lat": -12.0478147180136,
          "address": "JR. JORGE CHAVEZ - JR. CORNELIO BORDA"
        },
        {
          "lon": -77.0473351660252,
          "lat": -12.050317064516,
          "address": "JR. JORGE CHAVEZ - JR. ZORRITOS"
        },
        {
          "lon": -77.0224761613444,
          "lat": -12.0503659663924,
          "address": "JR. JUNIN - JR. HUANTA"
        },
        {
          "lon": -77.0129758322656,
          "lat": -12.0521811446127,
          "address": "JR. MIROQUESADA - AV. SEBASTIAN LORENTE"
        },
        {
          "lon": -77.0141008360441,
          "lat": -12.0523488812744,
          "address": "JR. MIROQUESADA - JR. JUNIN - JR. WUARI"
        },
        {
          "lon": -77.0213294277168,
          "lat": -12.053044120864,
          "address": "JR. MIROQUESADA - JR. CANGALLO"
        },
        {
          "lon": -77.0230398870869,
          "lat": -12.0527799363652,
          "address": "JR. MIROQUESADA - JR. HUANTA"
        },
        {
          "lon": -77.0196661693933,
          "lat": -12.0531856549667,
          "address": "JR. MIROQUESADA - JR. HUANUCO"
        },
        {
          "lon": -77.0220621559702,
          "lat": -12.0554263264244,
          "address": "JR. PUNO - JR. CANGALLO"
        },
        {
          "lon": -77.0236011333993,
          "lat": -12.0551204778836,
          "address": "JR. PUNO - JR. HUANTA"
        },
        {
          "lon": -77.0196426783069,
          "lat": -12.0556962088145,
          "address": "JR. PUNO - JR. LUACANAS"
        },
        {
          "lon": -77.051305598621,
          "lat": -12.0383421521413,
          "address": "AV. RAMON CARCAMO - JR. CONDESUYOS"
        },
        {
          "lon": -77.0257786771981,
          "lat": -12.0509950580204,
          "address": "JR. UCAYALI - PLOG. ANDAHUAYLAS"
        },
        {
          "lon": -77.0388184555158,
          "lat": -12.0644096390434,
          "address": "JR. WASHINGTON - AV. 28 DE JULIO"
        },
        {
          "lon": -77.0398727417461,
          "lat": -12.0509963522537,
          "address": "JR. WASHINGTON - JR. QUILCA"
        },
        {
          "lon": -77.0396060577318,
          "lat": -12.0542951846115,
          "address": "JR. WASHINGTON - AV. URUGUAY"
        },
        {
          "lon": -77.0394058401162,
          "lat": -12.0560266688795,
          "address": "JR. WASHINGTON - AV. BOLIVIA"
        },
        {
          "lon": -77.0390608187707,
          "lat": -12.0600386824419,
          "address": "JR. WASHINGTON - AV. 9 DE DICIEMBRE"
        },
        {
          "lon": -77.0419631013339,
          "lat": -12.0607274261872,
          "address": "PLAZA BOLOGNESI - AV BRASIL"
        },
        {
          "lon": -77.0420494133999,
          "lat": -12.0600999820103,
          "address": "PLAZA BOLOGNESI - AV. ARICA"
        },
        {
          "lon": -77.0668510037362,
          "lat": -12.0522866385839,
          "address": "PROLONGACION ARICA - AV. LUIS BRAILE"
        },
        {
          "lon": -77.0125919180896,
          "lat": -12.1990902726337,
          "address": "ALAMEDA SUR - ALAMEDA SAN LORENZO"
        },
        {
          "lon": -77.0081062939856,
          "lat": -12.1927844424229,
          "address": "ALAMEDA SUR - CA. MACCHU PICCHU"
        },
        {
          "lon": -77.0068168545511,
          "lat": -12.1764607162985,
          "address": "AV. A. MATELLINI - AV. GUARDIA PERUANA"
        },
        {
          "lon": -77.022889029373,
          "lat": -12.1561971792233,
          "address": "AV. CHORRILLOS - JR. INDEPENDENCIA (P. SOLARI)"
        },
        {
          "lon": -77.0231047415781,
          "lat": -12.1579900496685,
          "address": "AV. CHORRILLOS - CA. GASTAÑETA - CA. ELENA F. DE PASTOR"
        },
        {
          "lon": -77.0258625295839,
          "lat": -12.1673236665696,
          "address": "AV. DEFENSORES DEL MORRO - AV. MARTIR JOSE OLAYA"
        },
        {
          "lon": -77.0253029723909,
          "lat": -12.1678925568896,
          "address": "AV. DEFENSORES DEL MORRO - AV. ALEJANDRO IGLESIAS"
        },
        {
          "lon": -77.0215394635344,
          "lat": -12.1717953037322,
          "address": "AV. DEFENSORES DEL MORRO - JR. NUEVA YORK"
        },
        {
          "lon": -77.0235490375959,
          "lat": -12.1696714525694,
          "address": "AV. DEFENSORES DEL MORRO - CA. HONDURAS - CA. URUGUAY"
        },
        {
          "lon": -77.0186673404068,
          "lat": -12.1748199817519,
          "address": "AV. DEFENSORES DEL MORRO - JR. COMUNIDAD INDUSTRIAL"
        },
        {
          "lon": -77.0176559970657,
          "lat": -12.1758294517578,
          "address": "AV. DEFENSORES DEL MORRO - CA. COSTA AZUL"
        },
        {
          "lon": -77.0140269520299,
          "lat": -12.1796200562055,
          "address": "AV. DEFENSORES DEL MORRO - CA. SILVA"
        },
        {
          "lon": -77.0123721560035,
          "lat": -12.1812762612966,
          "address": "AV. DEFENSORES DEL MORRO - AV. MATELINI"
        },
        {
          "lon": -76.9993703023637,
          "lat": -12.1973677835564,
          "address": "AV. DEFENSORES DEL MORRO - AV. ALAMEDA LOS HORIZONTES"
        },
        {
          "lon": -77.0245716685767,
          "lat": -12.1686332627552,
          "address": "AV. DEFENSORES DEL MORRO - CA. NICANOR RIVAS - JR. SANDOVAL"
        },
        {
          "lon": -77.0215954769259,
          "lat": -12.1643750154224,
          "address": "AV. ESPLANADA - AV. ALEJANDRO IGLESIAS"
        },
        {
          "lon": -76.9928852472748,
          "lat": -12.1741627394395,
          "address": "AV. GUARDIA CIVIL - AV. ALIPIO PONCE"
        },
        {
          "lon": -76.9996149537476,
          "lat": -12.1827187868566,
          "address": "AV. GUARDIA CIVIL - AV. GUARDIA PERUANA"
        },
        {
          "lon": -77.0002136969382,
          "lat": -12.1833656343288,
          "address": "AV. GUARDIA CIVIL - PSJ. SANTA ROSA (JR. TOLOMEO)"
        },
        {
          "lon": -76.9962816060233,
          "lat": -12.1782379680677,
          "address": "AV. GUARDIA CIVIL -AV. EL SOL"
        },
        {
          "lon": -77.0248041231369,
          "lat": -12.1663328867005,
          "address": "AV. MARTIR JOSE OLAYA - BA. NUEVO (JR. BELLAVISTA)"
        },
        {
          "lon": -76.9985277801113,
          "lat": -12.176447062114,
          "address": "AV. LOS FAISANES - AV. EL SOL"
        },
        {
          "lon": -77.0142184074239,
          "lat": -12.1893534254508,
          "address": "AV. SANTA ANITA - ALAMEDA LOS PINOS"
        },
        {
          "lon": -77.0559547215641,
          "lat": -11.950787869514,
          "address": "AV. CARABAYLLO - AV. 22 DE AGOSTO"
        },
        {
          "lon": -77.0576589623334,
          "lat": -11.9559236997621,
          "address": "AV. CARABAYLLO - AV. MEXICO"
        },
        {
          "lon": -77.0666072683983,
          "lat": -11.944780665354,
          "address": "AV. METROPOLITANA - AV. 25 DE ENERO"
        },
        {
          "lon": -77.0618176570437,
          "lat": -11.9648462561583,
          "address": "AV. METROPOLITANA - AV. EL MAESTRO"
        },
        {
          "lon": -77.0667702633716,
          "lat": -11.9421325547971,
          "address": "AV. METROPOLITANA - AV. GUILLERMO DE LA FUENTE"
        },
        {
          "lon": -77.0695219670935,
          "lat": -11.9301339510172,
          "address": "AV. TRAPICHE - AV. GERARDO UNGER"
        },
        {
          "lon": -77.0533740294879,
          "lat": -11.905701076852,
          "address": "AV. TRAPICHE - CA. LA HACIENDA"
        },
        {
          "lon": -77.0514872298495,
          "lat": -11.9528996274131,
          "address": "AV. TUPAC AMARU - JR. PUNO"
        },
        {
          "lon": -77.0521278730885,
          "lat": -11.9258398302614,
          "address": "AV. UNIVERSITARIA - AV. JAMAICA"
        },
        {
          "lon": -77.0536057615658,
          "lat": -11.9299948555394,
          "address": "AV. UNIVERSITARIA - AV. MICAELA BASTIDAS"
        },
        {
          "lon": -77.0606419795675,
          "lat": -11.9460586228738,
          "address": "AV. UNIVERSITARIA - AV. 22 DE AGOSTO"
        },
        {
          "lon": -77.0603074782376,
          "lat": -11.9423354038868,
          "address": "AV. UNIVERSITARIA - AV. GUILLERMO DE LA FUENTE"
        },
        {
          "lon": -77.0478607635571,
          "lat": -11.9151510689595,
          "address": "AV. UNIVERSITARIA - AV. LOS INCAS"
        },
        {
          "lon": -77.0626343971596,
          "lat": -11.961477303987,
          "address": "AV. UNIVERSITARIA - AV. METROPOLITANA"
        },
        {
          "lon": -77.0599412482734,
          "lat": -11.9553820859795,
          "address": "AV. UNIVERSITARIA - AV. MEXICO"
        },
        {
          "lon": -77.0399138418659,
          "lat": -11.9009709662033,
          "address": "AV. UNIVERSITARIA  - AV. SAN FELIPE"
        },
        {
          "lon": -77.0563225968127,
          "lat": -11.9348723794445,
          "address": "AV. UNIVERSITARIA - AV. VICTOR ANDRES BELAUNDE"
        },
        {
          "lon": -77.0723837613213,
          "lat": -11.930580669287,
          "address": "PANAMERICANA NORTE - AV. HEROES DEL CENEPA (AV. TRAPICHE)"
        },
        {
          "lon": -76.9977927746233,
          "lat": -12.0405727026804,
          "address": "AV. ANCASH - CALLE 3"
        },
        {
          "lon": -76.9908213253821,
          "lat": -12.0397145406331,
          "address": "AV. ATARGEA - CA. LOS CEREZOS - CA. RIO CHEPEN"
        },
        {
          "lon": -77.0047429747368,
          "lat": -12.0417458510308,
          "address": "AV. CEMENTERIO - AV. PLACIDO JIMENEZ - JR. ANCASH"
        },
        {
          "lon": -77.0334200254278,
          "lat": -12.0270850480196,
          "address": "AV. ALCAZAR - AV. FELIPE ARANCIBIA"
        },
        {
          "lon": -77.0297043014976,
          "lat": -12.0302758862629,
          "address": "AV. ALCAZAR - AV. ANTON SANCHEZ"
        },
        {
          "lon": -77.034103528477,
          "lat": -12.0262421374526,
          "address": "AV. ALCAZAR - AV. FLOR DE AMANCAES"
        },
        {
          "lon": -77.0313165805086,
          "lat": -12.0287953348247,
          "address": "AV. ALCAZAR - CA. LAS CALEZAS"
        },
        {
          "lon": -77.0296219461983,
          "lat": -12.0211742948288,
          "address": "AV. FLOR DE AMANCAES - AV. EL SOL"
        },
        {
          "lon": -77.0155560396049,
          "lat": -12.0258446700153,
          "address": "AV. LIMA - CA. MOQUEGUA"
        },
        {
          "lon": -77.0358484945277,
          "lat": -12.0300867077801,
          "address": "AV. G. REPUBLICANA - AV. FELIPE ARANCIBIA"
        },
        {
          "lon": -77.0277601800169,
          "lat": -12.031955931876,
          "address": "AV. PROLONGACION TACNA - AV. ALCAZAR"
        },
        {
          "lon": -77.022601055668,
          "lat": -12.0419043703597,
          "address": "JR. MARAÑON - JR. CASTANETA"
        },
        {
          "lon": -77.0400730064247,
          "lat": -12.026856075519,
          "address": "JR. VILLACAMPA - JR. MORRO DE ARICA"
        },
        {
          "lon": -77.0597065960635,
          "lat": -11.9894171656918,
          "address": "AV. CARLOS IZAGUIRRE - AV. INDUSTRIAL"
        },
        {
          "lon": -77.0596385291537,
          "lat": -11.9767102936898,
          "address": "AV. TUPAC AMARU - AV. NARANJAL"
        },
        {
          "lon": -76.9509767715462,
          "lat": -12.0631160628939,
          "address": "AV. CONSTRUCTORES - AV. LOS INGENIEROS"
        },
        {
          "lon": -76.9735925686355,
          "lat": -12.0730825403588,
          "address": "AV. EVITAMIENTO - AV. LAS PALMERAS"
        },
        {
          "lon": -76.9485398198777,
          "lat": -12.0688258307667,
          "address": "AV. JAVIER PRADO - AV. INGENIEROS"
        },
        {
          "lon": -76.9566198240738,
          "lat": -12.0715334303876,
          "address": "AV. JAVIER PRADO - AV. LA MOLINA"
        },
        {
          "lon": -76.9659636179041,
          "lat": -12.0794973184645,
          "address": "AV. JAVIER PRADO - CA. LOS CEIBOS"
        },
        {
          "lon": -76.9439435067006,
          "lat": -12.0667534097414,
          "address": "AV. JAVIER PRADO - AV. FLORA TRISTAN"
        },
        {
          "lon": -76.9636827478164,
          "lat": -12.0756038589516,
          "address": "AV. JAVIER PRADO - AV. LOS FRUTALES"
        },
        {
          "lon": -76.9581448955653,
          "lat": -12.0760391182147,
          "address": "AV. LA FONTANA - AV. GOLF"
        },
        {
          "lon": -76.9128194900833,
          "lat": -12.0859832583341,
          "address": "AV. LA MOLINA - AV. EL SOL"
        },
        {
          "lon": -76.9200482145924,
          "lat": -12.0851976322577,
          "address": "AV. LA MOLINA - RINCONADA DEL LAGO"
        },
        {
          "lon": -76.9354876729923,
          "lat": -12.0756164124535,
          "address": "AV. LA MOLINA - AV. MELGAREJO"
        },
        {
          "lon": -76.928207978097,
          "lat": -12.0819812711943,
          "address": "AV. LA MOLINA - CALLE 7"
        },
        {
          "lon": -76.9274474962692,
          "lat": -12.0839838922834,
          "address": "AV. LA MOLINA - CALLE 11"
        },
        {
          "lon": -76.9001899182285,
          "lat": -12.0860406612265,
          "address": "AV. LA MOLINA - CA. LA PUNTA"
        },
        {
          "lon": -76.9584452810432,
          "lat": -12.0678074075429,
          "address": "AV. LA MOLINA - AV. CONSTRUCTORES"
        },
        {
          "lon": -76.9574701356222,
          "lat": -12.070205657808,
          "address": "AV. LA MOLINA - CA. LAS ZARZAMORAS"
        },
        {
          "lon": -76.9540499508925,
          "lat": -12.0769587804523,
          "address": "AV. LA MOLINA - AV. ALAMEDA DEL CORREGIDOR"
        },
        {
          "lon": -76.9352896617015,
          "lat": -12.0823305039358,
          "address": "AV. RAUL FERRERO - AV. MANUEL PARDO"
        },
        {
          "lon": -76.9479426910189,
          "lat": -12.0893412144371,
          "address": "AV. RAUL FERRERO - AV. LOS FRESNOS"
        },
        {
          "lon": -76.9531444556172,
          "lat": -12.0917277796512,
          "address": "AV. RAUL FERRERO - AV. ALAMEDA DEL CORREGIDOR"
        },
        {
          "lon": -76.9248836722405,
          "lat": -12.0805074787144,
          "address": "JR. BELLO HORIZONTE - AV. RICARDO ELIAS APARICIO"
        },
        {
          "lon": -77.0068691040105,
          "lat": -12.0423741710648,
          "address": "AV. CEMENTERIO - ENTRADA CEMENTERIO EL ANGEL"
        },
        {
          "lon": -76.988645365391,
          "lat": -12.0397114265476,
          "address": "AV. CESAR VALLEJO - AV. 1RO DE MAYO"
        },
        {
          "lon": -77.0057188072004,
          "lat": -12.0379957739068,
          "address": "AV. FERROCARRIL - AV. PLACIDO JIMENEZ"
        },
        {
          "lon": -76.9977034402724,
          "lat": -12.0349803361562,
          "address": "AV. FERROCARRIL - AV. JOSE CARLOS MARIATEGUI"
        },
        {
          "lon": -77.0105864012395,
          "lat": -12.0516003774925,
          "address": "AV. GRAU - AV. TUPAC AMARU"
        },
        {
          "lon": -76.9994108379062,
          "lat": -12.0309538985687,
          "address": "AV. JOSE CARLOS MARIATEGUI - AV. ESMERALDA"
        },
        {
          "lon": -76.9994389664043,
          "lat": -12.0466266392252,
          "address": "AV. JOSE DE LA RIVA AGUERO - AV. ELIAS OLIVERA"
        },
        {
          "lon": -77.0014846863333,
          "lat": -12.049404781459,
          "address": "AV. JOSE DE LA RIVA AGUERO - CA. HOYLE PALACIOS"
        },
        {
          "lon": -77.0007686731926,
          "lat": -12.0484112229198,
          "address": "AV. JOSE DE LA RIVA AGUERO - CA. LOURDES"
        },
        {
          "lon": -76.9998182854193,
          "lat": -12.0301624026601,
          "address": "AV. JOSE DE LA RIVA AGUERO - EVITAMIENTO (PUENTE NUEVO) - AV. JOSE CARLOS MARIATEGUI"
        },
        {
          "lon": -76.9975959125365,
          "lat": -12.0401601545695,
          "address": "AV. JOSE DE LA RIVA AGUERO - JR. ANCASH - AV. LA ATARJEA - OVALO LA PAZ"
        },
        {
          "lon": -76.999700825881,
          "lat": -12.0468167144993,
          "address": "AV. JOSE DE LA RIVA AGUERO - JR. LOS CLAVELES"
        },
        {
          "lon": -77.0053011276502,
          "lat": -12.0563264251917,
          "address": "AV. JOSE DE LA RIVA AGUERO - JR. M. CACERES"
        },
        {
          "lon": -77.0027147100283,
          "lat": -12.0512090635152,
          "address": "AV. JOSE DE LA RIVA AGUERO - 09 DE OCTUBRE"
        },
        {
          "lon": -77.0036956619801,
          "lat": -12.052804338474,
          "address": "AV. JOSE DE LA RIVA AGUERO - AV. TUPAC AMARU - JR. GARCIA SALCEDO"
        },
        {
          "lon": -76.9993852642288,
          "lat": -12.0463803785116,
          "address": "AV. JOSE DE LA RIVA AGUERO - PSJE. 94"
        },
        {
          "lon": -77.0073483021156,
          "lat": -12.0586729449157,
          "address": "AV. JOSE DE LA RIVA AGUERO - AV. JUNIN"
        },
        {
          "lon": -77.0123282291769,
          "lat": -12.0536556460897,
          "address": "AV. MIGUEL GRAU - JR. JUNIN"
        },
        {
          "lon": -77.0130624205417,
          "lat": -12.0531102098375,
          "address": "AV. SEBASTIAN LORENTE - AV. JUNIN"
        },
        {
          "lon": -77.0068578979346,
          "lat": -12.0522010792235,
          "address": "AV. TUPAC AMARU - JR. CHIQUIAN"
        },
        {
          "lon": -77.0060865810176,
          "lat": -12.0505712073363,
          "address": "JR. CHIQUIAN - JR. CAJACAY - JR. INCA RIPAC"
        },
        {
          "lon": -77.0004517378868,
          "lat": -12.0452615879392,
          "address": "JR. CHIQUIAN - JR. LOS CLAVELES"
        },
        {
          "lon": -77.0085761785453,
          "lat": -12.0430038836726,
          "address": "JR. MANUEL ODRIA - JR. ANCASH"
        },
        {
          "lon": -76.9986431231349,
          "lat": -12.0328112729672,
          "address": "AV. JOSE CARLOS MARIATEGUI - LAS MAGNOLIAS"
        },
        {
          "lon": -77.054797586421,
          "lat": -12.0866438175088,
          "address": "AV. GREGORIO ESCOBEDO - IPSS - JR. DIEGO DE ALMAGRO"
        },
        {
          "lon": -77.0516362492635,
          "lat": -12.0841101084899,
          "address": "AV. GREGORIO ESCOBEDO - JR. HUIRACOCHA"
        },
        {
          "lon": -77.0572038789459,
          "lat": -12.0892793072665,
          "address": "AV. GREGORIO ESCOBEDO - JIRON SANTO DOMINGO"
        },
        {
          "lon": -77.0423717126233,
          "lat": -12.0795339642098,
          "address": "AV. SALAVERRY - AV. EDGARDO REBAGLIATI"
        },
        {
          "lon": -77.0456058216597,
          "lat": -12.0825000128078,
          "address": "AV. SALAVERRY - AV. SAN FELIPE"
        },
        {
          "lon": -77.0505741625237,
          "lat": -12.082513157105,
          "address": "AV. SAN FELIPE - AV. VALDIZAN - JIRON HUAZCAR"
        },
        {
          "lon": -77.0521480794758,
          "lat": -12.0831255998535,
          "address": "AV. SAN FELIPE - JR. HUIRACOCHA"
        },
        {
          "lon": -77.0316924127342,
          "lat": -12.0820491360793,
          "address": "AV. MILITAR - JR. MANUEL CANDAMO"
        },
        {
          "lon": -77.0462740655231,
          "lat": -12.0851727361149,
          "address": "AV. CÉSAR CANEVARO - JR. MANCO SEGUNDO"
        },
        {
          "lon": -77.0398507308667,
          "lat": -12.0842735257458,
          "address": "AV. FRANSICO DE ZELA - AV. CESAR CANEVARO"
        },
        {
          "lon": -77.0293669710776,
          "lat": -12.0828040405372,
          "address": "AV. JOSÉ PARDO DE ZELA - JR. JOSÉ GALVEZ"
        },
        {
          "lon": -77.0326391295909,
          "lat": -12.0832890494865,
          "address": "AV. JOSÉ PARDO DE ZELA - AV. IGNACIO MERINO"
        },
        {
          "lon": -77.0315417434057,
          "lat": -12.0831251080708,
          "address": "AV. MILITAR - AV. JOSÉ PARDO DE ZELA"
        },
        {
          "lon": -77.0419526791895,
          "lat": -12.0828774112545,
          "address": "JR. BELISARIO FLORES - JR. CAPAC YUPANQUI"
        },
        {
          "lon": -77.0417814694717,
          "lat": -12.0806128215198,
          "address": "JR. DOMINGO CUETO - JR. CAPAC YUPANQUI"
        },
        {
          "lon": -77.0285005788295,
          "lat": -12.0805337964923,
          "address": "PROL. IQUITOS - JR. BARTOLOME HERRERA"
        },
        {
          "lon": -77.0290870651428,
          "lat": -12.0883619507534,
          "address": "PROL. IQUITOS - CA. TULIPANES"
        },
        {
          "lon": -77.0286139672968,
          "lat": -12.0798784803075,
          "address": "PROL. IQUITOS - AV. EMILIO ALTHAUS"
        },
        {
          "lon": -77.0338638624443,
          "lat": -12.089124848992,
          "address": "AV. AREQUIPA - JR. JOSE DE LA TORRE UGARTE"
        },
        {
          "lon": -77.0282254020407,
          "lat": -12.0826555582099,
          "address": "AV. JOSÉ PARDO DE ZELA - AV. IQUITOS"
        },
        {
          "lon": -77.0280688514772,
          "lat": -12.0837451715602,
          "address": "PROL. IQUITOS - JR. BERNARDO ALCEDO"
        },
        {
          "lon": -77.0807634217506,
          "lat": -11.9956787295991,
          "address": "AV. ANTUNEZ DE MAYOLO - JR. HUARI"
        },
        {
          "lon": -77.0774205527337,
          "lat": -11.9952030871241,
          "address": "AV. ANTUNEZ DE MAYOLO - JR. JULIO C. TELLO"
        },
        {
          "lon": -77.0651875196101,
          "lat": -11.9903029326347,
          "address": "AV. CARLOS IZAGUIRRE - JR. SALAVERRY"
        },
        {
          "lon": -77.0792205709303,
          "lat": -11.9576564459349,
          "address": "AV. CENTRAL - AV. BETANCURT"
        },
        {
          "lon": -77.0774372709176,
          "lat": -11.9637709002954,
          "address": "AV. HUANDOY - AV. A"
        },
        {
          "lon": -77.07598557899,
          "lat": -11.9543775827021,
          "address": "AV. HUANDOY - AV. HOLANDA"
        },
        {
          "lon": -77.0770186425257,
          "lat": -11.9476097925111,
          "address": "AV. LOS PROCERES DE HUANDOY - AV. 2 DE OCTUBRE - AV. 25 DE ENERO"
        },
        {
          "lon": -77.071317807179,
          "lat": -11.9376201124727,
          "address": "AV. LOS PROCERES DE HUANDOY - AV. ALFREDO MENDIOLA"
        },
        {
          "lon": -77.0759809541378,
          "lat": -11.9593429571756,
          "address": "AV. LOS PROCERES DE HUANDOY - AV. CENTRAL"
        },
        {
          "lon": -77.0772641605026,
          "lat": -11.9392426244097,
          "address": "AV. LOS PROCERES DE HUANDOY - AV. COORDIALIDAD"
        },
        {
          "lon": -77.0802156858591,
          "lat": -11.9699345631619,
          "address": "AV. LOS PROCERES DE HUANDOY - AV. MARAÑON"
        },
        {
          "lon": -77.0678333432572,
          "lat": -11.994686314768,
          "address": "AV. MANUEL GONZALES PRADA - CA. SANTA CRUZ"
        },
        {
          "lon": -77.0730169708807,
          "lat": -11.9592782009187,
          "address": "AV. SANTA ELVIRA - AV. CENTRAL"
        },
        {
          "lon": -77.0738559951719,
          "lat": -11.9654144735714,
          "address": "AV. UNIVERSITARIA - AV. A"
        },
        {
          "lon": -77.0845487336337,
          "lat": -11.9961706646321,
          "address": "AV. UNIVERSITARIA - AV. ANTUNEZ DE MAYOLO"
        },
        {
          "lon": -77.0816972654992,
          "lat": -11.9913895448584,
          "address": "AV. UNIVERSITARIA - AV. CARLOS IZAGUIRRE"
        },
        {
          "lon": -77.0783793788599,
          "lat": -11.9832472480494,
          "address": "AV. UNIVERSITARIA - AV. LOS ALISOS"
        },
        {
          "lon": -77.0809737784021,
          "lat": -11.9886288291635,
          "address": "AV. UNIVERSITARIA - AV. LOS OLIVOS"
        },
        {
          "lon": -77.0776777784597,
          "lat": -11.9779064249877,
          "address": "AV. UNIVERSITARIA - AV. NARANJAL"
        },
        {
          "lon": -77.0758433358004,
          "lat": -11.9719710289346,
          "address": "AV. UNIVERSITARIA - AV. RIO MARAÑON"
        },
        {
          "lon": -77.0717253915581,
          "lat": -11.9638449450191,
          "address": "AV. UNIVERSITARIA - AV. SANTA ELVIRA"
        },
        {
          "lon": -77.066602565275,
          "lat": -11.9957942303532,
          "address": "JR. GONZALES PRADA - CA. ABRAHAM VALDELOMAR"
        },
        {
          "lon": -76.9467987617153,
          "lat": -12.0140833672555,
          "address": "JR. LAS GAVIOTAS - AV. LOS TUCANES"
        },
        {
          "lon": -77.071367146402,
          "lat": -12.0973047450009,
          "address": "AV. DEL EJERCITO - JR. HIPOLITO UNANUE"
        },
        {
          "lon": -77.0640345374427,
          "lat": -12.0914465308024,
          "address": "AV. JAVIER PRADO - GONZALES PRADA"
        },
        {
          "lon": -77.0588554413901,
          "lat": -12.0964240911955,
          "address": "AV. JUAN DE ALIAGA - AV. ALBERTO DEL CAMPO - AV. FELIZ DIBOS"
        },
        {
          "lon": -77.0738395549225,
          "lat": -12.0912632138504,
          "address": "JR. BOLOGNESI - JR. CASTILLA"
        },
        {
          "lon": -77.0728003509994,
          "lat": -12.0916365198101,
          "address": "JR. BOLOGNESI - JR. LEONCIO PRADO - CA. ULISES DEL BOY"
        },
        {
          "lon": -77.0742261559977,
          "lat": -12.0922810835342,
          "address": "JR. GRAU - JR. CASTILLA"
        },
        {
          "lon": -77.0731770190653,
          "lat": -12.0926492386238,
          "address": "JR. GRAU - JR. LEONCIO PRADO - CA. ULISES DEL BOY"
        },
        {
          "lon": -77.0734769723394,
          "lat": -12.0902914575871,
          "address": "JR. JOSE GALVEZ - JR. CASTILLA"
        },
        {
          "lon": -77.0724445902026,
          "lat": -12.0906266609353,
          "address": "JR. JOSE GALVEZ - JR. LEONCIO PRADO - CA. ULISES DEL BOY"
        },
        {
          "lon": -77.0269967345863,
          "lat": -12.1278912220884,
          "address": "AV. 28 DE JULIO - AV. LA PAZ"
        },
        {
          "lon": -77.0236775913935,
          "lat": -12.1291985538612,
          "address": "AV. 28 DE JULIO - AV. REDUCTO"
        },
        {
          "lon": -77.023158501029,
          "lat": -12.125475559501,
          "address": "AV. ALFREDO BENAVIDES - CA. CASIMIRO ULLOA"
        },
        {
          "lon": -77.0269230829777,
          "lat": -12.124909110251,
          "address": "AV. ALFREDO BENAVIDES - AV. LA PAZ"
        },
        {
          "lon": -77.0279409358823,
          "lat": -12.1247338592193,
          "address": "AV. ALFREDO BENAVIDES - CA. ALCANFORES"
        },
        {
          "lon": -77.0095323379123,
          "lat": -12.127629384419,
          "address": "AV. ALFREDO BENAVIDES - CA. LA MERCED"
        },
        {
          "lon": -77.0128573099682,
          "lat": -12.1270873644376,
          "address": "AV. ALFREDO BENAVIDES - AV. GRAL. ERNESTO MONTAGNE"
        },
        {
          "lon": -77.0369180538501,
          "lat": -12.1140362733463,
          "address": "AV. ANGAMOS - AV. COMANDANTE ESPINAR"
        },
        {
          "lon": -77.0291274116822,
          "lat": -12.1179499283377,
          "address": "AV. AREQUIPA - CA, DOS DE MAYO - CA. PERSHING"
        },
        {
          "lon": -77.0276552818571,
          "lat": -12.1332111161424,
          "address": "AV. ARMENDARIZ - AV. VASCO NUÑES DE BALBOA"
        },
        {
          "lon": -77.0291238424683,
          "lat": -12.132177002199,
          "address": "AV. ARMENDARIZ - CA. LAS DALIAS"
        },
        {
          "lon": -77.03697737649,
          "lat": -12.1179371302812,
          "address": "AV. COMANDANTE ESPINAR - CA. DOS DE MAYO"
        },
        {
          "lon": -77.0485125872228,
          "lat": -12.1104029608328,
          "address": "AV. HIPÓLITO UNANUE - AV. LA MAR"
        },
        {
          "lon": -77.0358072317553,
          "lat": -12.1190650869555,
          "address": "AV. JOSÉ PARDO - AV. BOLOGNESI"
        },
        {
          "lon": -77.0369816422528,
          "lat": -12.1190486591992,
          "address": "AV. JOSÉ PARDO - AV. COMANDANTE ESPINAR"
        },
        {
          "lon": -77.0291065644867,
          "lat": -12.122364445954,
          "address": "AV. LARCO - AV. DIEZ CANSECO"
        },
        {
          "lon": -77.0291703488126,
          "lat": -12.1229483708103,
          "address": "AV. LARCO - CA. SHELL"
        },
        {
          "lon": -77.0293626294953,
          "lat": -12.1245189792122,
          "address": "AV. LARCO - AV. ALFREDO BENAVIDES"
        },
        {
          "lon": -77.0295158763695,
          "lat": -12.1268965823256,
          "address": "AV. LARCO - AV. 28 DE JULIO"
        },
        {
          "lon": -77.0298641525671,
          "lat": -12.1314659597535,
          "address": "AV. LARCO - AV. ARMENDARIZ"
        },
        {
          "lon": -77.0456685623234,
          "lat": -12.1132463003648,
          "address": "AV. MARISCAL LA MAR - CA. 8 DE OCT - CA. IGNACIO MERINO"
        },
        {
          "lon": -77.0301712105739,
          "lat": -12.1208202227248,
          "address": "CA. JOSÉ OLAYA - AV. DIAGONAL"
        },
        {
          "lon": -77.0313823258945,
          "lat": -12.1224406246277,
          "address": "CA. SHELL - CA. BERLÍN"
        },
        {
          "lon": -77.026090641365,
          "lat": -12.1191597738696,
          "address": "AV. PASEO DE LA REPUBLICA - AV. RICARDO PALMA"
        },
        {
          "lon": -77.0230269334706,
          "lat": -12.1295226740984,
          "address": "AV. PASEO DE LA REPÚBLICA - AV. 28 DE JULIO"
        },
        {
          "lon": -77.0263114478431,
          "lat": -12.1135028612754,
          "address": "AV. PASEO DE LA REPÚBLICA - AV. ANGAMOS"
        },
        {
          "lon": -77.0275201738818,
          "lat": -12.102840013874,
          "address": "AV. PASEO DE LA REPÚBLICA - AV. ARAMBURU"
        },
        {
          "lon": -77.0264394576382,
          "lat": -12.1086253343553,
          "address": "AV. PASEO DE LA REPUBLICA - AV. DOMINGO ORUE"
        },
        {
          "lon": -77.0252407888157,
          "lat": -12.1220713364118,
          "address": "AV. PASEO DE LA REPUBLICA - AV. ERNESTO DIEZ CANSECO"
        },
        {
          "lon": -77.0243673524887,
          "lat": -12.1253078609539,
          "address": "AV. PASEO DE LA REPUBLICA - AV. ALFREDO BENAVIDES"
        },
        {
          "lon": -77.0274341971613,
          "lat": -12.1191258640743,
          "address": "AV. RICARDO PALMA - AV. GRAL. SUAREZ"
        },
        {
          "lon": -77.0244757534874,
          "lat": -12.11959374762,
          "address": "AV. RICARDO PALMA - CA. M. ODICIO - AV. A. A. CACERES"
        },
        {
          "lon": -77.0205205755116,
          "lat": -12.1224558568117,
          "address": "AV. RICARDO PALMA - AV. CASIMIRO ULLOA"
        },
        {
          "lon": -77.0350429253969,
          "lat": -12.1078962973438,
          "address": "AV. SANTA CRUZ - AV. SANTA MARÍA"
        },
        {
          "lon": -77.0083914516437,
          "lat": -12.1180732207684,
          "address": "AV. TOMAS MARSANO - ALTURA CA. LAS MORAS"
        },
        {
          "lon": -77.0303087547712,
          "lat": -12.1180108652032,
          "address": "CA. 2 DE MAYO - CA. ATAHUALPA"
        },
        {
          "lon": -77.0370452715253,
          "lat": -12.1171489101601,
          "address": "CA. ENRIQUE PALACIOS - AV. COMANDANTE ESPINAR"
        },
        {
          "lon": -77.0380682575835,
          "lat": -12.1171235197997,
          "address": "CA. ENRIQUE PALACIOS - CA. TORRE TAGLE - CA. MIGUEL IGLESIAS"
        },
        {
          "lon": -77.0324392643584,
          "lat": -12.1169614301303,
          "address": "CA. ENRIQUE PALACIOS - CA. INDEPENDENCIA"
        },
        {
          "lon": -77.0443230936398,
          "lat": -12.1147830592082,
          "address": "CA. JOAQUIN CAPELLO - AV. LA MAR"
        },
        {
          "lon": -77.0330928558088,
          "lat": -12.1254143276417,
          "address": "MALECÓN 28 DE JULIO - AV. 28 DE JULIO"
        },
        {
          "lon": -77.0324532814893,
          "lat": -12.1238321883997,
          "address": "MALECÓN 28 DE JULIO - AV. ALFREDO BENAVIDES"
        },
        {
          "lon": -76.8737183796593,
          "lat": -12.1127610020869,
          "address": "AV. VICTOR MALASQUEZ - AV. MIGUEL GRAU"
        },
        {
          "lon": -77.0629096313427,
          "lat": -12.0778021723463,
          "address": "AV. GRAL. VIVANCO - AV. SAN MARTIN"
        },
        {
          "lon": -77.0600297130212,
          "lat": -12.0792873725822,
          "address": "AV. GRAL. VIVANCO - CA. TORRE TAGLE (WAGNER)"
        },
        {
          "lon": -77.0779475168676,
          "lat": -12.0704404910298,
          "address": "AV. UNIVERSITARIA - AV. BOLIVAR"
        },
        {
          "lon": -77.0796846734103,
          "lat": -12.0749336461976,
          "address": "AV. UNIVERSITARIA - AV. CIPRIANO DULANTO (LA MAR)"
        },
        {
          "lon": -77.0100124077895,
          "lat": -12.0941734713032,
          "address": "AV DEL PARQUE NORTE - AV. GUARDIA CIVIL"
        },
        {
          "lon": -77.0026457112963,
          "lat": -12.0942206900984,
          "address": "AV. AVIACION - AV. SAN BORJA NORTE"
        },
        {
          "lon": -77.0016935793267,
          "lat": -12.1010070845643,
          "address": "AV. AVIACION - AV. SAN BORJA SUR"
        },
        {
          "lon": -77.0013754460451,
          "lat": -12.1032701766679,
          "address": "AV. AVIACION - CA. ISAAC ALBENIZ"
        },
        {
          "lon": -77.0006678704414,
          "lat": -12.1086667476705,
          "address": "AV. AVIACION - CA. PAUL LINDER"
        },
        {
          "lon": -77.0011067609147,
          "lat": -12.1052623318443,
          "address": "AV. AVIACION - JR. ANDRES VESALIO"
        },
        {
          "lon": -77.0009974400264,
          "lat": -12.1058166666667,
          "address": "AV. AVIACION - JR. EDUARDO ORDOÑEZ - JR. F. TRAVINSKI"
        },
        {
          "lon": -77.0021610836821,
          "lat": -12.0979981621253,
          "address": "AV. AVIACION - JR. FRAY LUIS DE LEON"
        },
        {
          "lon": -77.0031566070531,
          "lat": -12.0908993315296,
          "address": "AV. DE LAS ARTES - AV. AVIACION"
        },
        {
          "lon": -76.9961688048285,
          "lat": -12.0899635138503,
          "address": "AV. DE LAS ARTES NORTE - AV. SAN LUIS"
        },
        {
          "lon": -76.9866273393548,
          "lat": -12.0911384330893,
          "address": "AV. DE LAS ARTES NORTE - CA. BOULEVARD"
        },
        {
          "lon": -76.9908672423741,
          "lat": -12.0894098408579,
          "address": "AV. DE LAS ARTES NORTE - JR. ROUSSEAU"
        },
        {
          "lon": -77.0059184780363,
          "lat": -12.0946561557536,
          "address": "AV. DE LAS ARTES NORTE - AV. SAN BORJA NORTE"
        },
        {
          "lon": -77.006268589005,
          "lat": -12.100968601136,
          "address": "AV. DE LAS ARTES SUR - AV. SAN BORJA SUR"
        },
        {
          "lon": -77.0105662157059,
          "lat": -12.0988385514017,
          "address": "AV. DEL PARQUE SUR - CA. REMINGTON"
        },
        {
          "lon": -77.010584361883,
          "lat": -12.0995964205252,
          "address": "AV. DEL PARQUE SUR - AV. SAN BORJA SUR"
        },
        {
          "lon": -77.0000013400083,
          "lat": -12.0873178825342,
          "address": "AV. JAVIER PRADO - ALT. AV. LA ARQUEOLOGIA"
        },
        {
          "lon": -77.0054933255195,
          "lat": -12.088116574472,
          "address": "AV. JAVIER PRADO - ALT. AV. LA POESIA"
        },
        {
          "lon": -76.9966681987652,
          "lat": -12.0866235707309,
          "address": "AV. JAVIER PRADO - AV. SAN LUIS"
        },
        {
          "lon": -76.9979517644702,
          "lat": -12.0869756922025,
          "address": "AV. JAVIER PRADO - CA. PASEO DE LA ARTESANIA"
        },
        {
          "lon": -77.0035335684602,
          "lat": -12.0882840707795,
          "address": "AV. JAVIER PRADO ESTE - AV. AVIACION"
        },
        {
          "lon": -77.0104949973969,
          "lat": -12.1072840703323,
          "address": "AV. PRINCIPAL - CA. TREINTIDOS"
        },
        {
          "lon": -76.9854889867379,
          "lat": -12.0964044569267,
          "address": "AV. SAN BORJA NORTE - AV. VELASCO ASTETE"
        },
        {
          "lon": -76.9946490715346,
          "lat": -12.100535511711,
          "address": "AV. SAN BORJA SUR - AV. SAN LUIS"
        },
        {
          "lon": -76.9897937155655,
          "lat": -12.1031061929654,
          "address": "AV. SAN BORJA SUR - CA. BOULEVARD - PASEO DEL BOSQUE"
        },
        {
          "lon": -76.9984919146947,
          "lat": -12.10071615495,
          "address": "AV. SAN BORJA SUR - JR. FRAY LUIS DE LEON"
        },
        {
          "lon": -76.9948891372985,
          "lat": -12.0988858495075,
          "address": "AV. SAN LUIS - CALLE BEETHOVEN"
        },
        {
          "lon": -76.9924936317635,
          "lat": -12.1079783048304,
          "address": "AV. SAN LUIS - CA. MADRID - CA. MELISSA"
        },
        {
          "lon": -76.9957160710343,
          "lat": -12.0930155747784,
          "address": "AV. SAN LUIS - AV. SAN BORJA NORTE"
        },
        {
          "lon": -76.9847053160176,
          "lat": -12.1078590731437,
          "address": "AV. VELASCO ASTETE - CA. ESMERALDA"
        },
        {
          "lon": -76.9930442200138,
          "lat": -12.0844727249911,
          "address": "CA. ROSA TORO - CA. BAILETTI"
        },
        {
          "lon": -76.9889748996468,
          "lat": -12.1005168840171,
          "address": "CUARTEL GENERAL DEL EJERCITO - AV. SAN BORJA SUR"
        },
        {
          "lon": -77.029531220165,
          "lat": -12.1032112540193,
          "address": "AV. ARAMBURU - CA. TACNA"
        },
        {
          "lon": -77.0325108817465,
          "lat": -12.1046342905817,
          "address": "AV. SANTA CRUZ - CA. PRO. ARENALES"
        },
        {
          "lon": -77.0329812819281,
          "lat": -12.0945568771446,
          "address": "AV. AREQUIPA - CA. MANUEL BAÑON"
        },
        {
          "lon": -77.05115150913,
          "lat": -12.0990066723142,
          "address": "AV. AURELIO MIROQUESADA - CA. CORONEL PORTILLO"
        },
        {
          "lon": -77.035647522608,
          "lat": -12.0964524970234,
          "address": "AV. CAMINO REAL - AV. PAZ SOLDAN"
        },
        {
          "lon": -77.0390737963727,
          "lat": -12.1056979713526,
          "address": "AV. CAMINO REAL - AV. PARDO Y ALIAGA"
        },
        {
          "lon": -77.0394603388996,
          "lat": -12.1076738558282,
          "address": "AV. CAMINO REAL - DASSO"
        },
        {
          "lon": -77.0377620512075,
          "lat": -12.0998325272869,
          "address": "AV. CAMINO REAL - CA. ALZAMORA"
        },
        {
          "lon": -77.0371760254378,
          "lat": -12.0989545755306,
          "address": "AV. CAMINO REAL - CALLE CHOQUEHUANCA"
        },
        {
          "lon": -77.0383854751489,
          "lat": -12.1018750143539,
          "address": "AV. CAMINO REAL - CA. J. CAVERO"
        },
        {
          "lon": -77.0387953358113,
          "lat": -12.1038924409164,
          "address": "AV. CAMINO REAL - CA. ESQUILACHE"
        },
        {
          "lon": -77.0387241428459,
          "lat": -12.1034450936477,
          "address": "AV. CAMINO REAL - AV. JUAN PEZET"
        },
        {
          "lon": -77.0514866107201,
          "lat": -12.1029950621055,
          "address": "AV. CORONEL PORTILLO - AV. JUAN PEZET"
        },
        {
          "lon": -77.0112702431185,
          "lat": -12.1037572703006,
          "address": "AV. DEL PARQUE SUR - AV. JOSÉ GALVEZ BARRENECHEA"
        },
        {
          "lon": -77.0148639741841,
          "lat": -12.1031710488425,
          "address": "AV. DEL PARQUE SUR - AV. GUARDIA CIVIL"
        },
        {
          "lon": -77.0484600133152,
          "lat": -12.1092445502887,
          "address": "AV. GRAL. CORDOVA - AV. HIPOLITO UNANUE"
        },
        {
          "lon": -77.0075968865273,
          "lat": -12.088894163777,
          "address": "AV. JAVIER PRADO - AV. GUARDIA CIVIL"
        },
        {
          "lon": -77.0383760085108,
          "lat": -12.0949748431998,
          "address": "AV. JORGE BASADRE - CA. LAS PALMERAS"
        },
        {
          "lon": -77.031034675919,
          "lat": -12.0968623408832,
          "address": "AV. JUAN DE ARONA - CA. FEDERICO VILLAREAL"
        },
        {
          "lon": -77.0287749542482,
          "lat": -12.0965631980547,
          "address": "AV. JUAN DE ARONA - CA. LAS CAMELIAS"
        },
        {
          "lon": -77.04804293514,
          "lat": -12.0975162409871,
          "address": "AV. NICOLAS DE RIVERA - AV. LOS EUCALIPTOS"
        },
        {
          "lon": -77.0469128010989,
          "lat": -12.0973122578708,
          "address": "AV. NICOLAS DE RIVERA - CA. LAS FLORES"
        },
        {
          "lon": -77.0371507146498,
          "lat": -12.1072682742536,
          "address": "AV. PARDO Y ALIAGA - AV. LOS CONQUISTADORES"
        },
        {
          "lon": -77.0253642799402,
          "lat": -12.0967542451624,
          "address": "AV. PASEO DE LA REPUBLICA -  AV. CANAVAL Y MOREYRA"
        },
        {
          "lon": -77.0312953899267,
          "lat": -12.0987412238803,
          "address": "AV. PETIT THOUARS - AV. REP. DE COLOMBIA"
        },
        {
          "lon": -77.0266660587984,
          "lat": -12.0940367003544,
          "address": "AV. RIVERA NAVARRETE - CA. ANDRÉS REYES"
        },
        {
          "lon": -77.0479807270614,
          "lat": -12.1029485891792,
          "address": "CA. BELEN - AV. JUAN PEZET"
        },
        {
          "lon": -77.0481939671476,
          "lat": -12.0882523640717,
          "address": "CA. CADIZ - CA. PRESCOTT"
        },
        {
          "lon": -77.0389877894846,
          "lat": -12.1082865406567,
          "address": "CA. CAVENECIA - CA. COCHRANE"
        },
        {
          "lon": -77.0470569213591,
          "lat": -12.0961886925136,
          "address": "AV. JORGE BASADRE - CA. LAS FLORES"
        },
        {
          "lon": -77.0425407305495,
          "lat": -12.095527794787,
          "address": "CA. JORGE BASADRE - CA. LOS ROBLES"
        },
        {
          "lon": -77.0447947217855,
          "lat": -12.095880543816,
          "address": "CA. JORGE BASADRE - CA. LOS NOGALES"
        },
        {
          "lon": -77.0402392390683,
          "lat": -12.0952428820884,
          "address": "CA. JORGE BASADRE - CA. EL ROSARIO"
        },
        {
          "lon": -77.0251211969505,
          "lat": -12.0941920322792,
          "address": "CA. LAS BEGONIAS - CA. ANDRÉS REYES"
        },
        {
          "lon": -77.0291155842127,
          "lat": -12.0943763204057,
          "address": "CA. LAS CAMELIAS - CA. ANDRÉS REYES"
        },
        {
          "lon": -77.0024127667734,
          "lat": -12.0039281108447,
          "address": "AV. 13 DE ENERO - AV. LOS JARDINES"
        },
        {
          "lon": -77.0051798503324,
          "lat": -11.9962588338582,
          "address": "AV. 13 DE JUNIO (13 DE ENERO) - AV. LOS POSTES"
        },
        {
          "lon": -76.9972438459002,
          "lat": -12.0167207152412,
          "address": "AV. 13 DE JUNIO (13 DE ENERO) - AV. LURIGANCHO"
        },
        {
          "lon": -76.9985133241893,
          "lat": -12.0113895992837,
          "address": "AV. 13 DE JUNIO (13 DE ENERO) - AV. TUSILAGOS"
        },
        {
          "lon": -77.0131956754744,
          "lat": -11.9822838253487,
          "address": "AV. CANTO GRANDE - AV. EL SOL"
        },
        {
          "lon": -77.0153006886016,
          "lat": -11.9900528404669,
          "address": "AV. CANTO GRANDE - AV. JORGE BASADRE"
        },
        {
          "lon": -77.0058166061791,
          "lat": -11.9703488352398,
          "address": "AV. CANTO GRANDE - AV. LAS FLORES - AV. LLOQUE YUPANQUI"
        },
        {
          "lon": -77.0088924815792,
          "lat": -11.9753115173393,
          "address": "AV. CANTO GRANDE - AV. LOS PERIODISTAS"
        },
        {
          "lon": -77.0159749812924,
          "lat": -11.999120377026,
          "address": "AV. CANTO GRANDE - AV. LOS POSTES"
        },
        {
          "lon": -77.0069948939624,
          "lat": -11.9720452827756,
          "address": "AV. CANTO GRANDE - AV. MACCHU PICCHU"
        },
        {
          "lon": -77.0043006760115,
          "lat": -11.9681842434296,
          "address": "AV. CANTO GRANDE - AV. SAN MARTIN"
        },
        {
          "lon": -76.9712120210008,
          "lat": -11.9368676499724,
          "address": "AV. FERNANDO WIESSE - INGRESO A POSTA MEDICA - CA. 10"
        },
        {
          "lon": -76.9849935387783,
          "lat": -11.9476338864618,
          "address": "AV. FERNANDO WIESSE - AV. EL MURO"
        },
        {
          "lon": -76.9767409710075,
          "lat": -11.9388476716636,
          "address": "AV. FERNANDO WIESSE - C.E SJL CRUZ DE MOTUPE - CA. 66"
        },
        {
          "lon": -76.9803381764601,
          "lat": -11.9411639441709,
          "address": "AV. FERNANDO WIESSE - JR. FINAL"
        },
        {
          "lon": -76.9687977506346,
          "lat": -11.9332932778443,
          "address": "AV. FERNANDO WIESSE - JR. MAR DEL NORTE"
        },
        {
          "lon": -77.001813947062,
          "lat": -12.02568826704,
          "address": "AV. GRAN CHIMU - AV. PIRAMIDE DEL SOL"
        },
        {
          "lon": -77.0038379910072,
          "lat": -12.0264670658801,
          "address": "AV. GRAN CHIMU - CA. LANZON"
        },
        {
          "lon": -76.991376080997,
          "lat": -12.0243464740384,
          "address": "AV. GRAN CHIMU - AV. LAS LOMAS"
        },
        {
          "lon": -76.9861417956543,
          "lat": -12.0238799763224,
          "address": "AV. GRAN CHIMU - CA. GRAN PAJATEN"
        },
        {
          "lon": -76.9888466400314,
          "lat": -12.0242309037764,
          "address": "AV. GRAN CHIMU - CA. HARAVICU"
        },
        {
          "lon": -77.0003894021754,
          "lat": -12.0253688954909,
          "address": "AV. GRAN CHIMU - JR.CHINCHAYSUYO"
        },
        {
          "lon": -76.9984882136626,
          "lat": -12.0249337093482,
          "address": "AV. GRAN CHIMU - JR. VARA DE ORO"
        },
        {
          "lon": -76.9809417050455,
          "lat": -12.0249972532259,
          "address": "AV. GRAN CHIMU - MALECON CHECA"
        },
        {
          "lon": -76.9990471014903,
          "lat": -12.0026741067469,
          "address": "AV. LAS CANTUARIAS - AV. LOS JARDINES OESTE - AV. SANTA ROSA"
        },
        {
          "lon": -77.0119529591474,
          "lat": -12.0251804909743,
          "address": "AV. LAS FLORES DE PRIMAVERA - AV. LIMA - PSJE. HUANCAVELICA"
        },
        {
          "lon": -77.011449365555,
          "lat": -12.0122803833145,
          "address": "AV. LAS FLORES DE PRIMAVERA - AV. TUSILAGOS"
        },
        {
          "lon": -77.0121204272266,
          "lat": -12.0177198990674,
          "address": "AV. LAS FLORES DE PRIMAVERA - LAS ALCAPARRAS"
        },
        {
          "lon": -77.0112237398158,
          "lat": -12.0072241275076,
          "address": "AV. LAS FLORES DE PRIMAVERA - CA. LAS VERDOLAGAS"
        },
        {
          "lon": -77.0111721292226,
          "lat": -12.0092151410169,
          "address": "AV. LAS FLORES DE PRIMAVERA - CA. LAS CORALINAS"
        },
        {
          "lon": -76.9900864248432,
          "lat": -12.0178558606277,
          "address": "AV. LAS LOMAS - AV. LURIGANCHO"
        },
        {
          "lon": -77.0010434064548,
          "lat": -12.0176415497473,
          "address": "AV. LURIGANCHO - JR. SAN AURELIO"
        },
        {
          "lon": -77.0150407965069,
          "lat": -12.0290552834132,
          "address": "AV. PERU - CA. MOQUEGUA"
        },
        {
          "lon": -77.0176265915461,
          "lat": -12.0294105528893,
          "address": "AV. PERU - CA. SAN NICOLAS"
        },
        {
          "lon": -76.9966356677025,
          "lat": -12.0245459818819,
          "address": "AV. PORTADA DEL SOL - AV. GRAN CHIMU"
        },
        {
          "lon": -77.0020106190679,
          "lat": -12.0180142784628,
          "address": "AV. PIRAMIDE DEL SOL - AV. LURIGANCHO"
        },
        {
          "lon": -77.0014669206134,
          "lat": -12.0202468639093,
          "address": "AV. PIRAMIDE DEL SOL - CA. LAS MERCEDES"
        },
        {
          "lon": -77.0017440482129,
          "lat": -12.0226007054307,
          "address": "AV. PIRAMIDE DEL SOL - JR. CAJAMARQUILLA"
        },
        {
          "lon": -77.0011507253868,
          "lat": -12.0277838060359,
          "address": "AV. PIRAMIDE DEL SOL - MALECON CHECA"
        },
        {
          "lon": -77.0007912308495,
          "lat": -12.0287930389605,
          "address": "AV. PIRAMIDE DEL SOL - MALECÓN AMISTAD"
        },
        {
          "lon": -76.9973011780426,
          "lat": -12.0224647216195,
          "address": "AV. PORTADA DEL SOL - JR. CAJAMARQUILLA"
        },
        {
          "lon": -77.0114491106831,
          "lat": -12.0287779435472,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - PSJE. QUIPUCAMAYOC"
        },
        {
          "lon": -77.0017978266486,
          "lat": -12.0166728433229,
          "address": "JR. CHINCHAYSUYO - AV. PROCERES DE LA INDEPENDENCIA"
        },
        {
          "lon": -77.0086652926877,
          "lat": -12.0016235703186,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - JR. ENELDOS - CA. LAS DRUSAS"
        },
        {
          "lon": -77.0110397762133,
          "lat": -12.027770067179,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - CALLE EL TUMI - ESTACION CAJA DE AGUA"
        },
        {
          "lon": -77.0054423247574,
          "lat": -11.9812914018689,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - CA. LOS DURAZNOS"
        },
        {
          "lon": -76.9939908737729,
          "lat": -11.968787812902,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. SANTA ROSA DE LIMA"
        },
        {
          "lon": -77.0106722186668,
          "lat": -11.9933306156594,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. SAN HILARION"
        },
        {
          "lon": -77.0116451908011,
          "lat": -12.0284745806299,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. PERU"
        },
        {
          "lon": -77.0025469666014,
          "lat": -12.0180397797185,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. LURIGANCHO"
        },
        {
          "lon": -77.0021453032738,
          "lat": -12.0131978456672,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. LOS TUSILAGOS"
        },
        {
          "lon": -77.0096904359704,
          "lat": -11.9972203310591,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. LOS POSTES"
        },
        {
          "lon": -77.0059355473641,
          "lat": -12.0059643611972,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. LOS JARDINES OESTE - METRO"
        },
        {
          "lon": -77.010220755549,
          "lat": -12.0257192363455,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. LIMA"
        },
        {
          "lon": -77.0014574471846,
          "lat": -11.9757055801419,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. LAS FLORES - JR. RIO HUALLAGA"
        },
        {
          "lon": -77.0061935751661,
          "lat": -12.021476335124,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. JOSE ANTONIO ENCINAS"
        },
        {
          "lon": -77.0105744740951,
          "lat": -11.9895168662396,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. JORGE BASADRE"
        },
        {
          "lon": -76.9869203250226,
          "lat": -11.9589146317723,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. HEROES DEL CENEPA - ESTACION BAYOVAR"
        },
        {
          "lon": -77.0066304690881,
          "lat": -11.9848749458708,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. EL SOL"
        },
        {
          "lon": -77.0047675993952,
          "lat": -11.9795398521804,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. EL BOSQUE"
        },
        {
          "lon": -77.0038414435379,
          "lat": -11.9780450287127,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. DEL PARQUE"
        },
        {
          "lon": -77.0043772756821,
          "lat": -12.008298515102,
          "address": "AV. PROCERES - HOSPITAL DE SOLIDARIDAD"
        },
        {
          "lon": -77.0121200143757,
          "lat": -12.0321524907228,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - AV. 9 DE OTUBRE - AV. MALECON CHECA - OVALO ZARATE"
        },
        {
          "lon": -77.0123229026149,
          "lat": -12.0315033997929,
          "address": "AV. PROCERES DE LA INDEPENDENCIA - ALT. MERCADO EL BOSQUE"
        },
        {
          "lon": -76.9918309374588,
          "lat": -11.9788476227753,
          "address": "AV. SAN MARTIN - AV. SANTA ROSA"
        },
        {
          "lon": -77.0124799153093,
          "lat": -12.0205996888164,
          "address": "AV. TUPAC AMARU - AV. LAS FLORES DE PRIMAVERA"
        },
        {
          "lon": -77.0079120791013,
          "lat": -12.0233093059315,
          "address": "AV. TUPAC AMARU - AV. PROCERES DE LA INDEPENDENCIA"
        },
        {
          "lon": -77.0007730705289,
          "lat": -12.0227061372634,
          "address": "JR. CHINCHAYSUYO - JR. CAJAMARQUILLA"
        },
        {
          "lon": -76.9999069281412,
          "lat": -12.0274910953861,
          "address": "JR. CHINCHAYSUYO - MALECON CHECA"
        },
        {
          "lon": -76.9997480932795,
          "lat": -12.0285259247914,
          "address": "JR. CHINCHAYSUYO - MALECON AMISTAD"
        },
        {
          "lon": -77.000036464698,
          "lat": -12.0268961550468,
          "address": "JR. CHINCHAYSUYO - TAHUANTUNSUYO"
        },
        {
          "lon": -76.9746450090128,
          "lat": -12.141760033721,
          "address": "AV. AGUSTIN ROSA LOZANO - JR. LOS GERANIOS"
        },
        {
          "lon": -76.9749588278821,
          "lat": -12.1405221663753,
          "address": "AV. AGUSTIN ROSA LOZANO - JR. CERRO GRIS"
        },
        {
          "lon": -76.9718548661816,
          "lat": -12.1452933989139,
          "address": "AV. DEFENSORES DE LIMA - AV. EDILBERTO RAMOS"
        },
        {
          "lon": -76.9749349257491,
          "lat": -12.1407172665278,
          "address": "AV. DEFENSORES DE LIMA - MERCADO MODELO SAN LUIS"
        },
        {
          "lon": -76.9595066901635,
          "lat": -12.1862145344468,
          "address": "AV. MIGUEL IGLESIAS - AV. LAS BEGONIAS"
        },
        {
          "lon": -76.96021806313,
          "lat": -12.1636003477744,
          "address": "AV. MIGUEL IGLESIAS - AV. BILLINGHURST"
        },
        {
          "lon": -76.9782725879029,
          "lat": -12.1668270352728,
          "address": "AV. PEDRO MIOTA - AV. VARGAS MACHUCA"
        },
        {
          "lon": -76.9695679154631,
          "lat": -12.148383923164,
          "address": "AV. SALVADOR ALLENDE - AV. SAN JUAN"
        },
        {
          "lon": -76.9707851326213,
          "lat": -12.1464133475349,
          "address": "AV. SALVADOR ALLENDE - AV. CENTRAL"
        },
        {
          "lon": -76.9721790765033,
          "lat": -12.1455303357675,
          "address": "AV. SALVADOR ALLENDE - AV. EDILBERTO RAMOS"
        },
        {
          "lon": -76.9732440302554,
          "lat": -12.1584873263578,
          "address": "AV. SAN JUAN - AV. BILLINGHURST"
        },
        {
          "lon": -76.9727549664981,
          "lat": -12.1652362575574,
          "address": "AV. SAN JUAN - AV. VARGAS MACHUCA"
        },
        {
          "lon": -77.0082136273866,
          "lat": -12.084667308959,
          "address": "AV. CANADA - AV. DEL AIRE"
        },
        {
          "lon": -76.9893628246017,
          "lat": -12.0779113664906,
          "address": "AV. CANADA - AV. CIRCUNVALACION"
        },
        {
          "lon": -76.9972479115892,
          "lat": -12.0824752407859,
          "address": "AV. CANADA - AV. SAN LUIS"
        },
        {
          "lon": -77.0008832606304,
          "lat": -12.0836578763559,
          "address": "AV. CANADA - CA. ARQUEOLOGIA"
        },
        {
          "lon": -77.0100325901722,
          "lat": -12.084936110116,
          "address": "AV. CANADA - AV. LUIS ALDANA"
        },
        {
          "lon": -77.0043099919004,
          "lat": -12.0841132991817,
          "address": "AV. CANADA - AV. AVIACION"
        },
        {
          "lon": -76.9935443751844,
          "lat": -12.0809711424629,
          "address": "AV. CANADA - AV. ROSA TORO"
        },
        {
          "lon": -76.9974013303321,
          "lat": -12.0671209782619,
          "address": "AV. CIRCUNVALACIÓN - AV. PABLO PATRÓN"
        },
        {
          "lon": -76.9954695687993,
          "lat": -12.0763674171672,
          "address": "AV. DEL AIRE - AV. ROSA TORO"
        },
        {
          "lon": -77.0110833195523,
          "lat": -12.0889524890756,
          "address": "AV. JAVIER PRADO - CA. LUIS SAENZ"
        },
        {
          "lon": -76.9991716028407,
          "lat": -12.0700686710782,
          "address": "AV. NICOLAS ARRIOLA - AV. ROSA TORO"
        },
        {
          "lon": -76.9969126195295,
          "lat": -12.0739153462709,
          "address": "AV. SAN JUAN - AV. ROSA TORO"
        },
        {
          "lon": -77.0010822880085,
          "lat": -12.075101564233,
          "address": "AV. SAN JUAN - AV. SAN LUIS"
        },
        {
          "lon": -76.9991100068084,
          "lat": -12.0784113958281,
          "address": "AV. SAN LUIS - AV. DEL AIRE"
        },
        {
          "lon": -77.0028304770681,
          "lat": -12.0720786075432,
          "address": "AV. SAN LUIS - AV. NICOLAS ARRIOLA"
        },
        {
          "lon": -76.9907964438609,
          "lat": -12.0789103951893,
          "address": "AV. CANADA - JR. ALCAZAR"
        },
        {
          "lon": -77.059755747499,
          "lat": -12.0187641547474,
          "address": "AV. ALFREDO MENDIOLA - CA. SANTA MERCEDES"
        },
        {
          "lon": -77.059306728949,
          "lat": -12.0227233645147,
          "address": "AV. ALFREDO MENDIOLA - CA. SANTA CARMELA"
        },
        {
          "lon": -77.0547220514245,
          "lat": -12.0264679822611,
          "address": "AV. EDUARDO DE HABICH - JR. VALDIZAN"
        },
        {
          "lon": -77.0521246958446,
          "lat": -12.0247325113913,
          "address": "AV. EDUARDO DE HABICH - CA. MICHAEL FORT"
        },
        {
          "lon": -77.0932313966112,
          "lat": -12.0280979650514,
          "address": "AV. LIMA - AV. SALAVERRY"
        },
        {
          "lon": -77.0882963457778,
          "lat": -12.037926665602,
          "address": "AV. MORALES DUAREZ - JR. DANIEL A. CARRION"
        },
        {
          "lon": -77.0934730390117,
          "lat": -12.0389243018104,
          "address": "AV. MORALES DUAREZ - JR. JORGE CHAVEZ"
        },
        {
          "lon": -77.0499137612336,
          "lat": -12.0374243328038,
          "address": "AV. MORALES DUAREZ - PUENTE SANTA MARIA"
        },
        {
          "lon": -77.0699499821342,
          "lat": -12.0318196722906,
          "address": "AV. PERU - CA. EL SALVADOR"
        },
        {
          "lon": -77.0855262037811,
          "lat": -12.0309238331965,
          "address": "AV. PERU - JR. CALLAO - JR. IQUITOS"
        },
        {
          "lon": -77.0729357342137,
          "lat": -12.0318578226042,
          "address": "AV. PERU - JR. PUERTO BARRIOS"
        },
        {
          "lon": -77.0663060288583,
          "lat": -12.0319994909852,
          "address": "AV. PERU - JR. SANTA FE"
        },
        {
          "lon": -77.0840526358018,
          "lat": -12.0311149500992,
          "address": "AV. PERU - AV. 27 DE NOVIEMBRE"
        },
        {
          "lon": -77.1013526742932,
          "lat": -12.0196426936867,
          "address": "AV. TOMAS VALLE - AV. PERU"
        },
        {
          "lon": -77.0823799746462,
          "lat": -12.0066044020856,
          "address": "AV. UNIVERSITARIA - AV. ANGELICA GAMARRA"
        },
        {
          "lon": -77.0831665029589,
          "lat": -12.0026542696896,
          "address": "AV. UNIVERSITARIA - AV. LAS TORRES"
        },
        {
          "lon": -77.076313656889,
          "lat": -12.0192409480218,
          "address": "AV. UNIVERSITARIA - AV. GERMAN AGUIRRE"
        },
        {
          "lon": -77.0754091864897,
          "lat": -12.0261418264373,
          "address": "AV. UNIVERSITARIA - AV. JOSE GRANDA"
        },
        {
          "lon": -77.0766360309128,
          "lat": -12.0293687603428,
          "address": "AV. UNIVERSITARIA - AV. LIMA"
        },
        {
          "lon": -77.0775645202107,
          "lat": -12.031862582533,
          "address": "AV. UNIVERSITARIA - AV. PERU"
        },
        {
          "lon": -77.0794361925755,
          "lat": -12.0128730256907,
          "address": "AV. UNIVERSITARIA - AV. TOMAS VALLE"
        },
        {
          "lon": -77.0574467930245,
          "lat": -12.0289712391373,
          "address": "PANAMERICANA NORTE - AV. EDUARDO DE HABICH"
        },
        {
          "lon": -77.088264875732,
          "lat": -12.0808646549778,
          "address": "AV. BRIGIDA SILVA DE OCHOA - PROL. AYACUCHO"
        },
        {
          "lon": -77.0886670249806,
          "lat": -12.0814722125138,
          "address": "AV. BRIGIDA SILVA DE OCHOA - PROL. CUSCO"
        },
        {
          "lon": -77.0969714498522,
          "lat": -12.0852240664855,
          "address": "AV. COSTANERA - AV. GAMARRA"
        },
        {
          "lon": -77.0972420332386,
          "lat": -12.0647932261795,
          "address": "AV. ELMER FAUCETT - AV. PARQUE DE LAS LEYENDAS - AV. MIGUEL IDALGO"
        },
        {
          "lon": -77.0980211719026,
          "lat": -12.0694384072687,
          "address": "AV. ELMER FAUCETT - AV. PRECURSORES"
        },
        {
          "lon": -77.097176749198,
          "lat": -12.0625497421472,
          "address": "AV. ELMER FAUCETT - AV. VENEZUELA"
        },
        {
          "lon": -77.0842904921518,
          "lat": -12.0897849094883,
          "address": "AV. LA PAZ - JR. INDEPENDENCIA"
        },
        {
          "lon": -77.0822031119547,
          "lat": -12.0747697494266,
          "address": "AV. MANUEL DULANTO (EX LA MAR) - CA. CHAMAYA"
        },
        {
          "lon": -77.0959523317797,
          "lat": -12.0829433289307,
          "address": "AV. RAFAEL ESCARDO - AV. LIBERTAD"
        },
        {
          "lon": -77.0941091260797,
          "lat": -12.0789375407408,
          "address": "AV. RAFAEL ESCARDO - AV. PATRIOTAS"
        },
        {
          "lon": -77.0824184101341,
          "lat": -12.0630275575191,
          "address": "AV. RIVA AGUERO - CA. LOS NARANJALES - JR. DIEGO FERRER"
        },
        {
          "lon": -77.085001406843,
          "lat": -12.0817208595905,
          "address": "AV. RIVA AGUERO - PROL. AYACUCHO"
        },
        {
          "lon": -77.0777838656817,
          "lat": -12.0684638676032,
          "address": "AV. UNIVERSITARIA - AV. MARIANO CORNEJO"
        },
        {
          "lon": -77.0782298363085,
          "lat": -12.0642715487172,
          "address": "AV. UNIVERSITARIA - CA. LOS TULIPANES"
        },
        {
          "lon": -77.0806498031696,
          "lat": -12.0764352144352,
          "address": "AV. UNIVERSITARIA - CA. MANTARO"
        },
        {
          "lon": -77.0823165844538,
          "lat": -12.0819354630527,
          "address": "AV. UNIVERSITARIA - PROL. AYACUCHO"
        },
        {
          "lon": -77.0826337475148,
          "lat": -12.0836246582409,
          "address": "AV. UNIVERSITARIA - PROL. CUZCO"
        },
        {
          "lon": -77.104631812729,
          "lat": -12.0631959444501,
          "address": "AV. VENEZUELA - AV. LOS INSURGENTES"
        },
        {
          "lon": -77.0884215042794,
          "lat": -12.0615830097733,
          "address": "AV. VENEZUELA - INGRESO A HOSPITAL DE LA BASE NAVAL"
        },
        {
          "lon": -77.0763339220727,
          "lat": -12.0816589449335,
          "address": "JR. MIGUEL DE UNAMUNO - JR. CAMINOS DEL INCA"
        },
        {
          "lon": -76.9725436528826,
          "lat": -12.0486437563482,
          "address": "AV. 7 DE JUNIO - AV. BOLOGNESI"
        },
        {
          "lon": -76.9851938866849,
          "lat": -12.0399491995232,
          "address": "AV. CESAR VALLEJO - CA. LOS MEDIDORES"
        },
        {
          "lon": -76.9627663067863,
          "lat": -12.0370928317477,
          "address": "AV. CHANCAS - AV. ENCALADA"
        },
        {
          "lon": -76.9741790693486,
          "lat": -12.0450067309347,
          "address": "AV. EUCALIPTOS - AV. FRANCISCO BOLOGNESI"
        },
        {
          "lon": -76.9541358607012,
          "lat": -12.0378670698412,
          "address": "AV. HUAROCHIRI - AV. METROPOLITANA"
        },
        {
          "lon": -76.9490845815833,
          "lat": -12.0358137327873,
          "address": "AV. LA CULTURA - AV. METROPOLITANA"
        },
        {
          "lon": -76.9498663355234,
          "lat": -12.0331315022027,
          "address": "AV. LA CULTURA - AV. LOS VIRREYES"
        },
        {
          "lon": -76.9477077283591,
          "lat": -12.0391428417789,
          "address": "AV. LA CULTURA - AV. SANTA ANA"
        },
        {
          "lon": -76.9653311290469,
          "lat": -12.0381373348023,
          "address": "AV. LOS CHANCAS - AV. IMPERIAL"
        },
        {
          "lon": -76.9607795126848,
          "lat": -12.0406411516636,
          "address": "AV. METROPOLITANA - AV. COLECTORA"
        },
        {
          "lon": -76.9562139344995,
          "lat": -12.0511160431805,
          "address": "AV. NICOLAS AYLLON - JR. 9 DE SETIEMBRE"
        },
        {
          "lon": -76.9701374291481,
          "lat": -12.043379183582,
          "address": "AV. SANTIAGO DE CHUCO - CA. LOS RUISEÑORES - AV. LOS EUCALIPTOS"
        },
        {
          "lon": -76.9515695256285,
          "lat": -12.0491927569892,
          "address": "CARRETERA CENTRAL - CA. MARTHNEY"
        },
        {
          "lon": -76.9611080826586,
          "lat": -12.0531673941175,
          "address": "CARRETERA CENTRAL - AV. SANTA ROSA"
        },
        {
          "lon": -76.9444778507578,
          "lat": -12.0464084126231,
          "address": "CARRETERA CENTRAL - AV. LA CULTURA - AV. ASTURIAS"
        },
        {
          "lon": -76.996039531442,
          "lat": -12.1285264713339,
          "address": "AV. ALFREDO BENAVIDES - Jr. León García"
        },
        {
          "lon": -76.9928030408079,
          "lat": -12.1283082317175,
          "address": "AV. ALFREDO BENAVIDES - Av. Ayacucho"
        },
        {
          "lon": -76.9917398930551,
          "lat": -12.1281583285618,
          "address": "AV. ALFREDO BENAVIDES - Av. Higuereta"
        },
        {
          "lon": -76.9875917369744,
          "lat": -12.1278796017704,
          "address": "AV. ALFREDO BENAVIDES - AV. VELASCO ASTETE"
        },
        {
          "lon": -76.9818280209471,
          "lat": -12.1298076271569,
          "address": "AV. ALFREDO BENAVIDES - Av. Caminos del Inca"
        },
        {
          "lon": -76.9851489338277,
          "lat": -12.1285660400025,
          "address": "AV. ALFREDO BENAVIDES - Ca. Batallón Callao Sur"
        },
        {
          "lon": -76.9785894165387,
          "lat": -12.1309976254811,
          "address": "AV. ALFREDO BENAVIDES - Panamericana Sur"
        },
        {
          "lon": -77.000478174868,
          "lat": -12.1268729703194,
          "address": "AV. AVIACION - Jr. Alicante"
        },
        {
          "lon": -76.999277582705,
          "lat": -12.1239126128852,
          "address": "AV. AVIACION - AV. MADRID"
        },
        {
          "lon": -76.9983198827643,
          "lat": -12.1200084499983,
          "address": "AV. AVIACION - AV. VILLARAN"
        },
        {
          "lon": -77.0037102452881,
          "lat": -12.1448229508347,
          "address": "AV. AYACUCHO - Jr. Arica"
        },
        {
          "lon": -76.9939441662061,
          "lat": -12.1304906557615,
          "address": "AV. AYACUCHO - Ca. Lapeyre"
        },
        {
          "lon": -77.0027062059913,
          "lat": -12.1433881236905,
          "address": "AV. AYACUCHO - Ca. Doña Delmira"
        },
        {
          "lon": -76.9954442319617,
          "lat": -12.1329265823979,
          "address": "AV. AYACUCHO - Ca. Antonio Pezet"
        },
        {
          "lon": -77.0130121275929,
          "lat": -12.1519790925127,
          "address": "Av. Camino Real - Ca. Juan E. Pazos"
        },
        {
          "lon": -76.9889694930709,
          "lat": -12.1186844027248,
          "address": "AV. CAMINOS DEL INCA - Jr. Cádiz"
        },
        {
          "lon": -76.9925342973317,
          "lat": -12.1131390932871,
          "address": "AV. CAMINOS DEL INCA - Jr. Monterrey"
        },
        {
          "lon": -76.9934537165153,
          "lat": -12.1115278412073,
          "address": "AV. CAMINOS DEL INCA - Av. Primavera"
        },
        {
          "lon": -76.9851720314046,
          "lat": -12.1433349864963,
          "address": "AV. CAMINOS DEL INCA - Ca. Andrés Tinoco"
        },
        {
          "lon": -76.9838693229103,
          "lat": -12.1356544808583,
          "address": "AV. CAMINOS DEL INCA - Jr. Ismael Bielich"
        },
        {
          "lon": -76.9831256194784,
          "lat": -12.1331565837343,
          "address": "AV. CAMINOS DEL INCA - Av. Las Nazarenas"
        },
        {
          "lon": -76.9856661278756,
          "lat": -12.1229867136464,
          "address": "AV. CAMINOS DEL INCA - AV. VELASCO ASTETE"
        },
        {
          "lon": -76.9879398090499,
          "lat": -12.1206330224943,
          "address": "AV. CAMINOS DEL INCA - Av. Higuereta"
        },
        {
          "lon": -76.9846676712294,
          "lat": -12.1402125031559,
          "address": "AV. CAMINOS DEL INCA - Loma Umbrosa"
        },
        {
          "lon": -76.9834328879816,
          "lat": -12.1250844890902,
          "address": "AV. CAMINOS DEL INCA - Av. Batallón Callao"
        },
        {
          "lon": -76.9841754041865,
          "lat": -12.1377576388278,
          "address": "AV. CAMINOS DEL INCA - Av. Monterrico Sur"
        },
        {
          "lon": -76.9733288320847,
          "lat": -12.1149369910815,
          "address": "AV. CASUARINAS (ALMENDRAS) - Av. Bunganvilla"
        },
        {
          "lon": -76.9647170156108,
          "lat": -12.1061087182295,
          "address": "Av. Central - Jr. Alonso de Molina"
        },
        {
          "lon": -76.9670964364327,
          "lat": -12.0900271551128,
          "address": "Av. Club Golf Los Incas - Jr. Cruz del Sur"
        },
        {
          "lon": -76.9766365205678,
          "lat": -12.124766025628,
          "address": "AV. CRISTOBAL PERALTA SUR - AV. DE LOS INGENIEROS"
        },
        {
          "lon": -76.9915921429039,
          "lat": -12.149955151672,
          "address": "AV. EL CARMEN - AV. COHETE"
        },
        {
          "lon": -76.9706624812491,
          "lat": -12.0988346006515,
          "address": "Av. El Polo - Av. El Derby"
        },
        {
          "lon": -76.9681159837025,
          "lat": -12.0951772924888,
          "address": "Av. El Polo - Pio XII"
        },
        {
          "lon": -76.9675120253601,
          "lat": -12.0937155494336,
          "address": "Av. El Polo - Av. Raúl Ferrero"
        },
        {
          "lon": -76.9721563416188,
          "lat": -12.1024417471188,
          "address": "Av. El Polo - Av. Padre Luis Tezza"
        },
        {
          "lon": -76.9712300707959,
          "lat": -12.1000682152703,
          "address": "Av. El Polo - Jr. La Conquista"
        },
        {
          "lon": -76.990809134726,
          "lat": -12.1632262847933,
          "address": "Av. Guardia Civil Norte - Jr. Luis Dextre"
        },
        {
          "lon": -76.992092791989,
          "lat": -12.1654779960702,
          "address": "Av. Guardia Civil Norte - Av. Vista Alegre"
        },
        {
          "lon": -76.9905186557024,
          "lat": -12.1664513081219,
          "address": "Av. Guardia Civil Sur - Av. Vista Alegre"
        },
        {
          "lon": -76.9751367679769,
          "lat": -12.1197670514028,
          "address": "Av. Jacaranda - Ca. Las Gardenias"
        },
        {
          "lon": -76.9760696570299,
          "lat": -12.1143057370177,
          "address": "Av. Jerónimo de Aliaga Sur - Ca. Alonso de Molina"
        },
        {
          "lon": -76.9691371339262,
          "lat": -12.0990658808309,
          "address": "Av. La Encalada - Av. El Derby"
        },
        {
          "lon": -76.9696655812945,
          "lat": -12.0974504404216,
          "address": "Av. La Encalada - Av. El Cortijo"
        },
        {
          "lon": -76.9706658952425,
          "lat": -12.1030078753996,
          "address": "Av. La Encalada - Ca. Padre Luis Tezza"
        },
        {
          "lon": -76.9708267577783,
          "lat": -12.1049340957828,
          "address": "Av. La Encalada - Ca. Cayalti"
        },
        {
          "lon": -76.9704176373015,
          "lat": -12.1038910886724,
          "address": "Av. La Encalada - Ca. C (Aldebaran)"
        },
        {
          "lon": -76.9767259744904,
          "lat": -12.1314507940493,
          "address": "Av. La Grevillea - Av. La Rosa Lozano"
        },
        {
          "lon": -77.0096942045029,
          "lat": -12.1346683341335,
          "address": "Av. La Merced - Av. Mariscal Castilla"
        },
        {
          "lon": -77.0093821418031,
          "lat": -12.1304359773671,
          "address": "Av. La Merced - Av. Simón Salguero"
        },
        {
          "lon": -76.9767761541021,
          "lat": -12.1297316498474,
          "address": "Av. La Rosa Lozano - ca. cerro lindo"
        },
        {
          "lon": -77.0124483566379,
          "lat": -12.1404668189855,
          "address": "Av. Lima - Jr. Catalino Miranda"
        },
        {
          "lon": -76.9896650005019,
          "lat": -12.1528956556339,
          "address": "Av. Los Próceres - AV. PASEO DE LA REPUBLICA"
        },
        {
          "lon": -76.9884483994625,
          "lat": -12.1508570873805,
          "address": "Av. Los Próceres - Altura ESSALUD"
        },
        {
          "lon": -76.9733117082546,
          "lat": -12.0980158370379,
          "address": "Av. Manuel Olguin - Av. El Derby"
        },
        {
          "lon": -76.9737649422828,
          "lat": -12.0909258094104,
          "address": "Av. Manuel Olguin - Ca. Tres Marías"
        },
        {
          "lon": -76.9736208333009,
          "lat": -12.089275885752,
          "address": "Av. Manuel Olguin - Ca. Orión"
        },
        {
          "lon": -76.9861961719449,
          "lat": -12.1320498661296,
          "address": "Av. Monte de los Olivos - Av. Las Nazarenas"
        },
        {
          "lon": -76.9975227998049,
          "lat": -12.119913272073,
          "address": "Av. Pedro Venturo - Av. Intihuatana"
        },
        {
          "lon": -76.9904372966655,
          "lat": -12.124098277818,
          "address": "Av. Pedro Venturo - Av. Higuereta"
        },
        {
          "lon": -76.9636082608494,
          "lat": -12.10379005456,
          "address": "AV. PRIMAVERA - UPC"
        },
        {
          "lon": -76.9844907362793,
          "lat": -12.1106645674883,
          "address": "Av. Primavera - AV. VELASCO ASTETE"
        },
        {
          "lon": -76.9727644282891,
          "lat": -12.1098367453401,
          "address": "Av. Primavera - Av. La Encalada"
        },
        {
          "lon": -76.9806802262996,
          "lat": -12.1103807891431,
          "address": "Av. Primavera - Av. Buena Vista"
        },
        {
          "lon": -76.9750061480649,
          "lat": -12.1099497800011,
          "address": "Av. Primavera - Av. El Polo"
        },
        {
          "lon": -76.9918840123701,
          "lat": -12.1112986730017,
          "address": "Av. Primavera - Av. Monte Bello"
        },
        {
          "lon": -76.9694802215804,
          "lat": -12.1080787044513,
          "address": "Av. Primavera - Ca. Tomasal"
        },
        {
          "lon": -76.9905561540414,
          "lat": -12.1556957495738,
          "address": "AV. PROCERES - Ingreso a la Cruceta"
        },
        {
          "lon": -77.0117579788983,
          "lat": -12.1470861665592,
          "address": "Av. Roosevelt - AV. JORGE CHAVEZ"
        },
        {
          "lon": -77.0090494810578,
          "lat": -12.1466160810373,
          "address": "Av. Roosevelt - Jr. San Pedrito"
        },
        {
          "lon": -76.9880825013578,
          "lat": -12.1428335429016,
          "address": "AV. TINOCO - AV. MONTE DE LOS OLIVOS"
        },
        {
          "lon": -76.9888378870677,
          "lat": -12.1310050692559,
          "address": "AV. VELASCO ASTETE - Av. Las Nazarenas"
        },
        {
          "lon": -76.9899808752511,
          "lat": -12.1341629774798,
          "address": "AV. VELASCO ASTETE - Jr. Ismael Bielich"
        },
        {
          "lon": -76.9741784553945,
          "lat": -12.1130597226557,
          "address": "Jr. Alonso de Molina - Av. La Encalada"
        },
        {
          "lon": -76.9875394103034,
          "lat": -12.1397534713932,
          "address": "Jr. Loma Umbrosa - AV. MONTE DE LOS OLIVOS"
        },
        {
          "lon": -76.9948430331942,
          "lat": -12.1463348431976,
          "address": "Jr. Soto Bermeo - Ca. Alfredo Icaza"
        },
        {
          "lon": -77.0003054268496,
          "lat": -12.1117422517574,
          "address": "AV. ANGAMOS - AV. AVIACION"
        },
        {
          "lon": -77.0072266929163,
          "lat": -12.1121302111558,
          "address": "AV. ANGAMOS - AV. PRINCIPAL"
        },
        {
          "lon": -77.0122077281914,
          "lat": -12.1125216903421,
          "address": "AV. ANGAMOS - AV. TOMAS MARSANO"
        },
        {
          "lon": -77.0034017338803,
          "lat": -12.1118765586497,
          "address": "AV. ANGAMOS - AV.R.MALACHOWSKY"
        },
        {
          "lon": -77.0163337690205,
          "lat": -12.112833959066,
          "address": "AV. ANGAMOS - CA. SAN FELIPE"
        },
        {
          "lon": -77.0184378177609,
          "lat": -12.1129647394134,
          "address": "AV. ANGAMOS - AV. REPUBLICA DE PANAMA"
        },
        {
          "lon": -77.0233693013302,
          "lat": -12.1133062560274,
          "address": "AV. ANGAMOS - CA. DANTE"
        },
        {
          "lon": -77.0234831240075,
          "lat": -12.1024307450733,
          "address": "AV. ARAMBURU - CA. LOS CISNES"
        },
        {
          "lon": -77.0227788775628,
          "lat": -12.1072593540844,
          "address": "AV. DOMINGO ORUE - JR. IRRIBARREN"
        },
        {
          "lon": -77.0036441360411,
          "lat": -12.1207742882617,
          "address": "AV. PRINCIPAL - AV. VILLARAN"
        },
        {
          "lon": -77.026051548993,
          "lat": -12.1092642725871,
          "address": "AV. REPUBLICA DE PANAMA - JR. CARLOS A. SALAVERRY"
        },
        {
          "lon": -77.0168851286198,
          "lat": -12.1064458019733,
          "address": "AV. TOMAS MARSANO - CA. SAN LORENZO"
        },
        {
          "lon": -76.9418798840094,
          "lat": -12.1992385561979,
          "address": "AV. CENTRAL - AV. EL SOL"
        },
        {
          "lon": -76.9464573873039,
          "lat": -12.1917129663416,
          "address": "AV. CENTRAL - AV. 1ERO DE MAYO"
        },
        {
          "lon": -76.934274991703,
          "lat": -12.2119326573906,
          "address": "AV. CENTRAL - AV. CESAR VALLEJO"
        },
        {
          "lon": -76.9373581588602,
          "lat": -12.2068270283361,
          "address": "AV. CENTRAL - AV. J. VELASCO ALVARADO"
        },
        {
          "lon": -76.96800034632,
          "lat": -12.1931360165361,
          "address": "AV. MATEO PUMACAHUA PUERTA 2 - PUERTA 2 PZ. HUAYNA CAPAC"
        },
        {
          "lon": -76.9492376318149,
          "lat": -12.2035347135724,
          "address": "AV. MICAELA BASTIDAS - AV. EL SOL"
        },
        {
          "lon": -76.9537403949749,
          "lat": -12.1959408862904,
          "address": "AV. MICAELA BASTIDAS - AV. 1RO DE MAYO"
        },
        {
          "lon": -76.9415963277464,
          "lat": -12.2161798090924,
          "address": "AV. MICAELA BASTIDAS - AV. CESAR VALLEJO"
        },
        {
          "lon": -76.9446937162374,
          "lat": -12.2110734432674,
          "address": "AV. MICAELA BASTIDAS - AV. J. VELASCO ALVARADO"
        },
        {
          "lon": -76.953030136395,
          "lat": -12.2057429152619,
          "address": "AV. PASTOR SEVILLA - AV. EL SOL"
        },
        {
          "lon": -76.9608446473165,
          "lat": -12.1927588090878,
          "address": "AV. PASTOR SEVILLA - HOSPITAL DE SOLIDARIDAD"
        },
        {
          "lon": -76.9575832501263,
          "lat": -12.1981854789783,
          "address": "AV. PASTOR SEVILLA - AV. 1ERO DE MAYO"
        },
        {
          "lon": -76.9454818151107,
          "lat": -12.2184647761246,
          "address": "AV. PASTOR SEVILLA - AV. CESAR VALLEJO"
        },
        {
          "lon": -76.9485455374052,
          "lat": -12.213363320295,
          "address": "AV. PASTOR SEVILLA - AV. J. VELASCO ALVARADO"
        },
        {
          "lon": -76.9590617211329,
          "lat": -12.1955330847817,
          "address": "AV. PASTOR SEVILLA - AV. MODELO"
        },
        {
          "lon": -76.9443262308165,
          "lat": -12.20067880904,
          "address": "AV. REVOLUCION - AV. EL SOL"
        },
        {
          "lon": -76.9366950773854,
          "lat": -12.2133721567238,
          "address": "AV. REVOLUCION - AV. CESAR VALLEJO"
        },
        {
          "lon": -76.9398073956373,
          "lat": -12.2082536679576,
          "address": "AV. REVOLUCION - AV. J. VELASCO ALVARADO"
        },
        {
          "lon": -76.9355012367539,
          "lat": -12.1746047347212,
          "address": "AV. 26 DE NOVIEMBRE - JR. MADRE DE DIOS"
        },
        {
          "lon": -76.956114356112,
          "lat": -12.1594909342975,
          "address": "AV. SALVADOR ALLENDE - AV. JOSE CARLOS MARIATEGUI"
        },
        {
          "lon": -76.9504008481202,
          "lat": -12.1661497282977,
          "address": "AV. PIEROLA - AV. VILLA MARIA"
        },
        {
          "lon": -76.9327594101439,
          "lat": -12.1725882798924,
          "address": "AV. RAMIRO MERINO - AV. 26 DE NOVIEMBRE"
        },
        {
          "lon": -76.9606501747538,
          "lat": -12.1557770664287,
          "address": "AV. SALVADOR ALLENDE - AV. JULIO C. TELLO"
        },
        {
          "lon": -76.9496987144046,
          "lat": -12.1669305304438,
          "address": "AV. SALVADOR ALLENDE - AV. TRIUNFO"
        },
        {
          "lon": -76.939496483137,
          "lat": -12.1774957832273,
          "address": "AV. SALVADOR ALLENDE - AV. 26 DE NOVIEMBRE"
        },
        {
          "lon": -77.065979588724,
          "lat": -12.0383526284129,
          "address": "AV. NICOLAS DUEÑAS - AV. ENRIQUE MEIGGS"
        },
        {
          "lon": -77.0657698390296,
          "lat": -12.0408041246026,
          "address": "AV. NICOLAS DUEÑAS - AV. MATERIALES - JR. PEDRO GAREZON"
        },
        {
          "lon": -77.0658301357976,
          "lat": -12.0354548904695,
          "address": "AV. NICOLAS DUEÑAS - AV. MORALES DUAREZ"
        },
        {
          "lon": -77.0864314291675,
          "lat": -12.051436926211,
          "address": "AV. OSCAR R. BENAVIDES - AV. ENRIQUE MEIGGS"
        },
        {
          "lon": -77.0853328088702,
          "lat": -12.0513870649878,
          "address": "AV. OSCAR R. BENAVIDES - AV. GERMAN AMEZAGA"
        },
        {
          "lon": -76.9199952692017,
          "lat": -12.1675301643055,
          "address": "JR. SAN PEDRO - AV. 26 DE NOVIEMBRE"
        },
        {
          "lon": -76.9462708366528,
          "lat": -12.1709183491639,
          "address": "AV. PIEROLA - AV. PARADO DE BELLIDO"
        },
        {
          "lon": -77.0724591204064,
          "lat": -11.9285674381016,
          "address": "TODO ARRIBA"
        },
        {
          "lon": -77.0637563058496,
          "lat": -11.9148384010592,
          "address": "IZQUIERDA"
        },
        {
          "lon": -77.0566005388944,
          "lat": -11.8988678667869,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -77.0491962603348,
          "lat": -11.8806402928454,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -77.0457141895793,
          "lat": -11.8621258741254,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -77.0807976073679,
          "lat": -11.9200388178603,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -77.0814239817737,
          "lat": -11.8996544719207,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -76.9252087570934,
          "lat": -12.0200256269883,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -76.9214639363645,
          "lat": -12.0225746420786,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -76.9190854864126,
          "lat": -12.0253710731538,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -76.9169090555896,
          "lat": -12.0281235633927,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -76.9144926455502,
          "lat": -12.0307086550353,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -76.9122309000674,
          "lat": -12.0331334100646,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -76.9099107388745,
          "lat": -12.03594962349,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -76.9035053396583,
          "lat": -12.0333937672999,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -76.919840188312,
          "lat": -12.0105661782649,
          "address": "Marca de posición sin título"
        },
        {
          "lon": -77.002457249344,
          "lat": -12.0628990494306,
          "address": "AV. MEXICO - AV. NICOLAS AYLLON"
        },
        {
          "lon": -77.0282990627609,
          "lat": -12.0701713644396,
          "address": "AV. ISABEL LA CATOLICA - AV. MANCO CAPAC"
        },
        {
          "lon": -77.0299273375305,
          "lat": -12.0703831146351,
          "address": "AV. ISABEL LA CATOLICA - AV. IQUITOS"
        },
        {
          "lon": -77.0297727893887,
          "lat": -12.0714751757014,
          "address": "AV. IQUITOS - JR. ITALIA"
        },
        {
          "lon": -77.0303246638049,
          "lat": -12.0674172241186,
          "address": "AV. IQUITOS - AV. HIPOLITO HUNANUE"
        },
        {
          "lon": -77.0304984225069,
          "lat": -12.0660881870236,
          "address": "AV. IQUITOS - JR. HUMBOLT"
        },
        {
          "lon": -77.0289226831662,
          "lat": -12.0658931811179,
          "address": "AV. MANCO CAPAC - JR. HUMBOLDT"
        },
        {
          "lon": -77.0282324096361,
          "lat": -12.0712426249383,
          "address": "AV. MANCO CAPAC - JR. ITALIA"
        },
        {
          "lon": -77.0334985336603,
          "lat": -12.0906078353551,
          "address": "AV. AREQUIPA - AV. SOLEDAD"
        },
        {
          "lon": -76.9826778130061,
          "lat": -12.063263010189,
          "address": "AV. NICOLAS AYLLON - PASAJE DEL BOSQUE"
        },
        {
          "lon": -76.9689954980824,
          "lat": -12.0565382910676,
          "address": "AV. NICOLAS AYLLON - AV. FRANCISCO BOLOGNESI"
        },
        {
          "lon": -76.9713280642889,
          "lat": -12.0574223591632,
          "address": "AV. NICOLAS AYLLON - PSJ. SANTA ANITA"
        },
        {
          "lon": -76.989533098565,
          "lat": -12.0766209697081,
          "address": "AV. CIRCUNVALACION - AV. AYMARAS"
        },
        {
          "lon": -77.0251817337532,
          "lat": -12.044914723427,
          "address": "AV. ABANCAY - JR. AMAZONAS"
        },
        {
          "lon": -77.0260631608685,
          "lat": -12.0466266510015,
          "address": "AV. ABANCAY - JR. ANCASH"
        },
        {
          "lon": -77.026624135205,
          "lat": -12.0477091334245,
          "address": "AV. ABANCAY - JR. JUNIN"
        },
        {
          "lon": -77.0271639479075,
          "lat": -12.0487733948731,
          "address": "AV. ABANCAY - JR. HUALLAGA"
        },
        {
          "lon": -77.0437494584902,
          "lat": -12.0627760577543,
          "address": "AV. BRASIL - JR. DON BOSCO"
        },
        {
          "lon": -77.0240402270482,
          "lat": -12.0429251312259,
          "address": "AV. 9 DE OCTUBRE - AV. ABANCAY"
        },
        {
          "lon": -77.0256790864297,
          "lat": -12.0421680777863,
          "address": "AV. 9 DE OCTUBRE - MARAÑON - PATAZ"
        },
        {
          "lon": -77.0171798288267,
          "lat": -12.0398572044281,
          "address": "AV. 9 DE OCTUBRE - MERCADO LAS FLORES"
        },
        {
          "lon": -77.023104983259,
          "lat": -12.0427632749931,
          "address": "AV. 9 DE OCTUBRE - PUENTE BALTA"
        },
        {
          "lon": -77.0303083844123,
          "lat": -12.0544879683922,
          "address": "AV. ABANCAY - AV. NICOLAS DE PIEROLA"
        },
        {
          "lon": -77.0287305066145,
          "lat": -12.0517925468868,
          "address": "AV. ABANCAY - JR. CUSCO"
        },
        {
          "lon": -77.0301669023863,
          "lat": -12.0566555883592,
          "address": "AV. ABANCAY - JR. LETICIA"
        },
        {
          "lon": -77.0282402205442,
          "lat": -12.0508183049038,
          "address": "AV. ABANCAY - JR. MIROQUESADA"
        },
        {
          "lon": -77.0300190695148,
          "lat": -12.0578159829215,
          "address": "AV. ABANCAY - JR. MONTEVIVEO"
        },
        {
          "lon": -77.0293502697714,
          "lat": -12.0528696167152,
          "address": "AV. ABANCAY - JR. PUNO"
        },
        {
          "lon": -77.0277601909487,
          "lat": -12.0497662485891,
          "address": "AV. ABANCAY - JR. UCAYALI"
        },
        {
          "lon": -77.0367616230308,
          "lat": -12.0768160566016,
          "address": "AV. ARENALES - JR. ENRIQUE VILLAR"
        },
        {
          "lon": -77.0370740864599,
          "lat": -12.0746965670029,
          "address": "AV. ARENALES - AV. ALEJANDRO TIRADO"
        },
        {
          "lon": -77.0372294406603,
          "lat": -12.0736161599543,
          "address": "AV. ARENALES - CA. EMILIO FERNANDEZ"
        },
        {
          "lon": -77.037805001349,
          "lat": -12.0698024502747,
          "address": "AV. ARENALES - CA. LARRABURE Y UNANUE"
        },
        {
          "lon": -77.0369108368742,
          "lat": -12.0757759629475,
          "address": "AV. ARENALES - CA. MARIANO CARRANZA"
        },
        {
          "lon": -77.036604551723,
          "lat": -12.0778942317878,
          "address": "AV. ARENALES - CA. TEODORO CARDENAS"
        },
        {
          "lon": -77.0373949470003,
          "lat": -12.0726002628753,
          "address": "AV. ARENALES - JR. PABLO BERMUDEZ"
        },
        {
          "lon": -77.0380495186344,
          "lat": -12.0671783603495,
          "address": "AV. ARENALES - JR. MANUEL NICOLAS CORPANCHO"
        },
        {
          "lon": -77.0373730702738,
          "lat": -12.0646364233887,
          "address": "AV. AREQUIPA - AV. 28 DE JULIO"
        },
        {
          "lon": -77.0357487306306,
          "lat": -12.0755936187228,
          "address": "AV. AREQUIPA - CA. MARIANO CARRANZA"
        },
        {
          "lon": -77.0370864872703,
          "lat": -12.0670286777055,
          "address": "AV. AREQUIPA - JR. MANUEL CORPANCHO"
        },
        {
          "lon": -77.0368887471691,
          "lat": -12.0683436700904,
          "address": "AV. AREQUIPA - JR. SACO OLIVEROS"
        },
        {
          "lon": -77.0359327008389,
          "lat": -12.0745477152428,
          "address": "AV. AREQUIPA - AV. ALEJANDRO TIRADO"
        },
        {
          "lon": -77.0360882790342,
          "lat": -12.0734620044589,
          "address": "AV. AREQUIPA - CA. EMILIO FERNANDEZ"
        },
        {
          "lon": -77.0355932913677,
          "lat": -12.0766842952113,
          "address": "AV. AREQUIPA - CA. ENRIQUE VILLAR"
        },
        {
          "lon": -77.0354410052608,
          "lat": -12.0777363270928,
          "address": "AV. AREQUIPA - CA. TEODORO CARDENAS"
        },
        {
          "lon": -77.0362849512481,
          "lat": -12.0724075987161,
          "address": "AV. AREQUIPA - JR. PABLO BERMUDEZ"
        },
        {
          "lon": -77.0372190440794,
          "lat": -12.0558162144771,
          "address": "AV. BOLIVIA - JR. JACINTO LOPEZ"
        },
        {
          "lon": -77.0382278044408,
          "lat": -12.0559184902158,
          "address": "AV. GARCILASO DE LA VEGA - AV. BOLIVIA"
        },
        {
          "lon": -77.0387355954229,
          "lat": -12.0510395996161,
          "address": "AV. GARCILASO DE LA VEGA - JR. QUILCA"
        },
        {
          "lon": -77.0383668364655,
          "lat": -12.0541647188341,
          "address": "AV. GARCILASO DE LA VEGA - AV. URUGUAY"
        },
        {
          "lon": -77.0378963089433,
          "lat": -12.0599694014662,
          "address": "AV. GARCILASO DE LA VEGA - AV. 9 DE DICIEMBRE"
        },
        {
          "lon": -77.0134493993817,
          "lat": -12.0550551615294,
          "address": "AV. GRAU - AV. NICOLAS DE AYLLON"
        },
        {
          "lon": -77.0147956859487,
          "lat": -12.0566809014118,
          "address": "AV.GRAU - AV. AVIACION"
        },
        {
          "lon": -77.0243533533637,
          "lat": -12.0583677901154,
          "address": "AV.GRAU - AV. NICOLAS DE PIEROLA"
        },
        {
          "lon": -77.0298680599346,
          "lat": -12.0590635070856,
          "address": "AV. GRAU - AV. ABANCAY"
        },
        {
          "lon": -77.0314326703291,
          "lat": -12.0593142386825,
          "address": "AV. GRAU - AV. IQUITOS"
        },
        {
          "lon": -77.0346500491137,
          "lat": -12.0595686660604,
          "address": "AV. GRAU - JR. COTABAMBAS"
        },
        {
          "lon": -77.0594067798223,
          "lat": -12.0486964323033,
          "address": "AV. NACIONES UNIDAS - JR. ZORRITOS"
        },
        {
          "lon": -77.0069777725561,
          "lat": -12.0602680934439,
          "address": "AV. NICOLAS AYLLON - AV. 28 DE JULIO"
        },
        {
          "lon": -77.0078485732376,
          "lat": -12.0593507586504,
          "address": "AV. NICOLAS AYLLON - AV. JOSE DE LA RIVA AGÜERO"
        },
        {
          "lon": -77.0105208429737,
          "lat": -12.0572786118552,
          "address": "AV. NICOLAS AYLLON - JR. GARCIA NARANJO"
        },
        {
          "lon": -77.0363165866859,
          "lat": -12.0643609054006,
          "address": "AV. PETIT THOUARS - AV. 28 DE JULIO"
        },
        {
          "lon": -77.0348137203441,
          "lat": -12.0743776644251,
          "address": "AV. PETIT THOUARS - AV. ALEJANDRO TIRADO"
        },
        {
          "lon": -77.0349961235649,
          "lat": -12.0732856445474,
          "address": "AV. PETIT THOUARS - CA. EMILIO FERNANDEZ"
        },
        {
          "lon": -77.0344977664125,
          "lat": -12.0765007917086,
          "address": "AV. PETIT THOUARS - CA. ENRIQUE VILLAR"
        },
        {
          "lon": -77.03595269941,
          "lat": -12.0668526658812,
          "address": "AV. PETIT THOUARS - AV. NICOLAS CORPANCHO"
        },
        {
          "lon": -77.0343310528542,
          "lat": -12.0775750540946,
          "address": "AV. PETIT THOUARS - CA. TEODORO CARDENAS"
        },
        {
          "lon": -77.0357392206092,
          "lat": -12.0681612331041,
          "address": "AV. PETIT THOUARS - JR. SACO OLIVEROS"
        },
        {
          "lon": -77.0346439488242,
          "lat": -12.0754316752322,
          "address": "AV. PETIT THOUARS - CA. MARIANO CARRANZA"
        },
        {
          "lon": -77.0381672646421,
          "lat": -12.0473522798956,
          "address": "AV. TACNA - JR. MOQUEGUA"
        },
        {
          "lon": -77.0391176794033,
          "lat": -12.0486650551965,
          "address": "AV. TACNA - AV. NICOLAS DE PIEROLA"
        },
        {
          "lon": -77.0355701788693,
          "lat": -12.0435299436856,
          "address": "AV. TACNA - JR. CALLAO"
        },
        {
          "lon": -77.0348666922998,
          "lat": -12.0425766201771,
          "address": "AV. TACNA - JR. CONDE DE SUPERUNDA"
        },
        {
          "lon": -77.0368739148581,
          "lat": -12.0455036171186,
          "address": "AV. TACNA - JR. HUANCAVELICA"
        },
        {
          "lon": -77.0361761167874,
          "lat": -12.0444623136756,
          "address": "AV. TACNA - JR. ICA"
        },
        {
          "lon": -77.0374181499945,
          "lat": -12.0540687980813,
          "address": "AV. URUGUAY - JR. JACINTO LOPEZ"
        },
        {
          "lon": -77.036160990765,
          "lat": -12.0538601140418,
          "address": "AV. URUGUAY - JR. DE LA UNION - JR. PACHITEA"
        },
        {
          "lon": -77.0277171810624,
          "lat": -12.0470668312453,
          "address": "JR. AZANGARO - JR. JUNIN"
        },
        {
          "lon": -77.0297650647847,
          "lat": -12.049872021022,
          "address": "JR. AZANGARO - JR. MIROQUESADA"
        },
        {
          "lon": -77.0310587422824,
          "lat": -12.0517912814955,
          "address": "JR. AZANGARO - JR. PUNO"
        },
        {
          "lon": -77.029093322473,
          "lat": -12.0489369064601,
          "address": "JR. AZANGARO - JR. UCAYALI"
        },
        {
          "lon": -77.0336687382273,
          "lat": -12.0555105078396,
          "address": "JR. AZANGARO - AV. ROOSVELT"
        },
        {
          "lon": -77.0303628323249,
          "lat": -12.0507807696798,
          "address": "JR. AZANGARO - JR. CUSCO"
        },
        {
          "lon": -77.028405753819,
          "lat": -12.047999571586,
          "address": "JR. AZANGARO - JR. HUALLAGA"
        },
        {
          "lon": -77.0333742323074,
          "lat": -12.0448894770926,
          "address": "JR. CALLAO - JR. CAILLOMA"
        },
        {
          "lon": -77.0323597497649,
          "lat": -12.0455448350051,
          "address": "JR. CALLAO - JR. CAMANÁ"
        },
        {
          "lon": -77.0343361788661,
          "lat": -12.044275045464,
          "address": "JR. CALLAO - JR. RUFINO TORRICO"
        },
        {
          "lon": -77.0297404684551,
          "lat": -12.0458303277172,
          "address": "JR. CARABAYA - JR. JUNIN"
        },
        {
          "lon": -77.0353058201233,
          "lat": -12.054175,
          "address": "JR. CARABAYA - JR. PACHITEA"
        },
        {
          "lon": -77.0317415296004,
          "lat": -12.0486919979055,
          "address": "JR. CARABAYA - JR. MIROQUESADA"
        },
        {
          "lon": -77.0330523092796,
          "lat": -12.050530767278,
          "address": "JR. CARABAYA - JR. PUNO"
        },
        {
          "lon": -77.0357726581395,
          "lat": -12.0557042383786,
          "address": "JR. CARABAYA - AV. ROOSVELT"
        },
        {
          "lon": -77.030382358394,
          "lat": -12.0467236072945,
          "address": "JR. CARABAYA - JR. HUALLAGA"
        },
        {
          "lon": -77.0316960340935,
          "lat": -12.0445754339676,
          "address": "JR. CONDE DE SUPERUNDA - JR. CAMANÁ"
        },
        {
          "lon": -77.0336425345164,
          "lat": -12.0433537323694,
          "address": "JR. CONDE DE SUPERUNDA - JR. RUFINO TORRICO"
        },
        {
          "lon": -77.0366237749057,
          "lat": -12.0556980705381,
          "address": "JR. DE LA UNION - AV. BOLIVIA"
        },
        {
          "lon": -77.0320462704426,
          "lat": -12.047074725024,
          "address": "JR. DE LA UNION - JR. UCAYALI"
        },
        {
          "lon": -77.0313727008881,
          "lat": -12.046156990735,
          "address": "JR. DE LA UNION - JR. CALLAO"
        },
        {
          "lon": -77.0307219051005,
          "lat": -12.0452092003223,
          "address": "JR. DE LA UNION - JR. CONDE SUPERUNDA"
        },
        {
          "lon": -77.034041611704,
          "lat": -12.0499191333931,
          "address": "JR. DE LA UNION - JR. PUNO"
        },
        {
          "lon": -77.0327738885217,
          "lat": -12.0481128108909,
          "address": "JR. DE LA UNION - JR. HUANCAVELICA"
        },
        {
          "lon": -77.0337540240318,
          "lat": -12.0474845002184,
          "address": "JR. HUANCAVELICA - JR. CAMANÁ"
        },
        {
          "lon": -77.0347754023753,
          "lat": -12.0467847992046,
          "address": "JR. HUANCAVELICA - JR. CAILLOMA"
        },
        {
          "lon": -77.0357780531562,
          "lat": -12.0461671258878,
          "address": "JR. HUANCAVELICA - JR. RUFINO TORRICO"
        },
        {
          "lon": -77.0340460598034,
          "lat": -12.0458125006958,
          "address": "JR. ICA - JR. CAILLOMA"
        },
        {
          "lon": -77.0330074114828,
          "lat": -12.0464656436102,
          "address": "JR. ICA - JR. CAMANÁ"
        },
        {
          "lon": -77.0350245480735,
          "lat": -12.0451814266878,
          "address": "JR. ICA - JR. RUFINO TORRICO"
        },
        {
          "lon": -77.028019325884,
          "lat": -12.0454940517268,
          "address": "JR. LAMPA - JR. ANCASH"
        },
        {
          "lon": -77.0287263019768,
          "lat": -12.0464433740207,
          "address": "JR. LAMPA - JR. JUNIN"
        },
        {
          "lon": -77.0300710401924,
          "lat": -12.048324396899,
          "address": "JR. LAMPA - JR. UCAYALI"
        },
        {
          "lon": -77.0307429201194,
          "lat": -12.0492663667259,
          "address": "JR. LAMPA - JR. MIROQUESADA"
        },
        {
          "lon": -77.0293872401128,
          "lat": -12.0473651141907,
          "address": "JR. LAMPA - JR. HUALLAGA"
        },
        {
          "lon": -77.0361366527258,
          "lat": -12.0486174413578,
          "address": "JR. MOQUEGUA - JR. CAILLOMA"
        },
        {
          "lon": -77.035070327222,
          "lat": -12.0492620995432,
          "address": "JR. MOQUEGUA - JR. CAMANÁ"
        },
        {
          "lon": -77.0371454239322,
          "lat": -12.0479817308782,
          "address": "JR. MOQUEGUA - JR. RUFINO TORRICO"
        },
        {
          "lon": -77.03327539538,
          "lat": -12.0549123628569,
          "address": "JR. PACHITEA - JR. AZÁNGARO"
        },
        {
          "lon": -77.0310567771668,
          "lat": -12.0476906869386,
          "address": "JR. CARABAYA - JR. UCAYALI"
        },
        {
          "lon": -77.0268838997786,
          "lat": -12.0502949349921,
          "address": "JR. UCAYALI - JR. AYACUCHO"
        },
        {
          "lon": -77.0202837753352,
          "lat": -12.0414280071162,
          "address": "AV. 9 DE OCTUBRE - PUENTE HUANUCO"
        },
        {
          "lon": -77.042906937644,
          "lat": -12.0298912293652,
          "address": "AV. FRANCISCO PIZARRO - AV. MORRO DE ARICA"
        },
        {
          "lon": -77.0386459029775,
          "lat": -12.0338199195001,
          "address": "AV. FRANCISCO PIZARRO - AV. FELIPE ARANCIBIA"
        },
        {
          "lon": -77.0350429917589,
          "lat": -12.036667007522,
          "address": "AV. FRANCISCO PIZARRO - JR. VILLACAMPA"
        },
        {
          "lon": -77.0363932807637,
          "lat": -12.0373165534078,
          "address": "AV. LOS PROCERES - CA. EL AGUILA"
        },
        {
          "lon": -77.0416410007099,
          "lat": -12.0326936397051,
          "address": "AV. LOS PROCERES - AV. DIEGO CORDOVA DE SALINAS"
        },
        {
          "lon": -77.0394815149606,
          "lat": -12.0346351667341,
          "address": "AV. LOS PROCERES - JR. GRAL. VIDAL"
        },
        {
          "lon": -77.029714947479,
          "lat": -12.034873311759,
          "address": "AV. PROLONGACION TACNA - AV. GUARDIA REPUBLICANA"
        },
        {
          "lon": -77.0309579444902,
          "lat": -12.0367742065821,
          "address": "AV. PROLONGACION TACNA - JR. CHIRA"
        },
        {
          "lon": -77.0322588169553,
          "lat": -12.0387899491621,
          "address": "AV. PROLONGACION TACNA - AV. FRANCISCO PIZARRO"
        },
        {
          "lon": -77.0329569763186,
          "lat": -12.0398072423318,
          "address": "AV. PROLONGACION TACNA - JR. VIRU (PROCERES)"
        },
        {
          "lon": -77.0283735901702,
          "lat": -12.0401323005481,
          "address": "JR. CAJAMARCA - JR. CHICLAYO"
        },
        {
          "lon": -77.0242082678615,
          "lat": -12.0403784116863,
          "address": "JR. CAJAMARCA - JR. HUALGAYOC"
        },
        {
          "lon": -77.0254818513422,
          "lat": -12.0413539396665,
          "address": "JR. GARCIA HURTADO DE MENDOZA - JR. PATAZ"
        },
        {
          "lon": -77.0293251790072,
          "lat": -12.0400508546598,
          "address": "JR. TRUJILLO - JR. CAJAMARCA"
        },
        {
          "lon": -77.0284474825813,
          "lat": -12.0409796269483,
          "address": "JR.VIRU (JR. GARCIA HURTADO DE MENDOZA) - JR. CHICLAYO"
        },
        {
          "lon": -77.029395757554,
          "lat": -12.0408864468934,
          "address": "JR. TRUJILLO - JR. VIRU"
        },
        {
          "lon": -77.0255200131264,
          "lat": -12.0583758859244,
          "address": "AV. GRAU - JR. PARURO"
        },
        {
          "lon": -77.0308880034441,
          "lat": -12.0633789460893,
          "address": "AV. 28 DE JULIO - AV. IQUITOS"
        },
        {
          "lon": -77.0293049408146,
          "lat": -12.0631626888877,
          "address": "AV. 28 DE JULIO - AV. MANCO CAPAC"
        },
        {
          "lon": -77.0307043790028,
          "lat": -12.0647422545987,
          "address": "AV. BAUZATE Y MEZA - AV. IQUITOS"
        },
        {
          "lon": -77.0291383574223,
          "lat": -12.0645418810396,
          "address": "AV. BAUZATE Y MEZA - AV. MANCO CAPAC"
        },
        {
          "lon": -77.0231795035132,
          "lat": -12.0584014813848,
          "address": "AV. GRAU - JR. ABTAO"
        },
        {
          "lon": -77.0266979532987,
          "lat": -12.0587168596686,
          "address": "AV. GRAU - JR. ANDAHUAYLAS"
        },
        {
          "lon": -77.0284191464358,
          "lat": -12.0587453020187,
          "address": "AV. GRAU - JR. AYACUCHO"
        },
        {
          "lon": -77.0217402270723,
          "lat": -12.0581667702723,
          "address": "AV. GRAU - JR. CANGALLO"
        },
        {
          "lon": -77.0174200596964,
          "lat": -12.0575166063643,
          "address": "AV. GRAU - JR. HUANUCO"
        },
        {
          "lon": -77.0194771447292,
          "lat": -12.0576034454657,
          "address": "AV. GRAU - JR. LUCANAS"
        },
        {
          "lon": -77.0184824427999,
          "lat": -12.0577858837587,
          "address": "AV. GRAU - JR. PARINACOCHAS"
        },
        {
          "lon": -77.0310707793539,
          "lat": -12.062021804237,
          "address": "AV. IQUITOS - JR. GARCIA NARANJO"
        },
        {
          "lon": -77.0312444254202,
          "lat": -12.0607312990293,
          "address": "AV. IQUITOS - JR. RAYMONDI"
        },
        {
          "lon": -77.0296482579775,
          "lat": -12.0605061601743,
          "address": "AV. MANCO CAPAC - JR. RAYMONDI"
        },
        {
          "lon": -77.0294767375638,
          "lat": -12.0618159066539,
          "address": "AV. MANCO CAPAC - JR. GARCIA NARANJO"
        },
        {
          "lon": -77.0287570580295,
          "lat": -12.0672107751422,
          "address": "AV. MANCO CAPAC - JR. HIPOLITO UNANUE"
        },
        {
          "lon": -77.0233704629351,
          "lat": -12.0749381130891,
          "address": "AV. MEXICO - AV. PALERMO"
        },
        {
          "lon": -77.0256620898096,
          "lat": -12.0755157657539,
          "address": "AV. MEXICO - AV. LUNA PIZARRO"
        },
        {
          "lon": -77.0246590691417,
          "lat": -12.0751810325259,
          "address": "AV. MEXICO - JR. ANDAHUAYLAS"
        },
        {
          "lon": -77.0212848566935,
          "lat": -12.0742187563646,
          "address": "AV. MEXICO - JR. ABTAO"
        },
        {
          "lon": -77.0163728451467,
          "lat": -12.0734044979253,
          "address": "AV. MEXICO - JR. PARINACOCHAS"
        },
        {
          "lon": -77.011563428442,
          "lat": -12.0720379180358,
          "address": "AV. MEXICO - AV. AVIACION"
        },
        {
          "lon": -77.0173078726399,
          "lat": -12.0735896070451,
          "address": "AV. MEXICO - JR. LUCANAS"
        },
        {
          "lon": -77.0140365180232,
          "lat": -12.0727187384127,
          "address": "AV. MEXICO - JR. HUANUCO"
        },
        {
          "lon": -77.0334699734811,
          "lat": -12.0833999447383,
          "address": "AV. PETIT THOUARS - AV. JOSÉ PARDO DE ZELA"
        },
        {
          "lon": -77.0337679310145,
          "lat": -12.0812491908793,
          "address": "AV. PETIT THOUARS - JR. BARTOLOME HERRERA"
        },
        {
          "lon": -77.0333288262047,
          "lat": -12.0845133229079,
          "address": "AV. PETIT THOUARS - JR. BERNARDO ALCEDO"
        },
        {
          "lon": -77.0338632575738,
          "lat": -12.0805981599068,
          "address": "AV. PETIT THOUARS - JR. EMILIO ALTHAUS"
        },
        {
          "lon": -77.032692233066,
          "lat": -12.0889627678103,
          "address": "AV. PETIT THOUARS - JR. JOSE DE LA TORRE UGARTE"
        },
        {
          "lon": -77.0340178144281,
          "lat": -12.0795227974761,
          "address": "AV. PETIT THOUARS - JR. MANUEL SEGURA"
        },
        {
          "lon": -77.0328486983156,
          "lat": -12.087915934292,
          "address": "AV. PETIT THOUARS - JR. PEDRO CONDE"
        },
        {
          "lon": -77.0331898589194,
          "lat": -12.0855659545306,
          "address": "AV. PETIT THOUARS - JR. RISSO"
        },
        {
          "lon": -77.0330199018639,
          "lat": -12.0868266121068,
          "address": "AV. PETIT THOUARS - JR. TOMAS GUIDO"
        },
        {
          "lon": -77.0336406513751,
          "lat": -12.0906413143381,
          "address": "AV. AREQUIPA - AV. DOS DE MAYO"
        },
        {
          "lon": -77.0345837800389,
          "lat": -12.083552074963,
          "address": "AV. AREQUIPA - AV. JOSÉ PARDO DE ZELA"
        },
        {
          "lon": -77.0348712978022,
          "lat": -12.0814060833068,
          "address": "AV. AREQUIPA - JR. BARTOLOME HERRERA"
        },
        {
          "lon": -77.0344537236219,
          "lat": -12.0846615343968,
          "address": "AV. AREQUIPA - JR. BERNARDO ALCEDO"
        },
        {
          "lon": -77.0350127257093,
          "lat": -12.0807569692661,
          "address": "AV. AREQUIPA - JR. EMILIO ALTHAUS"
        },
        {
          "lon": -77.0347647643023,
          "lat": -12.0824772958802,
          "address": "AV. AREQUIPA - JR. MANUEL CANDAMO"
        },
        {
          "lon": -77.0351484255959,
          "lat": -12.0796965383819,
          "address": "AV. AREQUIPA - JR. MANUEL SEGURA"
        },
        {
          "lon": -77.03388269015,
          "lat": -12.0881018536663,
          "address": "AV. AREQUIPA - JR. PEDRO CONDE"
        },
        {
          "lon": -77.0343090686075,
          "lat": -12.0857226726612,
          "address": "AV. AREQUIPA - JR. RISSO"
        },
        {
          "lon": -77.0340416121987,
          "lat": -12.0869762297592,
          "address": "AV. AREQUIPA - JR. TOMAS GUIDO"
        },
        {
          "lon": -77.0350058816936,
          "lat": -12.0887216854629,
          "address": "AV. ARENALES - AV. HIPOLITO UNANUE (C. VALLEJO)"
        },
        {
          "lon": -77.0357093456191,
          "lat": -12.0837142245016,
          "address": "AV. ARENALES - AV. JUAN PARDO DE ZELA"
        },
        {
          "lon": -77.0360361433031,
          "lat": -12.0815548593059,
          "address": "AV. ARENALES - JR. BARTOLOME HERRERA"
        },
        {
          "lon": -77.0355592551018,
          "lat": -12.0848153082064,
          "address": "AV. ARENALES - JR. BERNARDO ALCEDO"
        },
        {
          "lon": -77.0361426810599,
          "lat": -12.0808940774665,
          "address": "AV. ARENALES - JR. EMILIO ALTHAUS"
        },
        {
          "lon": -77.0336222239351,
          "lat": -12.0823052044461,
          "address": "AV. PETIT THOUARS - JR. MANUEL CANDAMO"
        },
        {
          "lon": -77.0358744266573,
          "lat": -12.0826215967184,
          "address": "AV. ARENALES - JR. MANUEL CANDAMO"
        },
        {
          "lon": -77.0362895728661,
          "lat": -12.0798610341585,
          "address": "AV. ARENALES - JR. MANUEL SEGURA"
        },
        {
          "lon": -77.0354066419922,
          "lat": -12.0858521888367,
          "address": "AV. ARENALES - JR. RISSO"
        },
        {
          "lon": -77.0352254291239,
          "lat": -12.0871414286418,
          "address": "AV. ARENALES - JR. TOMAS GUIDO"
        },
        {
          "lon": -77.029830136908,
          "lat": -12.1137259744703,
          "address": "AV. AREQUIPA - AV. ANGAMOS"
        },
        {
          "lon": -77.0305162870513,
          "lat": -12.1095537534808,
          "address": "AV. AREQUIPA - CA. SALAVERRY"
        },
        {
          "lon": -77.0300157385493,
          "lat": -12.1126468290766,
          "address": "AV. AREQUIPA - CA. TARAPACA"
        },
        {
          "lon": -77.0293031128145,
          "lat": -12.1169569513021,
          "address": "AV. AREQUIPA - AV. GONZALES PRADA"
        },
        {
          "lon": -77.0303597350928,
          "lat": -12.1105878424981,
          "address": "AV. AREQUIPA - AV. MARIA PARADO DE BELLIDO"
        },
        {
          "lon": -77.0310236335549,
          "lat": -12.1066640829173,
          "address": "AV. AREQUIPA - CA. LOS ANGELES"
        },
        {
          "lon": -77.0308681331116,
          "lat": -12.1077457837198,
          "address": "AV. AREQUIPA - AV. TERUEL"
        },
        {
          "lon": -77.0290524336658,
          "lat": -12.1192830186405,
          "address": "AV. AREQUIPA - OVALO MIRAFLORES"
        },
        {
          "lon": -77.0287162853583,
          "lat": -12.1136557954919,
          "address": "AV. PETIT THOUARS - AV. ANGAMOS ESTE"
        },
        {
          "lon": -77.0294170230968,
          "lat": -12.1095082462171,
          "address": "AV. PETIT THOUARS - CA. CARLOS AUGUSTO SALAVERRY"
        },
        {
          "lon": -77.0281530053004,
          "lat": -12.1169711248407,
          "address": "AV. PETIT THOUARS - CA. GONZALES PRADA / PERSHING"
        },
        {
          "lon": -77.0292328550403,
          "lat": -12.1105176664146,
          "address": "AV. PETIT THOUARS - CA. JUNÍN"
        },
        {
          "lon": -77.0297427903286,
          "lat": -12.1075716017503,
          "address": "AV. PETIT THOUARS - CA. SCIPIÓN LLONA"
        },
        {
          "lon": -77.0299260348222,
          "lat": -12.1064871775401,
          "address": "AV. PETIT THOUARS - CA. SEVILLA"
        },
        {
          "lon": -77.0347284545749,
          "lat": -12.0908005759006,
          "address": "AV. ARENALES - AV. 2 DE MAYO"
        },
        {
          "lon": -77.0344882033419,
          "lat": -12.0924567524356,
          "address": "AV. ARENALES - AV. JAVIER PRADO"
        },
        {
          "lon": -77.0328753419397,
          "lat": -12.0955457342957,
          "address": "AV. AREQUIPA - CA. RICARDO PALMA"
        },
        {
          "lon": -77.03262119524,
          "lat": -12.0970937509267,
          "address": "AV. AREQUIPA - AV. JUAN DE ARONA"
        },
        {
          "lon": -77.0322021928786,
          "lat": -12.0997093175088,
          "address": "AV. AREQUIPA - CA. LA HABANA"
        },
        {
          "lon": -77.0324547418367,
          "lat": -12.0981066366908,
          "address": "AV. AREQUIPA - CA. GARCÍA Y GARCÍA"
        },
        {
          "lon": -77.0315483144076,
          "lat": -12.1035275810162,
          "address": "AV. AREQUIPA - AV. ARAMBURU"
        },
        {
          "lon": -77.0304543084631,
          "lat": -12.1033515331193,
          "address": "AV. PETIT THOUARS - AV. ARAMBURU"
        },
        {
          "lon": -77.0315386882375,
          "lat": -12.096937697674,
          "address": "AV. PETIT THOUARS - AV. JUAN DE ARONA"
        },
        {
          "lon": -77.0311745800335,
          "lat": -12.0995330930956,
          "address": "AV. PETIT THOUARS - CA. LA  HABANA"
        },
        {
          "lon": -77.0322352497877,
          "lat": -12.0921758896487,
          "address": "AV. JAVIER PRADO - AV. PETIT THOUARS"
        },
        {
          "lon": -77.0316757702851,
          "lat": -12.0961195667679,
          "address": "AV. PETIT THOUARS - CA. CHINCHON"
        },
        {
          "lon": -77.0314233995053,
          "lat": -12.0979317120384,
          "address": "AV. PETIT THOUARS - CA. ODRIOZOLA"
        },
        {
          "lon": -76.9899369740016,
          "lat": -12.0647999703327,
          "address": "AV. NICOLAS AYLLON - AV. NICOLAS ARRIOLA"
        },
        {
          "lon": -76.9999879883442,
          "lat": -12.0635784826489,
          "address": "AV. NICOLAS AYLLON - AV. CIRCUNVALACION"
        },
        {
          "lon": -76.9940365667788,
          "lat": -12.0639537462646,
          "address": "AV. NICOLAS AYLLON - AV. LOS ROSALES"
        },
        {
          "lon": -76.9869074998496,
          "lat": -12.0639611315576,
          "address": "AV. NICOLAS AYLLON - AV. LAS TORRES"
        },
        {
          "lon": -76.9970531792869,
          "lat": -12.0638098200458,
          "address": "AV. NICOLAS AYLLON - AV. MANUEL ECHEANDIA"
        },
        {
          "lon": -77.0311441971079,
          "lat": -12.0705598594435,
          "address": "AV. ISABEL LA CATOLICA - AV. PASEO LA REPUBLICA"
        },
        {
          "lon": -77.0334044979551,
          "lat": -12.065091070297,
          "address": "AV. BAUZATE Y MEZA - AV. PASEO LA REPUBLICA"
        },
        {
          "lon": -77.0320824869152,
          "lat": -12.0649533134603,
          "address": "AV. BAUZATE Y MEZA - JR. GALVEZ"
        },
        {
          "lon": -77.0268193179632,
          "lat": -12.0567650005826,
          "address": "AV. NICOLAS DE PIEROLA - JR. ANDAHUAYLAS"
        },
        {
          "lon": -77.039980136539,
          "lat": -12.0481903879155,
          "address": "AV. NICOLAS DE PIEROLA - JR. CHANCAY"
        },
        {
          "lon": -77.0360370603587,
          "lat": -12.0507274352316,
          "address": "AV. NICOLAS DE PIEROLA - JR. CAMANÁ"
        },
        {
          "lon": -77.0371546425433,
          "lat": -12.0500105370976,
          "address": "AV. NICOLAS DE PIEROLA - JR. CAILLOMA"
        },
        {
          "lon": -77.0379853032186,
          "lat": -12.0494688605291,
          "address": "AV. NICOLAS DE PIEROLA - JR. RUFINO TORRICO"
        },
        {
          "lon": -77.0409934336598,
          "lat": -12.0475359152751,
          "address": "AV. NICOLAS DE PIEROLA - JR. CAÑETE"
        },
        {
          "lon": -77.035122676354,
          "lat": -12.051332213043,
          "address": "AV. NICOLAS DE PIEROLA - JR. DE LA UNION"
        },
        {
          "lon": -77.0390241577065,
          "lat": -12.0488271741376,
          "address": "AV. NICOLAS DE PIEROLA - AV. GARCILASO DE LA VEGA"
        },
        {
          "lon": -77.0341051375508,
          "lat": -12.0520021153478,
          "address": "AV. NICOLAS DE PIEROLA - JR. CARABAYA"
        },
        {
          "lon": -77.0256217374765,
          "lat": -12.0575105901136,
          "address": "AV. NICOLAS DE PIEROLA - JR. PARURO"
        },
        {
          "lon": -77.0346605137716,
          "lat": -12.0516870462884,
          "address": "AV. NICOLAS DE PIEROLA - PLAZA SAN MARTIN"
        },
        {
          "lon": -77.0594036353647,
          "lat": -12.0480720479972,
          "address": "AV. OSCAR R. BENAVIDES - AV. NACIONES UNIDAS"
        },
        {
          "lon": -77.0538814562806,
          "lat": -12.0475768688753,
          "address": "AV. OSCAR R. BENAVIDES - AV. TINGO MARÍA"
        },
        {
          "lon": -77.0454862875436,
          "lat": -12.0466649031196,
          "address": "AV. OSCAR R. BENAVIDES - CA. ASCOPE"
        },
        {
          "lon": -77.046784275419,
          "lat": -12.046749548962,
          "address": "AV. OSCAR R. BENAVIDES - CA. PACASMAYO"
        },
        {
          "lon": -77.0506389765961,
          "lat": -12.0472053168099,
          "address": "AV. OSCAR R. BENAVIDES - JR. RAMÓN CARCAMO"
        },
        {
          "lon": -77.0426574277981,
          "lat": -12.0463708750985,
          "address": "AV. OSCAR R. BENAVIDES - PLAZA 2 DE MAYO"
        },
        {
          "lon": -77.0288853351143,
          "lat": -12.0553186247709,
          "address": "JR. AYACUCHO - AV. NICOLAS DE PIEROLA"
        },
        {
          "lon": -77.032110759644,
          "lat": -12.0532989286343,
          "address": "JR. AZANGARO - AV. NICOLAS DE PIEROLA"
        },
        {
          "lon": -77.0577037698529,
          "lat": -12.0900844882321,
          "address": "AV. SANCHEZ CARRION - AV. GREGORIO ESCOBEDO"
        },
        {
          "lon": -77.0609473383389,
          "lat": -12.0878922524937,
          "address": "AV. SANCHEZ CARRION - ALT. HOSPITAL MILITAR"
        },
        {
          "lon": -77.0341036280444,
          "lat": -12.0638043099908,
          "address": "Av. 28 de Julio - AV. PASEO DE LA REPUBLICA"
        },
        {
          "lon": -77.0404034701722,
          "lat": -12.0799350988598,
          "address": "AV. FRANSICO DE ZELA - AV. REBAGLIATI"
        },
        {
          "lon": -77.0659260556025,
          "lat": -12.0899813113546,
          "address": "AV. JAVIER PRADO - AV. BRASIL"
        },
        {
          "lon": -77.0593545937748,
          "lat": -12.0934823832092,
          "address": "AV. JAVIER PRADO OESTE - AV. JUAN  DE ALIAGA"
        },
        {
          "lon": -77.0578659309538,
          "lat": -12.0937204410308,
          "address": "AV. JAVIER PRADO OESTE - JR. DE LA ROCA VERGALLO"
        },
        {
          "lon": -77.0620050553753,
          "lat": -12.0928740766163,
          "address": "AV. JAVIER PRADO OESTE - JR. TRUJILLO"
        },
        {
          "lon": -77.0672363296257,
          "lat": -12.083080961182,
          "address": "AV. LA MARINA - AV. SUCRE"
        },
        {
          "lon": -77.0661253761775,
          "lat": -12.0834819026424,
          "address": "AV. LA MARINA - CA. PASEO DE LA LIBERTAD"
        },
        {
          "lon": -77.0757647775862,
          "lat": -12.0799834505066,
          "address": "AV. LA MARINA - JR. JUAN VALER (CUEVA)"
        },
        {
          "lon": -77.0703446865386,
          "lat": -12.0819725441272,
          "address": "AV. LA MARINA - JR. CASTILLA"
        },
        {
          "lon": -77.0809007487189,
          "lat": -12.0770291443973,
          "address": "AV. UNIVERSITARIA - CA. 1 CALLE 7"
        },
        {
          "lon": -77.0507084164459,
          "lat": -12.0947590197012,
          "address": "AV. JAVIER PRADO - AV. J. F. SANCHEZ CARRION"
        },
        {
          "lon": -77.0270057438956,
          "lat": -12.0914198007399,
          "address": "AV. JAVIER PRADO - AV. RIVERA NAVARRETE"
        },
        {
          "lon": -77.0543808682867,
          "lat": -12.0951991675938,
          "address": "AV. JAVIER PRADO - AV. SALAVERRY"
        },
        {
          "lon": -77.0473205222795,
          "lat": -12.0942399950326,
          "address": "AV. JAVIER PRADO - CA. LAS FLORES"
        },
        {
          "lon": -77.0386336189111,
          "lat": -12.0930493252155,
          "address": "AV. JAVIER PRADO - CA. LAS PALMERAS"
        },
        {
          "lon": -77.025891925457,
          "lat": -12.0912292007695,
          "address": "AV. JAVIER PRADO - JR. FRANCISCO MASIAS"
        },
        {
          "lon": -77.0301776846188,
          "lat": -12.0918817822067,
          "address": "AV. JAVIER PRADO - PASEO PARODI"
        },
        {
          "lon": -77.0532360877702,
          "lat": -12.0930462528102,
          "address": "AV. SALAVERRY - AV. J. F. SANCHEZ CARRION"
        },
        {
          "lon": -77.0831701427134,
          "lat": -12.0777826357953,
          "address": "AV. LA MARINA - ALT. PLAZA SAN MIGUEL"
        },
        {
          "lon": -77.0852173778811,
          "lat": -12.0781364279569,
          "address": "AV. LA MARINA - AV. DINTILHAC"
        },
        {
          "lon": -77.1001988681191,
          "lat": -12.0740974415338,
          "address": "AV. LA MARINA - AV. ELMER FAUCETT (A)"
        },
        {
          "lon": -77.099161640076,
          "lat": -12.0745987349677,
          "address": "AV. LA MARINA - AV. ELMER FAUCETT (B)"
        },
        {
          "lon": -77.0886878758647,
          "lat": -12.0780103031734,
          "address": "AV. LA MARINA - AV. PARQUE DE LAS LEYENDAS"
        },
        {
          "lon": -77.0933946697111,
          "lat": -12.0774217857584,
          "address": "AV. LA MARINA - AV. RAFAEL ESCARDO"
        },
        {
          "lon": -77.0844965473453,
          "lat": -12.0781508862743,
          "address": "AV. LA MARINA - AV. RIVA AGÜERO"
        },
        {
          "lon": -77.0817578579366,
          "lat": -12.0782251010551,
          "address": "AV. LA MARINA - AV. UNIVERSITARIA"
        },
        {
          "lon": -77.0870450573582,
          "lat": -12.0781190445486,
          "address": "AV. LA MARINA - AV. BRIGIDA SILVA DE OCHOA"
        }
      ],

      // Destinations
      travelTime: {},
      endPoint: { lat: null, lon: null },
      directionsService: null,
      moment: moment,
      // End

      // Lists
      elapsedTime: null
      // End
    }
  },
  async mounted () {
    try {
      const script = document.createElement('script')
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdcLv-nPvdySavL1rIx4HC07Di-U0aNPs&callback=initMap&libraries=places,geometry'
      script.defer = true
      document.head.appendChild(script)
      window.initMap = async () => {
        this.map = new window.google.maps.Map(document.getElementById("googleMaps"), {
          center: { lat: -12.038691464664373, lng: -77.04340906419523 },
          zoom: 18,
          styles: [
            {
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#616161"
                }
              ]
            },
            {
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#bdbdbd"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#eeeeee"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#757575"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e5e5e5"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ffffff"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#757575"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#dadada"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#616161"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e5e5e5"
                }
              ]
            },
            {
              "featureType": "transit.station",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#eeeeee"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#c9c9c9"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            }
          ],
          disableDefaultUI: true,
        })
        await this.getCoordinates()
        this.directionsService = new window.google.maps.DirectionsService()
      }

      await this.importTrafficLights()
    } catch (e) {
      console.log(e)
      this.$toast.error(`Error al cargar viaje`);
    }
  },
  computed: {
    formattedElapsedTime () {
      const duration = moment.duration(this.elapsedTime)
      const hours = Math.floor(duration.asHours())
      const minutes = Math.floor(duration.asMinutes()) % 60
      const seconds = Math.floor(duration.asSeconds()) % 60

      return `${hours} hrs : ${minutes} min : ${seconds} seg`
    }
  },
  methods: {
    /**
     * Get coordinates for travel
     */
    async getCoordinates () {
      try {
        const liveDoc = await firebase.firestore().collection('lives').doc(this.$route.params.liveId).get()
        this.type = liveDoc.data().type
        if (this.type === 'destinations') {
          this.progress = true
          // Get user
          const destinationDoc = await firebase.firestore().collection('destinations').doc(liveDoc.data().destinationId).get()
          const userDoc = await firebase.firestore().collection('users').doc(destinationDoc.data().user.uid).get()
          const user = userDoc.data()
          // End

          // Get end point
          this.endPoint.lat = destinationDoc.data().endPoint.lat
          this.endPoint.lon = destinationDoc.data().endPoint.lng
          const position = new window.google.maps.LatLng(this.endPoint.lat, this.endPoint.lon)
          this.addMarker(position, null)
          // End

          // Watch coordinates
          await firebase.firestore().collection('destinations')
              .doc(liveDoc.data().destinationId)
              .collection('travels')
              .doc(liveDoc.data().travelId)
              .collection('coordinates')
              .orderBy('createdAt', 'asc')
              .onSnapshot((querySnap) => {
                querySnap.docChanges().forEach((change) => {
                  if (change.type === 'added') {
                    this.position.lat = change.doc.data().coordinates[0].lat
                    this.position.lon = change.doc.data().coordinates[0].lon
                    const position = new window.google.maps.LatLng(this.position.lat, this.position.lon)
                    if (!this.currentMarker) {
                      this.addMarker(position, user)
                    }
                    else {
                      const position = new window.google.maps.LatLng(this.position.lat, this.position.lon)
                      this.currentMarker.setPosition(position)
                      this.map.setCenter(position)
                    }
                  }
                })
              })
          // End

          // Calculate travel time
          setInterval(() => {
            this.calculateTravelTime()
          }, 5000)
          // End
        }
        else if (this.type === 'lists') {
          this.progress = true

          // Get user
          const listDoc = await firebase.firestore().collection('lists').doc(liveDoc.data().listId).get()
          const userDoc = await firebase.firestore().collection('users').doc(listDoc.data().owner.uid).get()
          const user = { ...userDoc.data() }
          // End

          // Watch
          await firebase.firestore().collection('lists')
              .doc(liveDoc.data().listId)
              .collection('travels')
              .doc(liveDoc.data().travelId)
              .collection('coordinates')
              .orderBy('createdAt', 'asc')
              .onSnapshot((querySnap) => {
                querySnap.docChanges().forEach((change) => {
                  if (change.type === 'added') {
                    this.position.lat = change.doc.data().coordinates[0].lat
                    this.position.lon = change.doc.data().coordinates[0].lon
                    const position = new window.google.maps.LatLng(this.position.lat, this.position.lon)
                    if (!this.currentMarker) {
                      this.addMarker(position, user)
                    }
                    else {
                      const position = new window.google.maps.LatLng(this.position.lat, this.position.lon)
                      this.currentMarker.setPosition(position)
                      this.map.setCenter(position)
                    }
                  }
                })
              })
          // End

          this.initTimer(liveDoc.data().createdAt.toDate())
        }
      } catch (e) {
        this.$toast.error(`Error al cargar coordenadas`);
      } finally {
        this.progress = false
      }
    },
    /**
     * Add marker to map
     */
    addMarker (position, user) {
      if (user) {
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="40" fill="#006938" />
          <text x="50" y="65" font-size="40"  font-family="'Arial', sans-serif" text-anchor="middle" fill="white">${user.displayName.substr(0, 1).toUpperCase()}</text>
    </svg>`
        const img = {
          url: user.photoURL,
          scaledSize: new window.google.maps.Size(40, 40)
        }

        let base64Svg = btoa(svg)
        const dataUrl = 'data:image/svg+xml;base64,' + base64Svg

        this.currentMarker = new window.google.maps.Marker({
          position,
          map: this.map,
          icon: user.photoURL ? img : {
            url: dataUrl, // URL de datos del SVG
            scaledSize: new window.google.maps.Size(40, 40),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(20, 40)
          }
        })
      }
      else {
        new window.google.maps.Marker({
          map: this.map,
          position: position,
          icon: '/assets/markers/end.svg'
        })
      }
    },
    /**
     * Calculate travel time with google maps
     * @returns {*|null}
     */
    async calculateTravelTime () {
      let startCoords = this.position.lat + "," + this.position.lon  // Starting point
      let endCoords = this.endPoint.lat + "," + this.endPoint.lon  // Destination point
      let request = {
        origin: startCoords,
        destination: endCoords,
        travelMode: 'DRIVING'
      }

      const response = await this.directionsService.route(request)

      if (response.status === window.google.maps.DirectionsStatus.OK) {
        let leg = response.routes[0].legs[0]

        this.travelTime = {
          travelTime: leg.duration,
          distance: leg.distance
        }
      }
    },
    /**
     * Init timer
     */
    initTimer (date) {
      this.initialMoment = date
      this.updateTimer()
      this.intervalId = setInterval(() => {
        this.updateTimer()
      }, 1000)
    },
    /**
     * Update timer
     */
    updateTimer () {
      const now = moment()
      const duration = moment.duration(now.diff(this.initialMoment))
      this.elapsedTime = duration.asMilliseconds()
    },
    /**
     * Import traffic lights
     */
    async importTrafficLights () {
      const batchs = []
      batchs.push(firebase.firestore().batch())
      let current = 0
      let count = 0
      this.tl.forEach((t) => {
        const docRef = firebase.firestore().collection('tl').doc()
        batchs[current].set(docRef, {
          ...t
        })
        count++

        if (count === 499) {
          batchs.push(firebase.firestore().batch())
          current++
          count = 0
        }
      })

      for await (let b of batchs) {
        await b.commit()
      }
    }
  }
}
</script>
