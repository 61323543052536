<template>
  <div>
    <h1>Términos y Condiciones de Uso y Tratamiento de Datos de Iflow</h1>

    <h2>1. Introducción</h2>
    <p>Bienvenido a Iflow. Al utilizar nuestra aplicación, aceptas los siguientes términos y condiciones relacionados
      con el uso y tratamiento de datos personales. Te recomendamos que leas detenidamente este documento. Si no estás
      de acuerdo con alguno de los términos aquí expuestos, por favor, no utilices nuestra aplicación.</p>

    <h2>2. Información Recopilada</h2>

    <h3>2.1 Datos Personales</h3>
    <p>Iflow recopila los siguientes tipos de datos personales:</p>
    <ul>
      <li><strong>Información de contacto:</strong> nombre, dirección de correo electrónico, número de teléfono.</li>
      <li><strong>Datos de perfil:</strong> nombre de usuario, foto de perfil, biografía.</li>
      <li><strong>Información de la cuenta:</strong> credenciales de inicio de sesión, historial de uso.</li>
      <li><strong>Datos de actividad:</strong> interacciones con la aplicación, preferencias y configuraciones de
        usuario.
      </li>
      <li><strong>Datos de geolocalización:</strong> ubicación precisa y aproximada del dispositivo.</li>
    </ul>

    <h3>2.2 Datos No Personales</h3>
    <p>También recopilamos datos no personales para mejorar la funcionalidad de nuestra aplicación. Estos pueden
      incluir:</p>
    <ul>
      <li><strong>Datos de uso:</strong> frecuencia de uso, duración de las sesiones.</li>
      <li><strong>Datos técnicos:</strong> tipo de dispositivo, sistema operativo, dirección IP.</li>
    </ul>

    <h2>3. Uso de la Información</h2>

    <h3>3.1 Finalidad del Tratamiento de Datos</h3>
    <p>Los datos recopilados por Iflow serán utilizados para:</p>
    <ul>
      <li><strong>Proporcionar y mejorar nuestros servicios:</strong> adaptar la experiencia del usuario, desarrollar
        nuevas funcionalidades.
      </li>
      <li><strong>Comunicación:</strong> enviar notificaciones, actualizaciones de servicio y promociones.</li>
      <li><strong>Seguridad:</strong> prevenir fraudes, proteger contra actividades no autorizadas.</li>
      <li><strong>Cumplimiento Legal:</strong> cumplir con obligaciones legales y reglamentarias.</li>
      <li><strong>Servicios basados en ubicación:</strong> personalizar el contenido y las recomendaciones según tu
        ubicación geográfica.
      </li>
    </ul>

    <h3>3.2 Base Legal para el Tratamiento</h3>
    <p>El tratamiento de tus datos personales se basa en:</p>
    <ul>
      <li><strong>Consentimiento:</strong> al utilizar la aplicación, consientes el tratamiento de tus datos según estos
        términos.
      </li>
      <li><strong>Ejecución de un contrato:</strong> para cumplir con los servicios ofrecidos por Iflow.</li>
      <li><strong>Intereses legítimos:</strong> mejorar y personalizar nuestra oferta, proteger la seguridad de nuestros
        usuarios.
      </li>
    </ul>

    <h2>4. Compartir Información</h2>

    <h3>4.1 Con Terceros</h3>
    <p>Podemos compartir tus datos personales con:</p>
    <ul>
      <li><strong>Proveedores de servicios:</strong> empresas que nos ayudan a operar nuestra aplicación, como servicios
        de alojamiento y análisis de datos.
      </li>
      <li><strong>Socios comerciales:</strong> con fines de marketing y promoción, siempre y cuando hayas dado tu
        consentimiento.
      </li>
      <li><strong>Autoridades legales:</strong> si es requerido por ley o para proteger nuestros derechos y seguridad.
      </li>
    </ul>

    <h3>4.2 Transferencias Internacionales</h3>
    <p>Si transferimos tus datos personales a países fuera del Espacio Económico Europeo (EEE), nos aseguraremos de que
      se implementen las medidas de protección adecuadas.</p>

    <h2>5. Seguridad de la Información</h2>
    <p>Iflow implementa medidas de seguridad técnicas y organizativas para proteger tus datos personales contra acceso
      no autorizado, pérdida, alteración o divulgación. Sin embargo, ningún sistema de seguridad es infalible y no
      podemos garantizar la seguridad absoluta de tu información.</p>

    <h2>6. Retención de Datos</h2>
    <p>Retendremos tus datos personales durante el tiempo que sea necesario para cumplir con los fines descritos en
      estos términos, a menos que la ley requiera o permita un período de retención más largo.</p>

    <h2>7. Derechos del Usuario</h2>

    <h3>7.1 Acceso y Rectificación</h3>
    <p>Tienes derecho a acceder a tus datos personales y a corregir cualquier información inexacta o incompleta.</p>

    <h3>7.2 Cancelación y Oposición</h3>
    <p>Puedes solicitar la eliminación de tus datos personales o oponerte a su tratamiento en ciertas
      circunstancias.</p>

    <h3>7.3 Portabilidad</h3>
    <p>Tienes derecho a recibir tus datos personales en un formato estructurado, de uso común y legible por máquina, y a
      transferirlos a otro responsable del tratamiento.</p>

    <h2>8. Cambios en los Términos y Condiciones</h2>
    <p>Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Notificaremos cualquier
      cambio a través de nuestra aplicación o por otros medios de comunicación. El uso continuado de Iflow después de
      dichos cambios implica la aceptación de los términos modificados.</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 2rem;
  background-color: #f4f4f4;
}

.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1, h2, h3 {
  color: #333;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
  margin-top: 20px;
}

h3 {
  margin-top: 15px;
}

p, ul {
  margin-bottom: 10px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}

a {
  color: #0066cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
