<template>
  <div class="map">
    <div id="googleMaps" class="map__container"></div>
    <div class="map__data">
      <h1>Información del viaje</h1>
      <div class="kpis">
        <div class="kpis__row">
          <span>Km. Recorridos</span>
          <span>{{ totalDistance.toFixed(2) }} KM</span>
        </div>
        <div class="kpis__row">
          <span>Tiempo de viaje</span>
          <span>{{ (totalTravelTime * 60).toFixed(2) }} M</span>
        </div>
        <div class="kpis__row">
          <span>Velocidad Promedio calculada</span>
          <span>{{ (averageSpeed).toFixed(2) }} KM/H</span>
        </div>
        <div class="kpis__row">
          <span>Tiempo de estacionalidad</span>
          <span>{{ (timeInTraffic * 60).toFixed(2) }} M</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase"

require('firebase/firestore')
export default {
  name: "Travels",
  data () {
    return {
      map: {},
      coordinates: [],
      totalDistance: 0,
      averageSpeed: 0,
      timeInTraffic: 0,
      totalTravelTime: 0
    }
  },
  async mounted () {
    try {
      const script = document.createElement('script')
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdcLv-nPvdySavL1rIx4HC07Di-U0aNPs&callback=initMap&libraries=places,geometry'
      script.defer = true
      document.head.appendChild(script)
      window.initMap = async () => {
        this.map = new window.google.maps.Map(document.getElementById("googleMaps"), {
          center: { lat: -12.038691464664373, lng: -77.04340906419523 },
          zoom: 12,
          disableDefaultUI: true,
        })
        await this.getCoordinates()
      }
    } catch (e) {
      console.log(e)
      this.$toast.error(`Error al cargar viaje`);
    }
  },
  methods: {
    /**
     * Get coordinates for travel
     */
    async getCoordinates () {
      try {
        const liveDoc = await firebase.firestore().collection('lives').doc(this.$route.params.liveId).get()
        let querySnap = null
        if (liveDoc.data().type === 'destinations') {
          this.progress = true
          this.coordinates = []
          querySnap = await firebase.firestore().collection('destinations')
              .doc(liveDoc.data().destinationId)
              .collection('travels')
              .doc(liveDoc.data().travelId)
              .collection('coordinates')
              .orderBy('createdAt', 'asc')
              .limit(100).get()
        }
        else if (liveDoc.data().type === 'events') {
          querySnap = await firebase.firestore().collection('groups')
              .doc(liveDoc.data().groupId)
              .collection('events')
              .doc(liveDoc.data().eventId)
              .collection('members')
              .doc(liveDoc.data().memberId)
              .collection('travels')
              .doc(liveDoc.data().travelId)
              .collection('coordinates')
              .orderBy('createdAt', 'asc')
              .limit(100).get()
        }
        else {
          querySnap = await firebase.firestore()
              .collection('lists')
              .doc(liveDoc.data().listId)
              .collection('travels')
              .doc(liveDoc.data().travelId)
              .collection('coordinates')
              .orderBy('createdAt', 'asc')
              .limit(100).get()

        }
          querySnap.forEach((doc) => {
            const obj = {
              id: doc.id,
              ...doc.data()
            }
            obj.createdAt = doc.data().createdAt.toDate()
            this.coordinates.push({
              latitude: obj.coordinates[0].lat,
              longitude: obj.coordinates[0].lon,
              timestamp: doc.data().createdAt.toDate().getTime(),
              speed: obj.coordinates[0].speed
            },)
          })
          // Add Markers
          this.coordinates.forEach((c, i) => {
            const coordinates = new window.google.maps.LatLng({ lat: c.latitude, lng: c.longitude })
            const marker = new window.google.maps.Marker({
              position: coordinates,
              title: (i + 1) + "",
              label: {
                text: (i + 1) + "",
                color: "white"
              }
            })
            marker.setMap(this.map)
          })
          this.centerMap()

          let result = this.calculateTravelData(this.coordinates, 20)
          this.averageSpeed = this.calculateAverageSpeed(result.travelData)
          this.totalDistance = result.totalDistance
          this.timeInTraffic = result.timeInTraffic
          this.totalTravelTime = result.totalTravelTime
      } catch (e) {
        console.log(e)
        this.$toast.error(`Error al cargar coordenadas`);
      }
    },
    /**
     * Center map
     */
    centerMap () {
      let startLatLng = new window.google.maps.LatLng(this.coordinates[0].latitude, this.coordinates[0].longitude)
      let endLatLng = new window.google.maps.LatLng(this.coordinates[this.coordinates.length - 1].latitude, this.coordinates[this.coordinates.length - 1].longitude)

      let bounds = new window.google.maps.LatLngBounds()
      bounds.extend(startLatLng)
      bounds.extend(endLatLng)
      this.map.fitBounds(bounds)
    },
    /**
     *
     * @param lat1
     * @param lon1
     * @param lat2
     * @param lon2
     * @returns {number}
     */
    calculateDistance (lat1, lon1, lat2, lon2) {
      let R = 6371; // Earth radius in kilometers
      let dLat = (lat2 - lat1) * Math.PI / 180;
      let dLon = (lon2 - lon1) * Math.PI / 180;
      let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let distance = R * c;
      return distance; // Distance in kilometers
    },
    /**
     * Calculate travel data
     * @param data
     * @param limit
     * @returns {{travelData: *[], totalDistance: number, timeInTraffic: number, totalTravelTime: number}}
     */
    calculateTravelData (data, limit) {
      let result = []
      let totalDistance = 0
      let timeInTraffic = 0
      let totalTravelTime = 0

      for (let i = 0; i < data.length - 1; i++) {
        let distance = this.calculateDistance(data[i].latitude, data[i].longitude, data[i + 1].latitude, data[i + 1].longitude)
        let timeInHours = (data[i + 1].timestamp - data[i].timestamp) / (1000 * 60 * 60); // Convert milliseconds to hours
        let speed = data[i].speed

        result.push({ distance: distance, time: timeInHours, speed: speed })

        totalDistance += distance

        if (timeInHours * 60 * 60 > limit) {
          timeInTraffic += timeInHours
        }

        totalTravelTime += timeInHours
      }

      return {
        travelData: result,
        totalDistance: totalDistance,
        timeInTraffic: timeInTraffic,
        totalTravelTime: totalTravelTime
      };
    },
    /**
     * Calculate average speed
     * @param travelData
     * @returns {number}
     */
    calculateAverageSpeed (travelData) {
      let totalSpeed = 0

      for (let i = 0; i < travelData.length; i++) {
        totalSpeed += travelData[i].speed
      }

      let averageSpeed = totalSpeed / travelData.length
      return averageSpeed // Average speed in kilometers per hour
    }
  }
}
</script>
