import { createRouter, createWebHistory } from 'vue-router'
import Travels from '../views/travels/App'
import Lives from '../views/live/App.vue'
import PrivacyPolicy from "@/views/privacy-policy.vue"

const routes = [
    {
        path: '/:liveId',
        name: 'lives',
        component: Lives
    },
    {
        path: '/travels/:liveId',
        name: 'travels',
        component: Travels
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
