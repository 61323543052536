import { createApp } from 'vue'
import Toaster from "@meforma/vue-toaster";


import App from './App.vue'
import router from './router'
import firebase from "firebase"


/* Firebase */

const firebaseConfig = {
    apiKey: "AIzaSyDdcLv-nPvdySavL1rIx4HC07Di-U0aNPs",
    authDomain: "iflow-6ca05.firebaseapp.com",
    projectId: "iflow-6ca05",
    storageBucket: "iflow-6ca05.appspot.com",
    messagingSenderId: "488179758548",
    appId: "1:488179758548:web:53c2a686313ac41cc79241",
    measurementId: "G-FYEE111PHL"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const app = createApp(App)
app.config.isCustomElement = tag => tag === '/^ion-/'
app.use(router).use(Toaster).mount('#app')
